import GlobalText from './globalText'
import React, { useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Toaster, toast } from 'sonner'
import GlobalData from './globalData'
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function AddVacancies(props) {
    const navigate = useNavigate();
    const {language} = props
    const [langP, setLangP] = useState(language)
    const [addloading, setaddloading] = useState(false);    
    const APIURL = GlobalData('apiUrl')
    const Cookie = Cookies.get('token')
    const succesText = GlobalText(106)
    const errorText = GlobalText(39)
    const errorText2 = GlobalText(122)
    const [editorStatetg, setEditorStatetg] = useState(EditorState.createEmpty())
    const [editorStateru, setEditorStateru] = useState(EditorState.createEmpty())
    const [editorStateen, setEditorStateen] = useState(EditorState.createEmpty())

    const onEditorStateChangetg = (editorState) => {
        setEditorStatetg(editorState);
    };
    const onEditorStateChangeru = (editorState) => {
      setEditorStateru(editorState);
    };
    const onEditorStateChangeen = (editorState) => {
      setEditorStateen(editorState);
    };

    function addVacancies(e){
      e.preventDefault()  
      setaddloading(true)
      const contentStatetg = convertToRaw(editorStatetg.getCurrentContent());
      const contentStateru = convertToRaw(editorStateru.getCurrentContent());
      const contentStateen = convertToRaw(editorStateen.getCurrentContent());
      const data =  {
        'url' : document.getElementById('url').value,
        'title_tg' : document.getElementById('title_tg').value,
        'title_ru' : document.getElementById('title_ru').value,
        'title_en' : document.getElementById('title_en').value,
        'content_tg' : JSON.stringify({ contentStatetg }), 
        'content_ru' : JSON.stringify({ contentStateru }),
        'content_en' : JSON.stringify({ contentStateen }),
      }
      axios.post(APIURL + 'vacancies/add', data, {
          headers:{
            Authorization: `Bearer ${Cookie}`,
            'Content-Type': 'multipart/form-data'
          }
        }      
      )
      .then((response) => {
        console.log(response.data)
        if(response.data === 'ok'){ 
          toast.success(succesText) 
          navigate(-1); 
        }else if(response.data === 'url'){
          toast.error(errorText2)
        }
        setaddloading(false) 
      })
      .catch(function (error) {   
        setaddloading(false)         
        toast.error(errorText)     
      })    
    }
   
    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      <Toaster richColors position="top-center"/>
      <div className='flex mx-auto justify-between py-3'>
        <div className="text-xl font-bold text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(152)} </div>
        <div className="text-md font-semi-bold text-gray-400 sm:truncate sm:text-md sm:tracking-tight inline-flex items-center"> 
          <button onClick={() => setLangP('tg')}
              className={classNames(
                langP === 'tg' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                'px-3 py-2 font-bold rounded-lg'
              )}>
              TJ
            </button>
            <button onClick={() => setLangP('ru')}
              className={classNames(
                langP === 'ru' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                'ml-2 px-3 py-2 font-bold rounded-lg'
              )}>
              RU
            </button>
            <button onClick={() => setLangP('en')}
              className={classNames(
                langP === 'en' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                'ml-2 px-3 py-2 font-bold rounded-lg'
              )}>
              EN
            </button>
        </div>        
      </div>
      <form onSubmit={(e) => addVacancies(e)}>
      <div className="mt-6">
        <div className="relative z-0 w-full mb-5">
          <input
              maxLength={200}
              autoComplete='off'
              required
              type="text"
              id="url"
              minLength='3'
              placeholder=''
              className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            />
          <label htmlFor="url" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(24)}</label>
        </div> 
      </div>      
      <div className="mt-6" hidden={!(langP=== 'tg')}>
          <div className="relative z-0 w-full mb-5">
            <input
                maxLength={100}
                autoComplete='off'
                type="text"
                id="title_tg"
                minLength='2'
                placeholder=''
                className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              />
            <label htmlFor="title_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
          </div>          
          <label htmlFor="text_tg" className="block text-lg font-medium leading-6 text-gray-900 mt-5">
            {GlobalText(66)}
          </label>
          <Editor
            editorState={editorStatetg}
            toolbarClassName="border"
            wrapperClassName="wrapperClassName"
            editorClassName="border"
            onEditorStateChange={onEditorStateChangetg}
            editorStyle={{
                minHeight: '200px',
                padding: '10px',
                backgroundColor: '#fff',
            }}
          />
      </div>
      <div className="mt-6" hidden={!(langP=== 'ru')}>
        <div className="relative z-0 w-full mb-5">
            <input
                maxLength={100}
                autoComplete='off'
                type="text"
                id="title_ru"
                minLength='2'
                placeholder=''
                className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              />
            <label htmlFor="title_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
          </div> 
          <label htmlFor="text_ru" className="block text-lg font-medium leading-6 text-gray-900 mt-5">
            {GlobalText(66)}
          </label>
          <Editor
              editorState={editorStateru}
              toolbarClassName="border"
              wrapperClassName="wrapperClassName"
              editorClassName="border"
              onEditorStateChange={onEditorStateChangeru}
              editorStyle={{
                  minHeight: '200px',
                  padding: '10px',
                  backgroundColor: '#fff',
              }}
            />
      </div>
      <div className="mt-6" hidden={!(langP=== 'en')}>
        <div className="relative z-0 w-full mb-5">
            <input
                maxLength={100}
                autoComplete='off'
                type="text"
                id="title_en"
                minLength='2'
                placeholder=''
                className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
              />
            <label htmlFor="title_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
          </div> 
          <label htmlFor="text_en" className="block text-lg font-medium leading-6 text-gray-900 mt-5">
            {GlobalText(66)}
          </label>
          <Editor
            editorState={editorStateen}
            toolbarClassName="border"
            wrapperClassName="wrapperClassName"
            editorClassName="border"
            onEditorStateChange={onEditorStateChangeen}
            editorStyle={{
                minHeight: '200px',
                padding: '10px',
                backgroundColor: '#fff',
            }}
          />
      </div>
        
        <div className='flex mx-auto justify-between mt-8'>
          {addloading ? <button disabled className="uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
                  <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(31)}
                  </button> : <button type='submit'
            className="text-white uppercase  bg-gray-500 hover:bg-gray-600 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
            {GlobalText(31)}
          </button>} 
        </div> 
      </form>
    </div>)
  }

  export default AddVacancies