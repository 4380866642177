import { Fragment, useState, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import NavjuLogo from '../imgs/navju_w.png'
import { 
  Bars3Icon, 
  XMarkIcon, 
  UserIcon, 
  Cog6ToothIcon,
  LanguageIcon, 
  ArrowLeftEndOnRectangleIcon, 
  ChevronDownIcon
} from '@heroicons/react/24/outline'
import axios from 'axios';
import Cookies from 'js-cookie';
import icon from '../imgs//icon_white.png'
import {
  Link,
  useLocation
} from "react-router-dom";
import GlobalText from './globalText'
import { IoMdCheckmark } from "react-icons/io";
import Pages from './pages';
import AddPage from './editePage';
import Settings from './settings';
import Users from './users';
import Helmet from 'react-helmet';
import GlobalData from './globalData';
import Profile from './profile';
import News from './news';
import AddNews from './addNews';
import EditePage from './editePage';
import Cals from './cals';
import EditeNews from './editeNews';
import Vacancies from './vacancies';
import AddVacancies from './addVacancies';
import EditeVacancies from './editeVacancies';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const APIURL = GlobalData('apiUrl')
const NAP = 'Navju Console';

function removeCookie(e, logoutText){
    e.preventDefault();
    if (window.confirm(logoutText)) {
      Cookies.remove('token')      
      window.location.reload();
    }
}

function Admin() {
  const IMGURL = GlobalData('imgUrl')
  const location = useLocation();
  const isCurrentlyEn = location.pathname.startsWith('/en');
  const isCurrentlyRu = location.pathname.startsWith('/ru');
  const language = isCurrentlyEn ? 'en' : isCurrentlyRu ? 'ru' : 'tg'
  const currentPathname = isCurrentlyEn || isCurrentlyRu
    ? location.pathname.substring(4) 
    : location.pathname.substring(1); 
  const parentPage = currentPathname.split('/')[1]
  
  const cookie = Cookies.get('token')
  const [userName, setUserName] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const [userEmail, setUserEmail] = useState('') 

  if(parentPage === 'news' || currentPathname === 'admin' ){
    document.title = GlobalText(19) + ' | ' + NAP;
  }
  if(parentPage === 'pages'){
    document.title = GlobalText(20) + ' | ' + NAP;
  }
  if(parentPage === 'users'){
    document.title = GlobalText(21) + ' | ' + NAP;
  }
  if(parentPage === 'settings'){
    document.title = GlobalText(22) + ' | ' + NAP;
  }
  if(parentPage === 'profile'){
    document.title = GlobalText(13) + ' | ' + NAP;
  }
  if(parentPage === 'rate'){
    document.title = GlobalText(88) + ' | ' + NAP;
  }
  if(parentPage === 'vacancies'){
    document.title = GlobalText(148) + ' | ' + NAP;
  }


  useEffect(() => {
    axios.get(APIURL + 'admin', 
      {
        headers:{
          Authorization: `Bearer ${cookie}`
        }
      }
    )
    .then((response) => {
      setUserName(response.data.data.userName)     
      setUserStatus(response.data.data.userStatus)  
      setUserEmail(response.data.data.email)  
      
    })
    .catch(function (error) {            
      if(error.response.status === 401){
        Cookies.remove('token')      
        window.location.reload();  
      }            
    })
  }, []);
    
  
  const logoutText = GlobalText(18);
    
  return (<div>
    <Helmet>
        <link rel="icon" href={IMGURL + 'icon.png'} />
      </Helmet>
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:flex sm:flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src={icon}
                    alt="BRT"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                      <Link to={language === 'tg' ? '/admin/news' : language + '/admin/news'}
                        className={classNames(
                          currentPathname === 'admin' || parentPage === 'news' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                        {GlobalText(19)}
                      </Link>   
                      <Link to={language === 'tg' ? '/admin/vacancies' : language + '/admin/vacancies'}
                        className={classNames(
                          parentPage === 'vacancies' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                        {GlobalText(148)}
                      </Link>                      
                      {(userStatus === 'superadmin' || userStatus === 'admin' || userStatus === 'moderator') && <Link to={language === 'tg' ? '/admin/pages' : language + '/admin/pages'}
                        className={classNames(
                          parentPage === 'pages' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                         {GlobalText(20)}
                      </Link>} 
                      {(userStatus === 'superadmin' || userStatus === 'admin') && <Link to={language === 'tg' ? '/admin/rate' : language + '/admin/rate'}
                        className={classNames(
                          parentPage === 'rate' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                         {GlobalText(88)}
                      </Link>}
                      {(userStatus === 'superadmin' || userStatus === 'admin') && <Link to={language === 'tg' ? '/admin/users' : language + '/admin/users'}
                        className={classNames(
                          parentPage === 'users' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                         {GlobalText(21)}
                      </Link>}
                      
                      {(userStatus === 'superadmin' || userStatus === 'admin') && <Link to={language === 'tg' ? '/admin/settings' : language + '/admin/settings'}
                        className={classNames(
                          parentPage === 'settings' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                       {GlobalText(22)}
                      </Link>}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto">  
                {/* Profile dropdown */}
                <Menu as="div" className="flex h-16 items-center justify-between ">
                  <div className="justify-end ">
                      <div className="">
                      <Menu as="div" className="relative inline-block text-left p-2 ">
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <UserIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </Menu.Button>
                <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                  >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                          
                        <Menu.Item  >
                              <div 
                              className={classNames(
                                     'text-gray-500',
                                    'block px-4 py-3 text-sm inline-flex w-full gap-x-5 justify-left brt-text-regular border-b border-gray-200 mb-3 items-center '
                                )
                                }
                              >
                              <UserIcon className="-mr-1 h-5 w-5 text-gray-500 " aria-hidden="true" />
                              <div className="">
                                <dt className="font-medium text-gray-900">{userName}</dt>
                                <dd className="text-sm text-gray-500 ">{userStatus === 'superadmin' ? GlobalText(15) : userStatus === 'admin' ? GlobalText(15) : userStatus === 'moderator' ? GlobalText(16) : GlobalText(17)}</dd>
                              </div>
                              </div>
                          </Menu.Item>

                          <Menu.Item  >
                          {({ active }) => (
                              <Link to={language === 'tg' ? '/admin/profile' : language + '/admin/profile'}  
                              className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                    'block px-4 py-3 text-sm inline-flex w-full gap-x-5 justify-left brt-text-regular'
                                )
                                }
                              >
                              <Cog6ToothIcon className="-mr-1 h-5 w-5 text-gray-500 " aria-hidden="true" />
                              {GlobalText(13)} 
                              </Link>
                          )}
                          </Menu.Item>
                          <Menu.Item  >
                          {({ active }) => (
                              <button onClick={(e) => removeCookie(e, logoutText)}  
                              className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                    'block px-4 py-3 text-sm inline-flex w-full gap-x-5 justify-left brt-text-regular'
                                )
                                }
                              >
                              <ArrowLeftEndOnRectangleIcon className="-mr-1 h-5 w-5 text-gray-500 " aria-hidden="true" />
                              {GlobalText(14)} 
                              </button>
                          )}
                          </Menu.Item>
                          <Menu.Item  >
                          {({ active }) => (
                              <div  
                              className={classNames(
                                    'text-gray-500',
                                    'block text-sm inline-flex w-full gap-x-5 brt-text-medium justify-left border-t border-gray-200 mt-3'
                                )
                                }
                              >
                                <Menu as="div" className="relative text-left  w-full">
                                  <Menu.Button
                                    className="inline-flex w-full justify-between  text-gray-600 brt-text-regular py-3 px-4 mt-3 hover:bg-gray-100"        
                                  >
                                    <LanguageIcon className="-mr-1 h-5 w-5 text-gray-500 " aria-hidden="true" />
                                    {GlobalText(7)}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-500 " aria-hidden="true" />
                                  </Menu.Button>
                                  <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item >
                                            {({ active }) => (
                                                <Link to={currentPathname}                                                   
                                                className={language === "tg"
                                                ? classNames(
                                                    'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                                    )
                                                    : classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                      'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                                  )
                                                  }
                                                >
                                                Тоҷикӣ
                                                {language === "tg" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                                                </Link>
                                            )}
                                            </Menu.Item>
                                            <Menu.Item  >
                                            {({ active }) => (
                                                <Link to={'ru/' + currentPathname}                                                     
                                                className={language === "ru"
                                                ? classNames(
                                                    'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                                    )
                                                    : classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                      'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between '
                                                  )
                                                  }
                                                >
                                                Русский
                                                {language === "ru" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                                                </Link>
                                            )}
                                            </Menu.Item>
                                            <Menu.Item  >
                                            {({ active }) => (
                                                <Link to={'en/' + currentPathname} 
                                                className={language === "en"
                                                ? classNames(
                                                    'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                                    )
                                                    : classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                                      'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                                  )
                                                  }
                                                >
                                                English 
                                                {language === "en" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                                                </Link>
                                            )}
                                            </Menu.Item>
                                        </div>
                                        </Menu.Items>
                                    </Transition>  
                                </Menu> 
                              </div>
                          )}
                          </Menu.Item>
                          
                      </div>
                      </Menu.Items>
                  </Transition>  
                  </Menu>
                      </div>            
                  </div>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
                  <Link to={language === 'tg' ? '/admin/news' : language + '/admin/news'}
                      className={classNames(
                        currentPathname === 'admin' || parentPage === 'news' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                    >
                      {GlobalText(19)}
                    </Link>
                    <Link to={language === 'tg' ? '/admin/pages' : language + '/admin/pages'}
                      className={classNames(
                        parentPage === 'pages' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                    >
                        {GlobalText(20)}
                    </Link>
                    <Link to={language === 'tg' ? '/admin/users' : language + '/admin/users'}
                      className={classNames(
                        parentPage === 'users' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                    >
                        {GlobalText(21)}
                    </Link>
                    <Link to={language === 'tg' ? '/admin/settings' : language + '/admin/settings'}
                      className={classNames(
                        parentPage === 'settings' ? 'bg-gray-900 text-gray-300' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                    >
                      {GlobalText(22)}
                    </Link>
              
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    {currentPathname === 'admin/pages' && (userStatus === 'superadmin' || userStatus === 'admin' || userStatus === 'moderator') && <Pages language={language}/>}
    {currentPathname.startsWith('admin/pages/edite/') && (userStatus === 'superadmin' || userStatus === 'admin' || userStatus === 'moderator') &&  <EditePage language={language} currentPathname={currentPathname} />}
    {currentPathname === 'admin/settings' && (userStatus === 'superadmin' || userStatus === 'admin') && <Settings />}
    {currentPathname === 'admin/users' && (userStatus === 'superadmin' || userStatus === 'admin') && <Users />}
    {currentPathname === 'admin/profile' && <Profile userEmail={userEmail} userName={userName} userStatus={userStatus} />}
    {(currentPathname === 'admin' || currentPathname === 'admin/news') && <News />}    
    {currentPathname === 'admin/vacancies' && <Vacancies />}
    {currentPathname === 'admin/news/add' && <AddNews language={language}/>}
    {currentPathname.startsWith('admin/news/edite/') &&  <EditeNews language={language} currentPathname={currentPathname} />}
    {currentPathname === 'admin/vacancies/add' && <AddVacancies language={language}/>}
    {currentPathname.startsWith('admin/vacancies/edite/') &&  <EditeVacancies language={language} currentPathname={currentPathname} />}
    {currentPathname === 'admin/rate' && (userStatus === 'superadmin' || userStatus === 'admin' || userStatus === 'moderator') && <Cals language={language}/>}
    


    <footer className="w-full bg-gray-800 shadow mt-10 ">
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-5 flex items-center justify-between">
        <span className="text-sm text-gray-300 sm:text-center">
          <a href="https://navju.tech" className="hover:underline blank" target="_blank"><img src={NavjuLogo} alt="Navju Logo" className='h-6'/></a>
        </span>
      <span className="text-sm text-gray-300 sm:text-center">{GlobalText(58)}
      </span>
      
      </div>
  </footer>
    </div>)
}

export default Admin