import { useLocation } from "react-router-dom"
  
  function  GlobalText (i) {       
    const location = useLocation();
    const isCurrentlyEn = location.pathname.startsWith('/en');
    const isCurrentlyRu = location.pathname.startsWith('/ru');
    const language = isCurrentlyEn ? 'en' : isCurrentlyRu ? 'ru' : 'tg'
    const texts = [
        {  // 0
            'tg': 'Тоҷикӣ',
            'ru': 'Русский',
            'en': 'English'
        },
        { // 1
            'tg': 'Ба шахсони воқеӣ',
            'ru': 'Частным лицам',
            'en': 'For private clients'
        },
        { // 2
            'tg': 'Ба шахсони ҳуқуқӣ',
            'ru': 'Юридическим лицам',
            'en': 'For business'
        },
        { // 3
            'tg': 'Дар бораи мо',
            'ru': 'О нас',
            'en': 'About Us'
        },
        { // 4
            'tg': 'Суроғаҳо ва банкоматҳо',
            'ru': 'Адреса и банкоматы',
            'en': 'Addresses and ATMs'
        },
        { // 5
            'tg': 'Бонки рушди Тоҷикистон',
            'ru': 'Банк Развития Таджикистана',
            'en': 'Development Bank of Tajikistan'
        },
        { // 6
            'tg': 'Пӯшидани меню',
            'ru': 'Закрыть меню',
            'en': 'Close the menu'
        },
        { // 7
            'tg': 'Иваз кардани забон',
            'ru': 'Изменить язык',
            'en': 'Change language'
        },
        { // 8
            'tg': 'Почтаи электронӣ',
            'ru': 'Электронная почта',
            'en': 'Email'
        },
        { // 9
            'tg': 'Рамз',
            'ru': 'Пароль',
            'en': 'Password'
        },
        { // 10
            'tg': 'Намуна: name@company.com',
            'ru': 'Пример: name@company.com',
            'en': 'Example: name@company.com'
        },
        { // 11
            'tg': 'Ворид шудан',
            'ru': 'Вход',
            'en': 'Sign in'
        },
        { // 12
            'tg': 'Хато! Почтаи электронӣ ё рамзро хато ворид кардед.',
            'ru': 'Ошибка! Вы ввели неправильный адрес электронной почты или пароль.',
            'en': 'Error! You entered an incorrect email address or password.'
        },
        { // 13
            'tg': 'Танзимоти аккаунт',
            'ru': 'Настройки аккаунта',
            'en': 'Account settings'
        },
        { // 14
            'tg': 'Хориҷ шудан',
            'ru': 'Выйти',
            'en': 'Log out'
        },
        { // 15
            'tg': 'Администратор',
            'ru': 'Администратор',
            'en': 'Administrator'
        },
        { // 16
            'tg': 'Модератор',
            'ru': 'Модератор',
            'en': 'Moderator'
        },
        { // 17
            'tg': 'Муҳаррир',
            'ru': 'Редактор',
            'en': 'Editor'
        },
        { // 18
            'tg': 'Воқеан хориҷ шудан мехоҳед?',
            'ru': 'Вы действительно хотите выйти?',
            'en': 'Are you sure you want to log out?'
        },
        { // 19
            'tg': 'Хабарҳо',
            'ru': 'Новости',
            'en': 'News'
        },
        { // 20
            'tg': 'Саҳифаҳо',
            'ru': 'Страницы',
            'en': 'Pages'
        },
        { // 21
            'tg': 'Корбарон',
            'ru': 'Пользователи',
            'en': 'Users'
        },
        { // 22
            'tg': 'Танзимот',
            'ru': 'Настройки',
            'en': 'Settings'
        },
        { // 23
            'tg': 'Иловаи саҳифаи нав',
            'ru': 'Добавить новую страницу',
            'en': 'Add new page'
        },
        { // 24
            'tg': 'URL-и саҳифа',
            'ru': 'URL-адрес страницы',
            'en': 'Page URL'
        },
        { // 25
            'tg': 'Номи саҳифа',
            'ru': 'Название страницы',
            'en': 'Page title'
        },
        { // 26
            'tg': 'Иловаи компонент',
            'ru': 'Добавить компонент',
            'en': 'Add component'
        },
        { // 27
            'tg': 'Сабт кардан',
            'ru': 'Сохранить',
            'en': 'Save'
        },
        { // 28
            'tg': 'Слайдер',
            'ru': 'Слайдер',
            'en': 'Slider'
        },
        { // 29
            'tg': 'Слайди',
            'ru': 'Слайд',
            'en': 'Slide'
        },
        { // 30
            'tg': 'Иловаи слайд',
            'ru': 'Добавить слайд',
            'en': 'Add a slide'
        },
        { // 31
            'tg': 'Илова кардан',
            'ru': 'Добавить',
            'en': 'Add'
        },
        { // 32
            'tg': 'Танзимоти умумӣ',
            'ru': 'Общие настройки',
            'en': 'General settings'
        },
        { // 33
            'tg': 'Илова кардани логотип',
            'ru': 'Добавить логотип',
            'en': 'Add a Logo'
        },
        { // 34
            'tg': 'Логотип',
            'ru': 'Логотип',
            'en': 'Logo'
        },
        { // 35
            'tg': 'Номи сомона',
            'ru': 'Название сайта',
            'en': 'Website name'
        },
        { // 36
            'tg': 'Иконка',
            'ru': 'Иконка',
            'en': 'Icon'
        },
        { // 37
            'tg': 'Калидвожаҳо',
            'ru': 'Ключевые слова',
            'en': 'Keywords'
        },
        { // 38
            'tg': 'Тағйирот бомуваффақият сабт карда шуд',
            'ru': 'Изменение успешно сохранено',
            'en': 'Change saved successfully'
        },
        { // 39
            'tg': 'Хато! Бори дигар такрор кунед.',
            'ru': 'Ошибка! Попробуйте снова.',
            'en': 'Error! Try it again.'
        },
        { // 40
            'tg': 'Меню',
            'ru': 'Меню',
            'en': 'Menu'
        },
        { // 41
            'tg': 'Ном',
            'ru': 'Название',
            'en': 'Name'
        },
        { // 42
            'tg': 'Суроғаи URL',
            'ru': 'URL-адрес',
            'en': 'URL address'
        },
        { // 43
            'tg': 'Иловаи банд',
            'ru': 'Добавить пункт',
            'en': 'Add item'
        },
        { // 44
            'tg': 'Иловаи корбар',
            'ru': 'Добавить пользователя',
            'en': 'Add user'
        },
        { // 45
            'tg': 'Номи корбар',
            'ru': 'Имя пользователя',
            'en': 'Username'
        },
        { // 46
            'tg': 'Такрори рамз',
            'ru': 'Повторить пароль',
            'en': 'Retype password'
        },
        { // 47
            'tg': 'Корбар илова карда шуд',
            'ru': 'Пользователь добавлен',
            'en': 'User added'
        },
        { // 48
            'tg': 'Воқеан ин корбарро ҳазф кардан мехоҳед?',
            'ru': 'Вы уверены, что хотите удалить этого пользователя?',
            'en': 'Are you sure you want to delete this user?'
        },
        { // 49
            'tg': 'Корбар нест карда шуд', 
            'ru': 'Пользователь был удален',
            'en': 'The user has been deleted'
        },
        { // 50
            'tg': 'Таҳрири корбар',
            'ru': 'Изменить пользователя',
            'en': 'Edit user'
        },
        { // 51
            'tg': 'Иваз кардани рамз (ихтиёрӣ)',
            'ru': 'Изменить пароль (необязательно)',
            'en': 'Change password (optional)'
        },
        { // 52
            'tg': 'Асосӣ',
            'ru': 'Основной',
            'en': 'Main'
        },
        { // 53
            'tg': 'Тоҷикӣ',
            'ru': 'Таджикский',
            'en': 'Tajik'
        },
        { // 54
            'tg': 'Русӣ',
            'ru': 'Русский',
            'en': 'Russian'
        },
        { // 55
            'tg': 'Англисӣ',
            'ru': 'Английский',
            'en': 'English'
        },
        { // 56
            'tg': 'Иловаи зербанд',
            'ru': 'Добавить подпункт',
            'en': 'Add sub-item'
        },
        { // 57
            'tg': 'Таҳрир кардан',
            'ru': 'Редактировать',
            'en': 'Edit'
        },
        { // 58
            'tg': '© 2024 Маҳсули Navju Tech',
            'ru': '© 2024 Создано Navju Tech',
            'en': '© 2024 Created by Navju Tech'
        },
        { // 59
            'tg': 'Иваз кардани рамз',
            'ru': 'Изменить пароль',
            'en': 'Change password'
        },
        { // 60
            'tg': 'Рамзи ҳозира',
            'ru': 'Текущий пароль',
            'en': 'Current password'
        },
        { // 61
            'tg': 'Рамзи нав',
            'ru': 'Новый пароль',
            'en': 'New password'
        },
        { // 62
            'tg': 'Рамзи навро дубора ворид кунед',
            'ru': 'Введите новый пароль еще раз',
            'en': 'Enter your new password again'
        },
        { // 63
            'tg': 'Рамзи нав дар ҳарду майдон бояд мувофиқат кунад',
            'ru': 'Новый пароль в обоих полях должен совпадать',
            'en': 'The new password in both fields must match'
        },
        { // 64
            'tg': 'Иловаи хабар',
            'ru': 'Добавить новость',
            'en': 'Add news'
        },
        { // 65
            'tg': 'Сарлавҳа',
            'ru': 'Заголовок',
            'en': 'Title'
        },
        { // 66
            'tg': 'Матн',
            'ru': 'Текст',
            'en': 'Text'
        },
        { // 67
            'tg': 'Футер',
            'ru': 'Футер',
            'en': 'Footer'
        }, 
        { // 68
            'tg': 'Шарҳ',
            'ru': 'Значение',
            'en': 'Meaning'
        },
        { // 69
            'tg': 'Рақами телефон',
            'ru': 'Номер телефона',
            'en': 'Phone number'
        },
        { // 70
            'tg': 'Суроға',
            'ru': 'Адрес',
            'en': 'Address'
        },
        { // 71
            'tg': 'Тамос тариқи WhatsApp',
            'ru': 'Поддержка в WhatsApp',
            'en': 'WhatsApp support'
        },
        { // 72
            'tg': 'Тамос тариқи Telegram',
            'ru': 'Поддержка в Telegram',
            'en': 'Telegram support'
        },
        { // 73
            'tg': 'Иҷозатнома',
            'ru': 'Лицензия',
            'en': 'License'
        },
        { // 74
            'tg': 'Ҳуқуқи муаллифӣ',
            'ru': 'Авторские права',
            'en': 'Copyright'
        },
        { // 75
            'tg': 'Мо дар шабакаҳои иҷтимоӣ',
            'ru': 'Мы в социальных сетях',
            'en': 'We are on social networks'
        },
        { // 76
            'tg': 'Телефони боварии БМТ',
            'ru': 'Горячая линия НБТ',
            'en': 'NBT hotline'
        },
        { // 77
            'tg': 'Сутуни 1',
            'ru': 'Столбец 1',
            'en': 'Column 1'
        },
        { // 78
            'tg': 'Сутуни 2',
            'ru': 'Столбец 2',
            'en': 'Column 2'
        },
        { // 79
            'tg': 'Сутуни 3',
            'ru': 'Столбец 3',
            'en': 'Column 3'
        },
        { // 80
            'tg': 'Воқеан ин бандро ҳазф картан мехоҳед?',
            'ru': 'Вы действительно хотите удалить этот элемент?',
            'en': 'Are you sure you want to delete this item?'
        },
        { // 81
            'tg': 'Воқеан ин саҳифаро ҳазф картан мехоҳед?',
            'ru': 'Вы уверены, что хотите удалить эту страницу?',
            'en': 'Are you sure you want to delete this page?'
        },
        { // 82
            'tg': 'Саҳифа илова карда шуд',
            'ru': 'Страница добавлена',
            'en': 'The page has been added'
        },
        { // 83
            'tg': 'Саҳифа ҳазф карда шуд',
            'ru': 'Страница удалена',
            'en': 'The page has been deleted'
        },
        { // 84
            'tg': 'Бо чунин URL саҳифа мавҷуд аст',
            'ru': 'Есть страница с этим URL',
            'en': 'There is a page with this URL'
        },
        { // 85
            'tg': '2 блок',
            'ru': '2 блока',
            'en': '2 blocks'
        },
        { // 86
            'tg': '3 блок',
            'ru': '3 блока',
            'en': '3 blocks'
        },
        { // 87
            'tg': 'Ранг',
            'ru': 'Цвет',
            'en': 'Color'
        },
        { // 88
            'tg': 'Қурби асъор',
            'ru': 'Курс валюты',
            'en': 'Currency rate'
        },
        { // 89
            'tg': 'Воқеан ин компонентро ҳазф картан мехоҳед?',
            'ru': 'Вы уверены, что хотите удалить этот элемент?',
            'en': 'Are you sure you want to delete this item?'
        },
        { // 90
            'tg': 'Услуб',
            'ru': 'Стиль',
            'en': 'Style'
        },
        { // 91
            'tg': 'Зерматн',
            'ru': 'Подзаголовок',
            'en': 'Subtitle'
        },
        { // 92
            'tg': 'Пасманзар',
            'ru': 'Фоновый цвет',
            'en': 'Background color'
        },
        { // 93
            'tg': 'Расм',
            'ru': 'Картина',
            'en': 'Picture'
        },
        { // 94
            'tg': 'Тугмача',
            'ru': 'Кнопка',
            'en': 'Button'
        },
        { // 95
            'tg': 'Слайдҳо',
            'ru': 'Слайды',
            'en': 'Slides'
        },
        { // 96
            'tg': 'Ҳеҷ чиз ёфт нашуд',
            'ru': 'Ничего не найдено',
            'en': 'Nothing found'
        },
        { // 97
            'tg': 'Бо чунин суроға саҳифае мавҷуд нест',
            'ru': 'Страница не существует по этому адресу.',
            'en': 'The page does not exist at this address.'
        },
        { // 98
            'tg': 'Гузариш ба саҳифаи асосӣ',
            'ru': 'Перейти на главную страницу',
            'en': 'Go to main page'
        },
        { // 99
            'tg': 'Кортҳо',
            'ru': 'Карты',
            'en': 'Cards'
        },
        { // 100
            'tg': 'Тафсилот',
            'ru': 'Подробности',
            'en': 'Details'
        },
        { // 101
            'tg': 'Нишон додани ҳамаи кортҳо',
            'ru': 'Показать все карты',
            'en': 'Show all cards'
        },
        { // 102
            'tg': 'Гурӯҳҳо',
            'ru': 'Группы',
            'en': 'Groups'
        },
        { // 103
            'tg': 'Ҳамаи кортҳо',
            'ru': 'Все карты',
            'en': 'All cards'
        },
        { // 104
            'tg': 'Ҳамаи хабарҳо',
            'ru': 'Все новости',
            'en': 'All news'
        },
        { // 105
            'tg': 'Шарҳи кутоҳ',
            'ru': 'Краткое описание',
            'en': 'Short description'
        },
        { // 106
            'tg': 'Хабар илова карда шуд',
            'ru': 'Новость была добавлена',
            'en': 'The news was added'
        },
        { // 107
            'tg': 'Дар ин блок хабарҳои охирин нишон дода мешавад. Ба сайт бингаред.',
            'ru': 'В этом блоке отображаются последние новости. Посмотрите сайт.',
            'en': 'This block displays the latest news. Check out the website.'
        },
        { // 108
            'tg': 'Воқеан ин хабарро ҳазф кардан мехоҳед?',
            'ru': 'Вы действительно хотите удалить эту новость?',
            'en': 'Do you really want to delete this news?'
        },
        { // 109
            'tg': 'Хабар нест карда шуд', 
            'ru': 'Новость удалена',
            'en': 'News has been deleted'
        },
        { // 110
            'tg': 'Дар ин блок қурби асъор нишон дода мешавад.',
            'ru': 'В этом блоке отображается курс обмена валюты.',
            'en': 'This block displays the exchange rate.'
        },
        { // 111
            'tg': 'Ҳисобкунакҳо',
            'ru': 'Калькуляторы',
            'en': 'Calculators'
        },
        { // 112
            'tg': 'Иловаи гурӯҳ',
            'ru': 'Создать группу',
            'en': 'Create a group'
        },
        { // 113
            'tg': 'Харид',
            'ru': 'Купить',
            'en': 'Buy'
        },
        { // 114
            'tg': 'Фурӯш',
            'ru': 'Продать',
            'en': 'Sell'
        },
        { // 115
            'tg': 'Маҳсулот',
            'ru': 'Продукт',
            'en': 'Product'
        },
        { // 116
            'tg': 'Варақаи дархост',
            'ru': 'Форма заявки',
            'en': 'Application form'
        },
        { // 117
            'tg': 'Слайд-шоу',
            'ru': 'Слайд-шоу',
            'en': 'Slide show'
        },
        { // 118
            'tg': 'Блок бо расм',
            'ru': 'Блок с картинкой',
            'en': 'Block with a picture'
        },
        { // 119
            'tg': 'Рақамҳо',
            'ru': 'Цифры',
            'en': 'Numbers'
        },
        { // 120
            'tg': 'Таърих',
            'ru': 'История',
            'en': 'History'
        },
        { // 121
            'tg': 'Муфассал',
            'ru': 'Подробнее',
            'en': 'More details'
        },
        { // 122
            'tg': 'Бо ин URL аллакай хабар мавҷуд аст. Лутфан онро иваз кунед.',
            'ru': 'Новости с таким URL уже есть. Пожалуйста, замените его.',
            'en': 'There are already news with this URL. Please replace it.'
        },
        { // 123
            'tg': 'Фиристодан',
            'ru': 'Поделиться',
            'en': 'Share'
        },
        { // 124
            'tg': 'Харидан',
            'ru': 'Купить',
            'en': 'Buy'
        },
        { // 125
            'tg': 'Фурӯхтан',
            'ru': 'Продать',
            'en': 'Sell'
        },
        { // 126
            'tg': 'Гирифтан',
            'ru': 'Получу',
            'en': 'Receive'
        },
        { // 127
            'tg': 'Мубодила кардан',
            'ru': 'Заплачу',
            'en': 'Exchange'
        },
        { // 128
            'tg': 'Ҳисобкунаки пасандоз',
            'ru': 'Депозитный калькулятор',
            'en': 'Deposit calculator'
        },
        { // 129
            'tg': 'Ҳисобкунаки қарзӣ',
            'ru': 'Кредитный калькулятор',
            'en': 'Credit calculator'
        },
        { // 130
            'tg': 'Асъор',
            'ru': 'Валюта',
            'en': 'Currency'
        },
        { // 131
            'tg': 'Харид',
            'ru': 'Покупка',
            'en': 'Purchase'
        },
        { // 132
            'tg': 'Фурӯш',
            'ru': 'Продажа',
            'en': 'Sale'
        },
        { // 133
            'tg': 'Пайванд нусхабардорӣ карда шуд',
            'ru': 'Ссылка скопирована',
            'en': 'Link copied'
        },
        { // 134
            'tg': 'Пардохти барзиёд',
            'ru': 'Фактическая переплата',
            'en': 'Actual overpayment'
        },
        { // 135
            'tg': 'Пардохти моҳона',
            'ru': 'Ежемесячный платёж',
            'en': 'Monthly payment'
        },
        { // 136
            'tg': 'Фоизи солона',
            'ru': 'Годовая ставка',
            'en': 'Annual rate'
        },
        { // 137
            'tg': 'Маблағи қарз',
            'ru': 'Сумма кредита',
            'en': 'Amount of credit'
        },
        { // 138
            'tg': 'Муҳлат',
            'ru': 'Срок',
            'en': 'Term'
        },
        { // 139
            'tg': 'Гирифтани қарз',
            'ru': 'Получение кредита',
            'en': 'Getting a loan'
        },
        { // 140
            'tg': 'Даромад',
            'ru': 'Доход',
            'en': 'Income'
        },
        { // 141
            'tg': 'Амонат гузоштан',
            'ru': 'Открыть вклад',
            'en': 'Open a deposit'
        },
        { // 142
            'tg': 'Даромади моҳона',
            'ru': 'Ежемесячный доход',
            'en': 'Monthly income'
        },
        { // 143
            'tg': 'Маблағи амонат',
            'ru': 'Сумма вклада',
            'en': 'Deposit amount'
        },
        { // 144
            'tg': 'Ҳисоб кардан пас аз тарҳкунии 12% андоз',
            'ru': 'Рассчитать с вычетом 12% налога',
            'en': 'Calculate minus 12% tax'
        },
        { // 145
            'tg': 'PDF хонанда',
            'ru': 'PDF-ридер',
            'en': 'PDF reader'
        },
        { // 146
            'tg': 'Суроға',
            'ru': 'Адрес',
            'en': 'Adress'
        },
        { // 147
            'tg': 'Ҳуҷҷатҳо',
            'ru': 'Документы',
            'en': 'Documents'
        },
        { // 148
            'tg': 'Ҷойҳои кор',
            'ru': 'Вакансии',
            'en': 'Vacancies'
        },
        { // 149
            'tg': 'Тарофаҳо',
            'ru': 'Тарифы',
            'en': 'Tariffs'
        },
        { // 150
            'tg': 'Саволу ҷавобҳо',
            'ru': 'Вопросы и ответы',
            'en': 'Questions and answers'
        },
        { // 151
            'tg': 'Дархости Шумо фиристода шуд. Кормандони мо  дар фурсати наздиктарин бо шумо тамос мегиранд.',
            'ru': 'Ваш запрос был отправлен. Наши сотрудники свяжутся с вами как можно скорее.',
            'en': 'Your request has been sent. Our staff will contact you as soon as possible.'
        },
        { // 152
            'tg': 'Илова кардани эълон',
            'ru': 'Добавить объявление',
            'en': 'To add an advert'
        },
        { // 153
            'tg': 'Воқеан ин эълонро ҳазф кардан мехоҳед?',
            'ru': 'Вы уверены, что хотите удалить это объявление?',
            'en': 'Are you sure you want to delete this ad?'
        },
        { // 154
            'tg': 'Эълон нест карда шуд', 
            'ru': 'Объявление удалено',
            'en': 'The ad has been deleted'
        }
    ]    
    return(texts[i][language])
}

export default GlobalText