import GlobalText from "./globalText";
import { PencilIcon, TrashIcon, XMarkIcon, CheckIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/outline'
import { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, EffectCoverflow, Pagination, EffectCreative, Autoplay, Navigation } from 'swiper/modules';
import GlobalData from "./globalData";
import { PhotoIcon } from '@heroicons/react/24/outline'
import { FileInput, Label } from "flowbite-react";
import { Link } from 'react-router-dom';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'; // стили по умолчанию
import '@react-pdf-viewer/toolbar/lib/styles/index.css'; // стили панели инструментов

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import img_1 from '../imgs/pr/image5.png'
import img_2 from '../imgs/pr/image6.png'
import img_3 from '../imgs/pr/image7.png'
import img_8 from '../imgs/pr/image8.png'
import img_9 from '../imgs/pr/image9.png'
import img_10 from '../imgs/pr/image10.png'
import img_11 from '../imgs/pr/image11.png'
import img_12 from '../imgs/pr/image12.png'
import img_13 from '../imgs/pr/image13.png'
import img_14 from '../imgs/pr/image14.png'
import img_15 from '../imgs/pr/image15.png'
import img_16 from '../imgs/pr/image16.png'
import img_17 from '../imgs/pr/111.jpg'
import img_18 from '../imgs/pr/222.jpg'
import img_19 from '../imgs/pr/333.png'
import img_20 from '../imgs/pr/444.png'
import img_21 from '../imgs/pr/555.png'
import img_100 from '../imgs/pr/100.png'
import img_101 from '../imgs/pr/101.png'
import img_102 from '../imgs/pr/102.png'
import img_103 from '../imgs/pr/103.png'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

  function ReturnComponent(props) {    
    const {component, onDelete, onEdit, offEdit, offEdit2, index, language } = props; 
    const [isModalEdit, setIsModaEdit] = useState(false);
    const [title, setTitle] = useState(component.data.title);  
    const [showAll, setshowAll] = useState(component.data.showAll);  
    const [showCards, setshowCards] = useState(component.data.showAll === '1' ? 'all' : '0');  
    const [slides, setSlides] = useState(component.data.slides);  
    const [blocks, setblocks] = useState(component.data.blocks);  
    const [cards, setcards] = useState(component.data.cards);  
    const [items, setitems] = useState(component.data.items);  
    const [cardscat, setcardscat] = useState(component.data.categories);  
    const [titleColor, setTitleColor] = useState(component.data.titleColor);
    const [titleBold, setTitleBold] = useState(component.data.titleBold);
    const [titleStyle, setTitleStyle] = useState(component.data.titleStyle);     

    const IMGURL = GlobalData('imgUrl')
    
    useEffect(() => {
      setTitle(component.data.title);
      setSlides(component.data.slides)
      setblocks(component.data.blocks)
      setcards(component.data.cards)
      setitems(component.data.items)
      setcardscat(component.data.categories)
      setTitleColor(component.data.titleColor);
      setTitleBold(component.data.titleBold);
      setTitleStyle(component.data.titleStyle);
      setshowAll(component.data.showAll);
    }, [component]);

    const getImageUrl = (img_pre) => {
      if (img_pre && (img_pre instanceof Blob || img_pre instanceof File)) {
        try {
          return URL.createObjectURL(img_pre);
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    };
    
    
    if(component.name === 'text'){
      return(<div className={`relative rounded-md py-2 my-2 hover:shadow group ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow' : ''
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit ? GlobalText(65) : <p style={{ 
                color: titleColor,
                fontWeight: titleBold,
                fontStyle: titleStyle,
              }}>{title}</p> }
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2 opacity-0 group-hover:opacity-100 transition-opacity bg-white ${
              isModalEdit ? 'opacity-100' : ''
            }`}>
          <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)
                offEdit({
                  title: title,
                  title_color: titleColor,
                  title_bold: titleBold,
                  title_style: titleStyle
                }, index)                
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        {isModalEdit && <div className="pt-2">
          <div className="relative z-0 my-5">
            <input
              type="text"
              id={'text_' + index}    
              placeholder='' 
              autoComplete="off"
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              defaultValue={component.data.title}
              className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            />
            <label htmlFor={'text_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(66)}</label>
          </div>
          <div className="relative z-0 my-5">
              <div className="block text-md font-semi-bold text-gray-400 sm:text-md inline-flex items-center grid justify-items-stretch"> 
                <div className='flex items-center justify-self-end '>       
                  <input 
                    type="color" 
                    className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    id={'color_' + index}
                    defaultValue={titleColor} //"#2563eb" 
                    onChange={(e) => {
                      setTitleColor(e.target.value)
                    }}
                    title="Color"
                  />
                  <button onClick={(e) => {
                    if(titleBold === 'bolder'){
                      setTitleBold('normal')
                    }else{
                      setTitleBold('bolder')
                    }
                  }}
                    id={'titleBold_' + index}
                    className={classNames(
                      (titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'px-3 py-1 font-bold rounded-lg'
                    )}>
                  B
                  </button>
                  <button onClick={(e) => {
                    if(titleStyle === 'italic'){
                        setTitleStyle('normal')
                      }else{
                        setTitleStyle('italic')
                      }
                    }}
                    id={'titleStyle_' + index}
                    className={classNames(
                      (titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold italic rounded-lg '
                    )}>
                    I
                  </button>
                </div> 
         </div>              
            </div>
            <div className='border border-gray-300 p-3 rounded-lg text-xl text-gray-900 sm:text-2xl sm:tracking-tight my-3'>
            <p style={{ 
                color: titleColor,
                fontWeight: titleBold,
                fontStyle: titleStyle,
              }}>{title}</p>
          </div>
        </div>}
      </div>)
    }
    if(component.name === 'slider'){
      return(<div className={`relative rounded-md  my-10 hover:shadow group  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-8'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(28)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity bg-white${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <span>
            <button 
              onClick={() => {   
                if(slides.length > 1){
                  setSlides(slides.slice(0, slides.length - 1)); 
                }
                          
              }}
              type="button" className="text-gray-700 mx-1 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <MinusIcon className='h-4 w-5'/>
          </button>
          <span className="text-sm mx-2 text-center inline-flex items-center">{GlobalText(95)}: {slides.length.toString()}</span>
          <button 
              onClick={() => {  
                if(slides.length < 10){
                  const newSlide = {
                      title: 'New title',
                      subtitle: 'Subtitle',
                      img: '',
                      titleColor: '#ffffff',
                      titleBold: 'normal',
                      titleStyle: 'normal',
                      subtitleColor: '#ffffff',
                      subtitleBold: 'normal',
                      subtitleStyle: 'normal',
                      bgColor: '#000000',
                      buttonTitle: 'Button',
                      buttonBg: '#ffffff',
                      buttonTitleBold: 'normal',
                      buttonTitleStyle: 'normal',
                      buttonTitleColor: '#000000',
                  };     
                  setSlides([...slides, newSlide])   
                }  
              }}
              type="button" className="text-gray-700 mx-1 mr-2 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PlusIcon className='h-4 w-5'/>
          </button>
          </span> }
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)       
                offEdit({
                  slides: slides
                }, index)                
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        {isModalEdit && slides && 
          slides.map((slide, index) => {          
          return(<div key={'slide' + index} className="px-5 py-2 border border-dashed border-gray-300 rounded-xl my-4">
              <div className="relative z-0">
                <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={90}
                      id={'text_' + index}
                      onChange={(e) => {
                        const slides2 = [...slides];
                        slides2[index].title = e.target.value
                        setSlides(slides2) 
                      }}
                      defaultValue={slide.title}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label htmlFor={'text_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      id={'text_color_' + index}
                      defaultValue={slides[index].titleColor} 
                      onChange={(e) => {
                        const slides2 = [...slides];
                        slides2[index].titleColor = e.target.value
                        setSlides(slides2) 
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                      if(slides[index].titleBold === 'bolder'){
                        const slides2 = [...slides];
                        slides2[index].titleBold = 'normal'
                        setSlides(slides2) 
                      }else{
                        const slides2 = [...slides];
                        slides2[index].titleBold = 'bolder'
                        setSlides(slides2) 
                      }
                    }}
                      id={'titleBold_' + index}
                      className={classNames(
                        (slides[index].titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                      if(slides[index].titleStyle === 'italic'){
                        const slides2 = [...slides];
                        slides2[index].titleStyle = 'normal'
                        setSlides(slides2) 
                      }else{
                        const slides2 = [...slides];
                        slides2[index].titleStyle = 'italic'
                        setSlides(slides2) 
                      }
                      }}
                      id={'titleStyle_' + index}
                      className={classNames(
                        (slides[index].titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                  </div> 
                </div>              
              </div>
              <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={150}
                    id={'subtitle_' + index}
                    onChange={(e) => {
                      const slides2 = [...slides];
                      slides2[index].subtitle = e.target.value
                      setSlides(slides2) 
                    }}
                    defaultValue={slide.subtitle}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label htmlFor={'subtitle_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(91)}</label>
                </div>
                <div className='flex items-center justify-self-end '>       
                  <input 
                    type="color" 
                    className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    id={'subtitle_color_' + index}
                    defaultValue={slides[index].subtitleColor} 
                    onChange={(e) => {
                      const slides2 = [...slides];
                      slides2[index].subtitleColor = e.target.value
                      setSlides(slides2) 
                    }}
                    title="Color"
                  />
                  <button onClick={() => {
                      if(slides[index].subtitleBold === 'bolder'){
                        const slides2 = [...slides];
                        slides2[index].subtitleBold = 'normal'
                        setSlides(slides2) 
                      }else{
                        const slides2 = [...slides];
                        slides2[index].subtitleBold = 'bolder'
                        setSlides(slides2) 
                      }
                    }}
                    id={'subtitleBold_' + index}
                    className={classNames(
                      (slides[index].subtitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'px-3 py-1 font-bold rounded-lg'
                    )}>
                  B
                  </button>
                  <button onClick={(e) => {
                      if(slides[index].subtitleStyle === 'italic'){
                        const slides2 = [...slides];
                        slides2[index].subtitleStyle = 'normal'
                        setSlides(slides2) 
                      }else{
                        const slides2 = [...slides];
                        slides2[index].subtitleStyle = 'italic'
                        setSlides(slides2) 
                      }
                      }}
                    id={'subtitleStyle_' + index}
                    className={classNames(
                      (slides[index].subtitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold italic rounded-lg '
                    )}>
                    I
                  </button>
                </div> 
              </div>              
            </div>
            <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-start flex justify-items-stretch  grid sm:grid-cols-2 gap-2"> 
                <div className="border rounded-xl px-3 py-2">
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={20}
                    id={'button_title_' + index}
                    onChange={(e) => {
                      const slides2 = [...slides];
                      slides2[index].buttonTitle = e.target.value
                      setSlides(slides2) 
                    }}
                    defaultValue={slide.buttonTitle}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label htmlFor={'button_title_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(94)}</label>
                </div>
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={150}
                    id={'button_url_' + index}
                    onChange={(e) => {
                      const slides2 = [...slides];
                      slides2[index].buttonUrl = e.target.value
                      setSlides(slides2) 
                    }}
                    defaultValue={slide.buttonUrl}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label htmlFor={'button_url_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                </div>
                <div className='flex items-center justify-self-end '>       
                  <input 
                    type="color" 
                    className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    id={'button_bg' + index}
                    defaultValue={slides[index].buttonBg} 
                    onChange={(e) => {
                      const slides2 = [...slides];
                      slides2[index].buttonBg = e.target.value
                      setSlides(slides2) 
                    }}
                    title="Color"
                  />
                  <button onClick={() => {
                      if(slides[index].buttonTitleBold === 'bolder'){
                        const slides2 = [...slides];
                        slides2[index].buttonTitleBold = 'normal'
                        setSlides(slides2) 
                      }else{
                        const slides2 = [...slides];
                        slides2[index].buttonTitleBold = 'bolder'
                        setSlides(slides2) 
                      }
                    }}
                    id={'button_title_bold' + index}
                    className={classNames(
                      (slides[index].buttonTitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'px-3 py-1 font-bold rounded-lg'
                    )}>
                  B
                  </button>
                  <button onClick={(e) => {
                      if(slides[index].buttonTitleStyle === 'italic'){
                        const slides2 = [...slides];
                        slides2[index].buttonTitleStyle = 'normal'
                        setSlides(slides2) 
                      }else{
                        const slides2 = [...slides];
                        slides2[index].buttonTitleStyle = 'italic'
                        setSlides(slides2) 
                      }
                      }}
                    id={'button_title_style' + index}
                    className={classNames(
                      (slides[index].buttonTitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold italic rounded-lg '
                    )}>
                    I
                  </button>
                  <button onClick={(e) => {
                      if(slides[index].buttonTitleTransform === 'uppercase'){
                        const slides2 = [...slides];
                        slides2[index].buttonTitleTransform = 'none'
                        setSlides(slides2) 
                      }else{
                        const slides2 = [...slides];
                        slides2[index].buttonTitleTransform = 'uppercase'
                        setSlides(slides2) 
                      }
                      }}
                    id={'button_title_transform' + index}
                    className={classNames(
                      (slides[index].buttonTitleTransform === 'uppercase') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold rounded-lg '
                    )}>
                    T
                  </button>
                  <input 
                    type="color" 
                    className="ml-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    id={'button_title_color' + index}
                    defaultValue={slides[index].buttonTitleColor} 
                    onChange={(e) => {
                      const slides2 = [...slides];
                      slides2[index].buttonTitleColor = e.target.value
                      setSlides(slides2) 
                    }}
                    title="Color"
                  />
                </div> 
                </div>
                <div className="border rounded-xl px-3 py-2 grid grid-cols-2 gap-3 items-start">
                  <div className="w-full relative z-0 my-5 mr-2 pt-1">
                    <input 
                      type="color" 
                      className="block w-full mt-3 h-32 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      id={'bgcolor_' + index}
                      defaultValue={slide.bgColor} 
                      onChange={(e) => {
                        const slides2 = [...slides];
                        slides2[index].bgColor = e.target.value
                        setSlides(slides2) 
                      }}
                      title="Color"
                    />
                    <label htmlFor={'bgcolor_' + index} className="absolute duration-300 top-3 origin-0 text-gray-500 ">{GlobalText(92)}</label>
                  </div>
                    <div className="w-full relative z-0 mr-2 ">
                      <label htmlFor={'img_' + index} className="absolute duration-300 top-3 origin-0 text-gray-500 text-sm">{GlobalText(93)}</label>
                      <label htmlFor={'img_' + index}>
                        <div className="block w-full mt-9 h-32 content-center cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 border border-dashed border-gray-300  ">
                          {getImageUrl(slide.img_pre) ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                className='h-32 w-auto p-3'
                                src={getImageUrl(slide.img_pre)}
                              />
                            </div> : slide.img ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                className='h-32 w-auto p-3'
                                src={IMGURL + slide.img}
                              />
                            </div> : <div className="flex justify-center items-center">
                              <PhotoIcon className='text-gray-300 h-20 items-center '/>
                            </div> }                        
                          </div>  
                        </label>
                        <FileInput id={'img_' + index} className="hidden" 
                          onChange={(event) => {
                            const slides2 = [...slides];
                            slides2[index].img_pre = event.target.files[0]
                            setSlides(slides2)
                          }}
                        />                      
                    </div>
                </div>
              </div>              
            </div>
          </div>)})}
        <div className="">
          <Swiper 
            autoHeight={true}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            speed={500}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,          
            }}
            pagination={{
              clickable: true
            }}
            modules={[Autoplay, EffectCreative, Pagination]}
            className="swiper mt-5 max-w-6xl rounded-3xl "
            creativeEffect={{
            prev: {
              shadow: false,
              translate: ['-120%', 0, -500],
            },
            next: {
              shadow: false,
              translate: ['120%', 0, -500],
            },
          }}
        >
          {slides && slides.map((slide, index) => (
            <SwiperSlide>
              <div className="bg-white rounded-3xl" >
              <div className="mx-0  rounded-3xl">
                <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-10 rounded-3xl sm:px-10 md:pt-15 lg:flex lg:gap-x-20 lg:px-20 lg:pt-0 h-96" 
                  style={{ 
                    backgroundColor: slide.bgColor,
                    height: 450
                  }}>
                  
                  <div className="mx-auto text-left lg:mx-0 lg:flex lg:py-32 lg:text-left lg:items-center">
                    <div>
                    <h2 className="text-3xl tracking-tight text-white sm:text-4xl" 
                    style={{ 
                      color: slide.titleColor,
                      fontWeight: slide.titleBold,
                      fontStyle: slide.titleStyle,
                    }}>
                      {slide.title}
                    </h2>
                    <p className="mt-6 text-lg leading-6 text-gray-300" style={{ 
                      color: slide.subtitleColor,
                      fontWeight: slide.subtitleBold,
                      fontStyle: slide.subtitleStyle,
                    }}>
                      {slide.subtitle}
                    </p>
                    <div className='flex'>
                    <Link to={slide.buttonUrl}
                        className="z-40 absolute bottom-10 inset-x-5 text-center rounded-lg brt-bg-blue px-3.5 py-3 font-semibold hover:bg-blend-darken lg:static lg:mt-10 lg:flex lg:items-center lg:justify-center lg:gap-x-6 lg:lg:justify-start lg:inset-0"
                      style={{ 
                        backgroundColor: slide.buttonBg,
                      }}>
                        <span style={{ 
                        color: slide.buttonTitleColor,
                        fontWeight: slide.buttonTitleBold,
                        fontStyle: slide.buttonTitleStyle,
                        textTransform: slide.buttonTitleTransform
                      }}>{slide.buttonTitle}</span>
                      </Link>
                      </div>
                    </div>
                    
                  </div>
                  <div className="relative mt-0 mb-20 lg:mb-0 flex items-center justify-center">
                  {getImageUrl(slide.img_pre) ? <div className="flex justify-center items-center ">
                      <img
                        alt=""
                        className='max-w-full lg:max-w-md'
                        style={{width:'400px'}}
                        src={getImageUrl(slide.img_pre)}
                      />
                    </div> : slide.img ? <div className="flex justify-center items-center ">
                          <img
                            alt=""
                            className='max-w-full lg:max-w-md'
                            style={{width:'400px'}}
                            src={IMGURL + slide.img}
                          />
                        </div> : <div className="flex justify-center items-center">
                        <PhotoIcon className='text-gray-300 h-64 items-center '/>
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          ) )}
        </Swiper>
        </div>
      </div>)
    }

    if(component.name === '2block'){
      return(<div className={`relative rounded-md hover:shadow group my-10 ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-8'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(85)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity bg-white${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)       
                offEdit({
                  blocks: blocks
                }, index)                
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        {isModalEdit && blocks && 
          blocks.map((block, index) => {          
          return(<div key={'slide' + index} className="px-5 py-2 border border-dashed border-gray-300 rounded-xl my-4">
              <div className="relative z-0">
                <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={90}
                      id={'title_' + index}
                      onChange={(e) => {
                        const blocks2 = [...blocks];
                        blocks2[index].title = e.target.value
                        setblocks(blocks2) 
                      }}
                      defaultValue={block.title}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label htmlFor={'title_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      id={'text_color_' + index}
                      defaultValue={blocks[index].titleColor} 
                      onChange={(e) => {
                        const blocks2 = [...blocks];
                        blocks2[index].titleColor = e.target.value
                        setblocks(blocks2) 
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                      if(blocks[index].titleBold === 'bolder'){
                        const blocks2 = [...blocks];
                        blocks2[index].titleBold = 'normal'
                        setblocks(blocks2) 
                      }else{
                        const blocks2 = [...blocks];
                        blocks2[index].titleBold = 'bolder'
                        setblocks(blocks2) 
                      }
                    }}
                      id={'titleBold_' + index}
                      className={classNames(
                        (blocks[index].titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                      if(blocks[index].titleStyle === 'italic'){
                        const blocks2 = [...blocks];
                        blocks2[index].titleStyle = 'normal'
                        setblocks(blocks2) 
                      }else{
                        const blocks2 = [...blocks];
                        blocks2[index].titleStyle = 'italic'
                        setblocks(blocks2) 
                      }
                      }}
                      id={'titleStyle_' + index}
                      className={classNames(
                        (blocks[index].titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                  </div> 
                </div>              
              </div>
              <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={150}
                    id={'subtitle_' + index}
                    onChange={(e) => {
                      const blocks2 = [...blocks];
                      blocks2[index].subtitle = e.target.value
                      setblocks(blocks2) 
                    }}
                    defaultValue={block.subtitle}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label htmlFor={'subtitle_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(91)}</label>
                </div>
                <div className='flex items-center justify-self-end '>       
                  <input 
                    type="color" 
                    className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    id={'subtitle_color_' + index}
                    defaultValue={blocks[index].subtitleColor} 
                    onChange={(e) => {
                      const blocks2 = [...blocks];
                      blocks2[index].subtitleColor = e.target.value
                      setblocks(blocks2) 
                    }}
                    title="Color"
                  />
                  <button onClick={() => {
                      if(blocks[index].subtitleBold === 'bolder'){
                        const blocks2 = [...blocks];
                        blocks2[index].subtitleBold = 'normal'
                        setblocks(blocks2) 
                      }else{
                        const blocks2 = [...blocks];
                        blocks2[index].subtitleBold = 'bolder'
                        setblocks(blocks2) 
                      }
                    }}
                    id={'subtitleBold_' + index}
                    className={classNames(
                      (blocks[index].subtitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'px-3 py-1 font-bold rounded-lg'
                    )}>
                  B
                  </button>
                  <button onClick={(e) => {
                      if(blocks[index].subtitleStyle === 'italic'){
                        const blocks2 = [...blocks];
                        blocks2[index].subtitleStyle = 'normal'
                        setblocks(blocks2) 
                      }else{
                        const blocks2 = [...blocks];
                        blocks2[index].subtitleStyle = 'italic'
                        setblocks(blocks2) 
                      }
                      }}
                    id={'subtitleStyle_' + index}
                    className={classNames(
                      (blocks[index].subtitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold italic rounded-lg '
                    )}>
                    I
                  </button>
                </div> 
              </div>              
            </div>
            <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-start flex justify-items-stretch  grid sm:grid-cols-2 gap-2"> 
                <div className="border rounded-xl px-3 py-2">
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={20}
                    id={'button_title_' + index}
                    onChange={(e) => {
                      const blocks2 = [...blocks];
                      blocks2[index].buttonTitle = e.target.value
                      setblocks(blocks2) 
                    }}
                    defaultValue={block.buttonTitle}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label htmlFor={'button_title_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(94)}</label>
                </div>
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={150}
                    id={'button_url_' + index}
                    onChange={(e) => {
                      const blocks2 = [...blocks];
                      blocks2[index].buttonUrl = e.target.value
                      setblocks(blocks2) 
                    }}
                    defaultValue={block.buttonUrl}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label htmlFor={'button_url_' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                </div>
                <div className='flex items-center justify-self-end '>  
                  <button onClick={() => {
                      if(blocks[index].buttonTitleBold === 'bolder'){
                        const blocks2 = [...blocks];
                        blocks2[index].buttonTitleBold = 'normal'
                        setblocks(blocks2) 
                      }else{
                        const blocks2 = [...blocks];
                        blocks2[index].buttonTitleBold = 'bolder'
                        setblocks(blocks2) 
                      }
                    }}
                    id={'button_title_bold' + index}
                    className={classNames(
                      (blocks[index].buttonTitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'px-3 py-1 font-bold rounded-lg'
                    )}>
                  B
                  </button>
                  <button onClick={(e) => {
                      if(blocks[index].buttonTitleStyle === 'italic'){
                        const blocks2 = [...blocks];
                        blocks2[index].buttonTitleStyle = 'normal'
                        setblocks(blocks2) 
                      }else{
                        const blocks2 = [...blocks];
                        blocks2[index].buttonTitleStyle = 'italic'
                        setblocks(blocks2) 
                      }
                      }}
                    id={'button_title_style' + index}
                    className={classNames(
                      (blocks[index].buttonTitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold italic rounded-lg '
                    )}>
                    I
                  </button>
                  <button onClick={(e) => {
                      if(blocks[index].buttonTitleTransform === 'uppercase'){
                        const slides2 = [...blocks];
                        slides2[index].buttonTitleTransform = 'none'
                        setblocks(slides2) 
                      }else{
                        const slides2 = [...blocks];
                        slides2[index].buttonTitleTransform = 'uppercase'
                        setblocks(slides2) 
                      }
                      }}
                    id={'button_title_transform' + index}
                    className={classNames(
                      (blocks[index].buttonTitleTransform === 'uppercase') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold rounded-lg '
                    )}>
                    T
                  </button>
                  <input 
                    type="color" 
                    className="ml-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    id={'button_title_color' + index}
                    defaultValue={blocks[index].buttonTitleColor} 
                    onChange={(e) => {
                      const slides2 = [...blocks];
                      slides2[index].buttonTitleColor = e.target.value
                      setblocks(slides2) 
                    }}
                    title="Color"
                  />
                </div> 
                </div>
                <div className="border rounded-xl px-3 py-2 grid grid-cols-2 gap-3 items-start">
                  <div className="w-full relative z-0 my-5 mr-2 pt-1">
                    <input 
                      type="color" 
                      className="block w-full mt-3 h-32 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      id={'bgcolor_' + index}
                      defaultValue={block.bgColor} 
                      onChange={(e) => {
                        const slides2 = [...blocks];
                        slides2[index].bgColor = e.target.value
                        setblocks(slides2) 
                      }}
                      title="Color"
                    />
                    <label htmlFor={'bgcolor_' + index} className="absolute duration-300 top-3 origin-0 text-gray-500 ">{GlobalText(92)}</label>
                  </div>
                    <div className="w-full relative z-0 mr-2 ">
                      <label htmlFor={'img_' + index} className="absolute duration-300 top-3 origin-0 text-gray-500 text-sm">{GlobalText(93)}</label>
                      <label htmlFor={'img_' + index}>
                        <div className="block w-full mt-9 h-32 content-center cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 border border-dashed border-gray-300  ">
                          {getImageUrl(block.img_pre) ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                className='h-32 w-auto p-3'
                                src={getImageUrl(block.img_pre)}
                              />
                            </div> : block.img ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                className='h-32 w-auto p-3'
                                src={IMGURL + block.img}
                              />
                            </div> : <div className="flex justify-center items-center">
                              <PhotoIcon className='text-gray-300 h-20 items-center '/>
                            </div> }                        
                          </div>  
                        </label>
                        <FileInput id={'img_' + index} className="hidden" 
                          onChange={(event) => {
                            const slides2 = [...blocks];
                            slides2[index].img_pre = event.target.files[0]
                            setblocks(slides2)
                          }}
                        />                      
                    </div>
                </div>
              </div>              
            </div>
          </div>)})}
        <div className="grid grid-flow-row md:grid-flow-col gap-4 mt-4">
        {blocks && blocks.map((block, index) => (
            <article className="relative flex w-full flex-wrap justify-center rounded-3xl md:flex-nowrap p-4"
              style={{ 
                backgroundColor: block.bgColor,
              }}>
            <div className="flex w-full flex-col justify-center px-4 pt-5 md:pr-0 md:py-7 md:pl-7 ">
              <header className="mb-4.2 max-w-[360px] md:mb-7.25 pl-6 pt-5 sm:pl-0 sm:pt-0">
                <h2 className="text-xl tracking-tight sm:text-xl text-bold " style={{ 
                      color: block.titleColor,
                      fontWeight: block.titleBold,
                      fontStyle: block.titleStyle,
                    }}>{block.title}</h2>
                <div className="rich-text-editor bodyNormal mt-1.5 md:mt-3">
                  <p style={{ 
                      color: block.subtitleColor,
                      fontWeight: block.subtitleBold,
                      fontStyle: block.subtitleStyle,
                    }}>
                    {block.subtitle}
                  </p>
                </div>
              </header>
              <Link className="inline-block hidden md:block mt-6 " 
                to={block.buttonUrl}>
                <button
                  type="button"
                  className="group grid grid-flow-col items-center justify-center outline-none duration-150 text-base leading-5 gap-1 text-green hover:text-green-90 active:text-green-100 disabled:text-grey rounded-lg"
                  aria-label={block.buttonTitle}
                >
                  <span style={{ 
                    color: block.buttonTitleColor,
                    fontWeight: block.buttonTitleBold,
                    fontStyle: block.buttonTitleStyle,
                    textTransform: block.buttonTitleTransform
                  }}>{block.buttonTitle}</span>
                  <div className="fill-blue group-hover:fill-green-90 group-active:fill-green-100 group-disabled:fill-grey "
                    style={{ 
                      color: block.buttonTitleColor,
                      fontWeight: block.buttonTitleBold,
                      fontStyle: block.buttonTitleStyle,
                      textTransform: block.buttonTitleTransform
                    }}>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.4226 3.57741L10.8333 2.98816L9.65482 4.16667L10.2441 4.75593L14.6548 9.16667L3.33333 9.16667H2.5V10.8333L3.33333 10.8333L14.6548 10.8333L10.2441 15.2441L9.65482 15.8333L10.8333 17.0118L11.4226 16.4226L17.2559 10.5893C17.5814 10.2638 17.5814 9.73618 17.2559 9.41075L11.4226 3.57741Z"
                      />
                    </svg>
                  </div>
                </button>
              </Link>
            </div>
            <figure className="flex w-full items-center justify-center md:justify-end">
              <div className="absolute bottom-5 left-5 z-10 md:hidden">
                <div className="flex whitespace-nowrap" style={{ 
                      color: block.buttonTitleColor
                    }}>
                  <span style={{ 
                      color: block.buttonTitleColor
                    }}>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.7686 5.21126L13.0615 4.50415L11.6473 5.91836L12.3544 6.62547L17.6473 11.9184L4.06152 11.9184H3.06152V13.9184H4.06152L17.6473 13.9184L12.3544 19.2113L11.6473 19.9184L13.0615 21.3326L13.7686 20.6255L20.7686 13.6255C21.1592 13.2349 21.1592 12.6018 20.7686 12.2113L13.7686 5.21126Z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="relative mt-0 mb-20 lg:mb-0 flex items-center justify-center">
                  {getImageUrl(block.img_pre) ? <div className="flex justify-center items-center ">
                      <img
                        alt=""
                        className='max-w-full lg:max-w-md'
                        style={{width:'250px'}}
                        src={getImageUrl(block.img_pre)}
                      />
                    </div> : block.img ? <div className="flex justify-center items-center ">
                          <img
                            alt=""
                            className='max-w-full lg:max-w-md'
                            style={{width:'250px'}}
                            src={IMGURL + block.img}
                          />
                        </div> : <div className="flex justify-center items-center my-3 mx-3">
                          <PhotoIcon className='text-gray-300 h-52 items-center '/>
                      </div>}
                  </div>
            </figure>
          </article>
          ) )}
        </div>
      </div>)
    }

    if(component.name === 'cards'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(99)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <span>
            <button 
              onClick={() => {   
                if(cards.length > 1){
                  setcards(cards.slice(0, cards.length - 1)); 
                }
                          
              }}
              type="button" className="text-gray-700 mx-1 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <MinusIcon className='h-4 w-5'/>
          </button>
          <span className="text-sm mx-2 text-center inline-flex items-center">{GlobalText(99)}: {cards.length.toString()}</span>
          <button 
              onClick={() => {  
                if(cards.length < 20){
                  const newCard = {
                    title: 'Card name',
                    subtitle: 'Subtitle',
                    img: '',
                    titleColor: '#ffffff',
                    titleBold: 'normal',
                    titleStyle: 'normal',
                    subtitleColor: '#ffffff',
                    subtitleBold: 'normal',
                    subtitleStyle: 'normal',
                    bgColor: '#000000',
                    buttonTitle: 'Button',
                    buttonBg: '#ffffff',
                    buttonTitleBold: 'normal',
                    buttonTitleStyle: 'normal',
                    buttonTitleColor: '#000000',
                    buttonTitle2: 'Button',
                    buttonTitleBold2: 'normal',
                    buttonTitleStyle2: 'normal',
                    buttonTitleColor2: '#fff',
                    cardType: 0,
                    details: [
                      {
                        title: 'Title',
                        subtitle: 'Subtitle',
                        titleColor: '#ffffff',
                        titleBold: 'normal',
                        titleStyle: 'normal',
                        subtitleColor: '#ffffff',
                        subtitleBold: 'normal',
                        subtitleStyle: 'normal',
                      }
                    ]
                  }   
                  setcards([...cards, newCard])   
                }  
              }}
              type="button" className="text-gray-700 mx-1 mr-2 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PlusIcon className='h-4 w-5'/>
          </button>
          </span> }
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)  
                offEdit({
                  title: title,
                  titleColor: titleColor,
                  titleBold: titleBold,
                  titleStyle: titleStyle,
                  showAll: showAll,
                  categories: cardscat,
                  cards: cards
                }, index)                 
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        {isModalEdit && <div>
          <div className="relative z-0">
          <div className="text-md font-semi-bold sm:text-md items-start flex justify-items-stretch mb-8"> 
            <div className="text-md font-semi-bold w-full mx-2"> 
              <div className="w-full relative z-0 my-5 mr-2">
                <input
                  type="text"   
                  placeholder='' 
                  autoComplete="off"
                  maxLength={90}
                  id={'text_'}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                  defaultValue={component.data.title}
                  className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                />
                <label htmlFor={'text_' } className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
              </div>
              <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      id={'text_color_' + index}
                      defaultValue={titleColor} 
                      onChange={(e) => {
                        setTitleColor(e.target.value)
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                      if(titleBold === 'bolder'){
                        setTitleBold('normal')
                      }else{
                        setTitleBold('bolder')
                      }
                    }}
                      className={classNames(
                        (titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                      if(titleStyle === 'italic'){
                        setTitleStyle('normal')
                      }else{
                        setTitleStyle('italic')
                      }
                      }}
                      className={classNames(
                        (titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
              </div> 
             
            </div>   
            <div className="text-md font-semi-bold w-full mx-2"> 
                <label for={'show_all' + index} className="block text-md font-medium leading-6 text-gray-900 inline-flex items-center">
                <input checked={showAll === '1' ? true : false} type="checkbox" id={'show_all' + index}  onChange={(e) => {
                  if(showAll === '0'){
                    setshowAll('1')
                    setshowCards('all')
                  }else{
                    setshowAll('0')
                    setshowCards('0')
                  }                  
                }} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> 
                <span className='ml-2'>{GlobalText(101)}</span>
              </label>
              <div className="border rounded-xl px-3 py-2 mt-2">
                  <div className="flex items-center">
                    <h6 className="text-sm text-gray-500 mr-4 my-3">{GlobalText(102)}</h6>
                    <span className="flex items-center">
                      <button 
                        onClick={() => {   
                          if(cardscat.length > 1){
                            setcardscat(cardscat.slice(0, cardscat.length - 1)); 
                          }
                                    
                        }}
                        type="button" className="text-gray-700 mx-1 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                        <MinusIcon className='h-4 w-5'/>
                      </button>
                      <span className="text-sm mx-2 text-center inline-flex items-center"> {cardscat.length.toString()}</span>
                      <button 
                          onClick={() => {  
                            if(cardscat.length < 9){
                              const newElement = {
                                name: 'Name',
                              };   
                              setcardscat([...cardscat, newElement])    
                            }  
                          }}
                          type="button" className="text-gray-700 mx-1 mr-2 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                          <PlusIcon className='h-4 w-5'/>
                      </button>
                    </span>
                  </div> 
                  {cardscat && cardscat.map((element, cindex) => (
                    <div className="">
                      <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                        <div className="w-full relative z-0 my-5 mr-2">
                          <input
                            type="text"   
                            placeholder='' 
                            autoComplete="off"
                            maxLength={20}
                            onChange={(e) => {
                              const editeData = [...cardscat];
                              editeData[cindex].name = e.target.value
                              setcardscat(editeData) 
                            }}
                            defaultValue={element.name}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41)}</label>
                        </div>
                      </div> 
                    </div>
                  ))}
                  
                </div>
            </div>    
          </div>              
          </div>
          {cards && cards.map((element, index) => {   
            return(<div key={'card' + index} className="px-5 py-2 border border-dashed border-gray-300 rounded-xl my-4">
              <div className="relative z-0">
                  <select onChange={(e) => {
                    const slides2 = [...cards];
                    slides2[index].cardType = e.target.value.toString()
                    setcards(slides2) 
                    console.log(cards)
                  }}
                  className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  {cardscat && cardscat.map((element2, cindex) => (
                    <option value={cindex} selected={element.cardType === cindex.toString()}>{element2.name}</option>
                  ))}                  
                </select>
                <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={90}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].title = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.title}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      defaultValue={element.titleColor} 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].titleColor = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                      if(element.titleBold === 'bolder'){
                        const slides2 = [...cards];
                        slides2[index].titleBold = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].titleBold = 'bolder'
                        setcards(slides2) 
                      }
                    }}
                      className={classNames(
                        (element.titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                      if(element.titleStyle === 'italic'){
                        const slides2 = [...cards];
                        slides2[index].titleStyle = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].titleStyle = 'italic'
                        setcards(slides2) 
                      }
                      }}
                      className={classNames(
                        (element.titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                  </div> 
                </div>              
              </div>
              <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={150}
                    onChange={(e) => {
                      const slides2 = [...cards];
                      slides2[index].subtitle = e.target.value
                      setcards(slides2) 
                    }}
                    defaultValue={element.subtitle}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(91)}</label>
                </div>
                <div className='flex items-center justify-self-end '>       
                  <input 
                    type="color" 
                    className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    defaultValue={element.subtitleColor} 
                    onChange={(e) => {
                      const slides2 = [...cards];
                      slides2[index].subtitleColor = e.target.value
                      setcards(slides2) 
                    }}
                    title="Color"
                  />
                  <button onClick={() => {
                      if(element.subtitleBold === 'bolder'){
                        const slides2 = [...cards];
                        slides2[index].subtitleBold = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].subtitleBold = 'bolder'
                        setcards(slides2) 
                      }
                    }}
                    className={classNames(
                      (element.subtitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'px-3 py-1 font-bold rounded-lg'
                    )}>
                  B
                  </button>
                  <button onClick={(e) => {
                      if(element.subtitleStyle === 'italic'){
                        const slides2 = [...cards];
                        slides2[index].subtitleStyle = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].subtitleStyle = 'italic'
                        setcards(slides2) 
                      }
                      }}
                    className={classNames(
                      (element.subtitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold italic rounded-lg '
                    )}>
                    I
                  </button>
                </div> 
              </div>              
            </div>
            <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-start flex justify-items-stretch  grid sm:grid-cols-2 gap-2"> 
              <div>
                <div className="border rounded-xl px-3 py-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 items-start">
                  <div className="w-full relative z-0 my-5 mr-2 pt-1">
                    <input 
                      type="color" 
                      className="block w-full mt-3 h-32 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      defaultValue={element.bgColor} 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].bgColor = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                    <label className="absolute duration-300 top-3 origin-0 text-gray-500 ">{GlobalText(92)}</label>
                  </div>
                  <div className="w-full relative z-0 mr-2 ">
                    <label  className="absolute duration-300 top-3 origin-0 text-gray-500 text-sm">{GlobalText(93)}</label>
                    <label htmlFor={'element' + index}>
                      <div className="block w-full mt-9 h-32 content-center cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 border border-dashed border-gray-300  ">
                        {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              className='h-32 w-auto p-3'
                              src={getImageUrl(element.img_pre)}
                            />
                          </div> : element.img ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              className='h-32 w-auto p-3'
                              src={IMGURL + element.img}
                            />
                          </div> : <div className="flex justify-center items-center">
                            <PhotoIcon className='text-gray-300 h-20 items-center '/>
                          </div> }                        
                        </div> 
                    </label> 
                    <FileInput id={'element' + index} className="hidden" 
                      onChange={(event) => {
                        const slides2 = [...cards];
                        slides2[index].img_pre = event.target.files[0]
                        setcards(slides2)
                      }}
                    />                      
                  </div>  
                  <div className="w-full relative z-0 mr-2 ">
                    <label  className="absolute duration-300 top-3 origin-0 text-gray-500 text-sm">{GlobalText(93)} (2)</label>
                    <label htmlFor={'element_2' + index}>
                      <div className="block w-full mt-9 h-32 content-center cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 border border-dashed border-gray-300  ">
                        {getImageUrl(element.img_pre_2) ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              className='h-32 w-auto p-3'
                              src={getImageUrl(element.img_pre_2)}
                            />
                          </div> : element.img_2 ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              className='h-32 w-auto p-3'
                              src={IMGURL + element.img_2}
                            />
                          </div> : <div className="flex justify-center items-center">
                            <PhotoIcon className='text-gray-300 h-20 items-center '/>
                          </div> }                        
                        </div> 
                    </label> 
                    <FileInput id={'element_2' + index} className="hidden" 
                      onChange={(event) => {
                        const slides2 = [...cards];
                        slides2[index].img_pre_2 = event.target.files[0]
                        setcards(slides2)
                      }}
                    />                      
                  </div>                    
                </div>
                <div className="border rounded-xl px-3 py-2 my-2">
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={20}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitle = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonTitle}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(94)}</label>
                  </div>
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={150}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonUrl = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonUrl}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      defaultValue={element.buttonBg} 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonBg = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                        if(element.buttonTitleBold === 'bolder'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold = 'bolder'
                          setcards(slides2) 
                        }
                      }}
                      className={classNames(
                        (element.buttonTitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleStyle === 'italic'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle = 'italic'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleTransform === 'uppercase'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform = 'none'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform = 'uppercase'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleTransform === 'uppercase') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold rounded-lg '
                      )}>
                      T
                    </button>
                    <input 
                      type="color" 
                      className="ml-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitleColor = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                  </div> 
                </div>
                <div className="border rounded-xl px-3 py-2 my-2">
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={20}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitle2 = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonTitle2}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(94)}</label>
                  </div>
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={150}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonUrl2 = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonUrl2}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>   
                    <button onClick={() => {
                        if(element.buttonTitleBold2 === 'bolder'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold2 = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold2 = 'bolder'
                          setcards(slides2) 
                        }
                      }}
                      className={classNames(
                        (element.buttonTitleBold2 === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleStyle2 === 'italic'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle2 = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle2 = 'italic'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleStyle2 === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleTransform2 === 'uppercase'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform2 = 'none'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform2 = 'uppercase'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleTransform2 === 'uppercase') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold rounded-lg '
                      )}>
                      T
                    </button>
                    <input 
                      type="color" 
                      className="ml-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitleColor2 = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                  </div> 
                </div>
              </div>
                <div className="border rounded-xl px-3 py-2">
                  <div className="flex items-center">
                    <h6 className="text-sm text-gray-500 mr-4 my-3">{GlobalText(100)}</h6>
                    <span className="flex items-center">
                      <button 
                        onClick={() => {   
                          if(element.details.length > 1){
                            const editeData = [...cards];
                            editeData[index].details = editeData[index].details.slice(0, -1);
                            setcards(editeData) 
                          }
                                    
                        }}
                        type="button" className="text-gray-700 mx-1 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                        <MinusIcon className='h-4 w-5'/>
                      </button>
                      <span className="text-sm mx-2 text-center inline-flex items-center"> {element.details.length.toString()}</span>
                      <button 
                          onClick={() => {  
                            if(element.details.length < 4){
                              const newElement = {
                                title: 'Title',
                                subtitle: 'Subtitle',
                                titleColor: '#ffffff',
                                titleBold: 'normal',
                                titleStyle: 'normal',
                                subtitleColor: '#ffffff',
                                subtitleBold: 'normal',
                                subtitleStyle: 'normal',
                              };   
                              const editeData = [...cards];
                              editeData[index].details = [...editeData[index].details, newElement]; 
                              setcards(editeData); 
                            }  
                          }}
                          type="button" className="text-gray-700 mx-1 mr-2 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                          <PlusIcon className='h-4 w-5'/>
                      </button>
                    </span>
                  </div> 
                  {element.details && element.details.map((detail, dindex) => (
                    <div className="border-t py-3">
                      <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                    <div className="w-full relative z-0 my-5 mr-2">
                      <input
                        type="text"   
                        placeholder='' 
                        autoComplete="off"
                        maxLength={20}
                        onChange={(e) => {
                          const editeData = [...cards];
                          editeData[index].details[dindex].title = e.target.value
                          setcards(editeData) 
                        }}
                        defaultValue={detail.title}
                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                      />
                      <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
                    </div>
                    <div className='flex items-center justify-self-end '>       
                      <input 
                        type="color" 
                        className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                        defaultValue={detail.titleColor} 
                        onChange={(e) => {
                          const editeData = [...cards];
                          editeData[index].details[dindex].titleColor = e.target.value
                          setcards(editeData) 
                        }}
                        title="Color"
                      />
                      <button onClick={() => {
                          if(detail.titleBold === 'bolder'){
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleBold = 'normal'
                            setcards(editeData) 
                          }else{
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleBold = 'bolder'
                            setcards(editeData) 
                          }
                        }}
                        className={classNames(
                          (detail.titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                          'px-3 py-1 font-bold rounded-lg'
                        )}>
                      B
                      </button>
                      <button onClick={(e) => {
                          if(detail.titleStyle === 'italic'){
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleStyle = 'normal'
                            setcards(editeData) 
                          }else{
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleStyle = 'italic'
                            setcards(editeData) 
                          }
                          }}
                        className={classNames(
                          (detail.titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                          'ml-2 px-4 py-1 font-bold italic rounded-lg '
                        )}>
                        I
                      </button>
                    </div> 
                      </div> 
                      <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                        <div className="w-full relative z-0 my-5 mr-2">
                          <input
                            type="text"   
                            placeholder='' 
                            autoComplete="off"
                            maxLength={80}
                            onChange={(e) => {
                              const editeData = [...cards];
                              editeData[index].details[dindex].subtitle = e.target.value
                              setcards(editeData) 
                            }}
                            defaultValue={detail.subtitle}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(91)}</label>
                        </div>
                        <div className='flex items-center justify-self-end '>       
                          <input 
                            type="color" 
                            className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                            defaultValue={detail.subtitleColor} 
                            onChange={(e) => {
                              const editeData = [...cards];
                              editeData[index].details[dindex].subtitleColor = e.target.value
                              setcards(editeData) 
                            }}
                            title="Color"
                          />
                          <button onClick={() => {
                              if(detail.subtitleBold === 'bolder'){
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleBold = 'normal'
                                setcards(editeData) 
                              }else{
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleBold = 'bolder'
                                setcards(editeData) 
                              }
                            }}
                            className={classNames(
                              (detail.subtitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                              'px-3 py-1 font-bold rounded-lg'
                            )}>
                          B
                          </button>
                          <button onClick={(e) => {
                              if(detail.subtitleStyle === 'italic'){
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleStyle = 'normal'
                                setcards(editeData) 
                              }else{
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleStyle = 'italic'
                                setcards(editeData) 
                              }
                              }}
                            className={classNames(
                              (detail.subtitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                              'ml-2 px-4 py-1 font-bold italic rounded-lg '
                            )}>
                            I
                          </button>
                        </div> 
                      </div> 
                    </div>
                  ))}
                  
                </div>
                
              </div>              
            </div>
          </div>)})}
        </div>}
        <h1 class="text-3xl font-bold my-4"
            style={{
              color: titleColor,
              fontWeight: titleBold,
              fontStyle: titleStyle,
            }}
          >{title}</h1>
        <div class="hide-scrollbar  flex space-x-1 overflow-auto my-6">
            {showAll === '1' && <button type="button" aria-label={GlobalText(103)}
              className={classNames(
                showCards === 'all' ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-400',
                ' whitespace-nowrap rounded-full py-2.5 px-4 '
              )}
              onClick={() => setshowCards('all')}>
              <p>{GlobalText(103)}</p>
            </button>}
            {cardscat && cardscat.map((element2, cindex) => (
              <button type="button" aria-label={element2.name} 
                className={classNames(
                 showCards === cindex.toString() ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-400',
                  ' whitespace-nowrap rounded-full py-2.5 px-4 '
                )} 
                onClick={() => setshowCards(cindex.toString())}>
                <p>{element2.name}</p>
              </button>
            ))}
            
        </div>
        {cards && cards.map((element, index) => (
          (showCards === 'all' || showCards.toString() === element.cardType.toString()) && <div className="py-2">            
            <article className=" mb-4 flex flex-wrap items-center rounded-3xl px-5 py-8 md:px-0 md:py-5" style={{
              background: element.bgColor,
            }}>
              <div className="mt-4.2 md:mt-0">
                <div className="md:hidden">
                  <div>
                    <h2 className="font-bold text-2xl"
                        style={{
                          color: element.titleColor,
                          fontWeight: element.titleBold,
                          fontStyle: element.titleStyle
                        }}
                      >
                        {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className='flex justify-center items-center'>
                {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center w-80">
                    <img
                      alt=""
                      className='max-w-full lg:max-w-md'
                      style={{width:'250px'}}
                      src={getImageUrl(element.img_pre)}
                    />
                  </div> : element.img ? <div className="flex justify-center items-center w-80">
                        <img
                          alt=""
                          className='max-w-full lg:max-w-md'
                          style={{width:'250px'}}
                          src={IMGURL + element.img}
                        />
                      </div> : <div className="flex justify-center items-center px-10">
                      <PhotoIcon className='text-gray-300 h-64 items-center '/>
                    </div>}
                </div>
              </div>
              <div className="w-full py-4.2 md:ml-7 md:mt-0 md:w-auto md:max-w-[632px] md:py-4">
                <div className="hidden md:block">
                  <div>
                    <h2 className="font-bold text-2xl"
                      style={{
                        color: element.titleColor,
                        fontWeight: element.titleBold,
                        fontStyle: element.titleStyle
                      }}
                    >
                      {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col flex-nowrap md:mt-6 md:flex-row text-white">
                {element.details && element.details.map((detail, dindex) => (
                  <div className="mt-6 flex items-start first:mt-0 md:mr-6 md:mt-0 md:block md:flex-1 md:justify-between md:last:mr-0 ">
                    <span className="min-w-[106px] text-base md:whitespace-nowrap md:text-lg" style={{
                        color: detail.titleColor,
                        fontWeight: detail.titleBold,
                        fontStyle: detail.titleStyle
                      }}>
                      {detail.title}
                    </span>
                    <div className="rich-text-editor bodySmall ml-2 md:mt-1 md:ml-0">
                      <p style={{
                        color: detail.subtitleColor,
                        fontWeight: detail.subtitleBold,
                        fontStyle: detail.subtitleStyle
                      }}>{detail.subtitle}</p>
                    </div>
                  </div>
                ))}
                </div>
                <hr className="my-6 hidden md:block" />
                <div className="mt-10 block md:mt-0 md:flex gap-x-6 flex justify-center items-center md:justify-start">
                  <Link to={element.buttonUrl} className="rounded-lg px-3.5 py-3 "
                      style={{ 
                        backgroundColor: element.buttonBg,
                      }}>
                        <span style={{ 
                        color: element.buttonTitleColor,
                        fontWeight: element.buttonTitleBold,
                        fontStyle: element.buttonTitleStyle,
                        textTransform: element.buttonTitleTransform
                      }}>{element.buttonTitle}</span>
                  </Link>
                  <Link to={element.buttonUrl} className="rounded-lg px-3.5 py-3 ">
                        <span style={{ 
                        color: element.buttonTitleColor2,
                        fontWeight: element.buttonTitleBold2,
                        fontStyle: element.buttonTitleStyle2,
                        textTransform: element.buttonTitleTransform2
                      }}>{element.buttonTitle2}</span>
                  </Link>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>)
    }

    if(component.name === '6x1'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && '6x1'}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)  
                offEdit({
                  title: title,
                  titleColor: titleColor,
                  titleBold: titleBold,
                  titleStyle: titleStyle,
                  items: items
                }, index) 
                console.log(items)                
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        {isModalEdit && <div>
          <div className="relative z-0">
            <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
              <div className="w-full relative z-0 my-5 mr-2">
                <input
                  type="text"   
                  placeholder='' 
                  autoComplete="off"
                  maxLength={90}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                  defaultValue={component.data.title}
                  className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                />
                <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
              </div>
              <div className='flex items-center justify-self-end '>       
                <input 
                  type="color" 
                  className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                  defaultValue={component.data.titleColor} 
                  onChange={(e) => {
                    setTitleColor(e.target.value)
                  }}
                  title="Color"
                />
                <button onClick={() => {
                  if(titleBold === 'bolder'){
                    setTitleBold('normal')
                  }else{
                    setTitleBold('bolder')
                  }
                }}
                  className={classNames(
                    (titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                    'px-3 py-1 font-bold rounded-lg'
                  )}>
                B
                </button>
                <button onClick={(e) => {
                  if(titleStyle === 'italic'){
                    setTitleStyle('normal')                    
                  }else{
                    setTitleStyle('italic')    
                  }
                  }}
                  className={classNames(
                    (titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                    'ml-2 px-4 py-1 font-bold italic rounded-lg '
                  )}>
                  I
                </button>
              </div> 
            </div>   
            <div className="mt-6 grid grid-cols-1 gap-2 sm:grid-cols-2 ">
              {items && items.map((element, index) => ( 
                <div className="col-span-1 border border-dashed border-gray-300 rounded-xl px-3 py-5 flex justify-items-stretch items-center">
                  <div className="mr-4 flex w-full">
                    <div className="w-full ">
                      <label htmlFor={'element' + index}>
                        <div className="block w-full h-32 content-center cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 border border-dashed border-gray-300  ">
                          {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                className='h-32 w-auto p-3'
                                src={getImageUrl(element.img_pre)}
                              />
                            </div> : element.img ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                className='h-32 w-auto p-3'
                                src={IMGURL + element.img}
                              />
                            </div> : <div className="flex justify-center items-center">
                              <PhotoIcon className='text-gray-300 h-20 items-center '/>
                            </div> }                        
                          </div> 
                      </label> 
                      <FileInput id={'element' + index} className="hidden" 
                        onChange={(event) => {
                          const slides2 = [...items];
                          slides2[index].img_pre = event.target.files[0]
                          setitems(slides2)
                        }}
                      />                      
                    </div> 
                  </div>  
                  <div className="w-full">
                    <div className="relative z-0 w-full mb-5">
                      <input maxLength={100}
                          autoComplete='off'
                          type="text"
                          minLength='2'
                          required
                          placeholder=''
                          defaultValue={element.title}
                          onChange={(event) => {
                            const slides2 = [...items];
                            slides2[index].title = event.target.value
                            setitems(slides2)
                          }}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65) + ' ' +  (index + 1)}</label>
                    </div>
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          minLength='2'
                          required
                          placeholder=''
                          defaultValue={element.url}
                          onChange={(event) => {
                            const slides2 = [...items];
                            slides2[index].url = event.target.value
                            setitems(slides2)
                          }}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                    </div> 
                  </div>
                </div>
              ))}              
            </div>            
          </div>
        </div>}
       
        <div className="">
          <h1 class="text-3xl my-4"
              style={{
                color: titleColor,
                fontWeight: titleBold,
                fontStyle: titleStyle,
              }}
            >{title}</h1>
          <section className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-3">
            {items && items.map((element, index) => (  
              <div className="col-span-1">
                <Link className="" to={element.url}>
                  <article
                    className="transition-transform duration-300 will-change-transform hover:scale-105"
                  >
                    <figure
                      className="relative grid overflow-hidden rounded-2xl transition-all duration-300 ease-in-out hover:!bg-[#2267B4] hover:!text-white h-[208px] gap-1 md:h-[214px] md:gap-4.2"
                      style={{ backgroundColor: "#F1F4F9", color: "#222" }}
                    >
                      <figcaption className="text-base leading-5 order-2 mb-4.2 mx-3.5">
                        {element.title}
                      </figcaption>
                      <div className="block w-full content-center">
                          {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              loading="lazy"
                              className='w-auto p-3 self-end justify-self-center'
                              src={getImageUrl(element.img_pre)}
                            />
                          </div> : element.img ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              loading="lazy"
                              decoding="async"
                              className='h-32 w-auto p-3 '
                              src={IMGURL + element.img}
                            />
                          </div> : <div className="flex justify-center items-center">
                            <PhotoIcon className='text-gray-300 h-20 items-center '/>
                          </div> }                        
                        </div> 
                    </figure>
                  </article>
                </Link>
              </div>
            ))}           
            
          </section>
        </div>
      </div>)
    }

    if(component.name === '4x1'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && '4x1'}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
          <h1 class="text-3xl mb-6 font-bold"
              style={{
                color: component.data.titleColor,
                fontWeight: component.data.titleBold,
                fontStyle: component.data.titleStyle,
              }}
            >
              { language === "en" && "We follow these principles" }
          { language === "ru" && "Мы следуем этим принципам" }
          { language === "tg" && "Мо пайрави ин принсипҳоем" }
            </h1>
          <section className="grid grid-cols-2 md:grid-cols-4 gap-5">
              <div className="col-span-1">
                <article className="duration-300 hover:scale-105 border-2 border-gray-200 rounded-2xl" >
                  <div
                    className="h-64  p-3 flex-col justify-center  overflow-hidden  transition-all duration-300 ease-in-out gap-1 md:gap-4"
                  >
                    <div className="w-full flex justify-center"> 
                      <img
                        alt="Icon"
                        loading="lazy"
                        decoding="async"
                        className='h-auto w-46 p-5 '
                        src={img_100}
                      /> 
                    </div>
                    <div className="text-md leading-5 mb-2 mx-3.5 text-center font-bold">
                    { language === "en" && "Responsibility and manners" }
          { language === "ru" && "Ответственность и манеры" }
          { language === "tg" && "Масъулият ва одоб" }
                    </div>
                  </div>
                </article>
              </div> 
              <div className="col-span-1">
                <article className="duration-300 hover:scale-105 border-2 border-gray-200 rounded-2xl" >
                  <div
                    className="h-64 p-3 flex-col justify-center  overflow-hidden transition-all duration-300 ease-in-out gap-1  md:gap-4.2"
                    >
                    <div className="w-full flex justify-center"> 
                      <img
                        alt="Icon"
                        loading="lazy"
                        decoding="async"
                        className='h-auto w-42 p-5 '
                        src={img_101}
                      /> 
                    </div>
                    <div className="text-md leading-5 mb-2 mx-3.5 text-center font-bold">
                    { language === "en" && "Striving to achieve goals" }
          { language === "ru" && "Стремление к достижению целей" }
          { language === "tg" && "Талош барои расидан ба ҳадафҳо" }
                    </div>
                  </div>
                </article>
              </div> 
              <div className="col-span-1">
                <article className="duration-300 hover:scale-105 border-2 border-gray-200 rounded-2xl" >
                  <div
                    className="h-64 p-3 flex-col justify-center  overflow-hidden  transition-all duration-300 ease-in-out  gap-1  md:gap-4.2"
                  >
                    <div className="w-full flex justify-center"> 
                      <img
                        alt="Icon"
                        loading="lazy"
                        decoding="async"
                        className='h-auto w-42 p-5 '
                        src={img_102}
                      /> 
                    </div>
                    <div className="text-md leading-5 mb-2 mx-3.5 text-center font-bold">
                    { language === "en" && "Respect for customers" }
          { language === "ru" && "Уважение к клиентам" }
          { language === "tg" && "Эҳтиром ба мизоҷон" }
                    </div>
                  </div>
                </article>
              </div> 
              <div className="col-span-1">
                <article className="duration-300 hover:scale-105 border-2 border-gray-200 rounded-2xl " >
                  <div
                    className="h-64 p-3 flex-col justify-center  overflow-hidden transition-all duration-300 ease-in-out  gap-1  md:gap-4.2"
                    >
                    <div className="w-full flex justify-center"> 
                      <img
                        alt="Icon"
                        loading="lazy"
                        decoding="async"
                        className='h-auto w-42 p-5 '
                        src={img_103}
                      /> 
                    </div>
                    <div className="text-md leading-5 mb-2 mx-3.5 text-center font-bold">
                    { language === "en" && "Excellent teamwork and professionalism skills" }
          { language === "ru" && "Отличные навыки командной работы и профессионализма" }
          { language === "tg" && "Малакаҳои кори гуруҳӣ ва касбияти олӣ" }
                    </div>
                  </div>
                </article>
              </div> 
          </section>
        </div>
      </div>)
    }

    if(component.name === 'news'){
      return(<div className='relative rounded-md hover:shadow group my-10 '>
        <div className='absolute top-0 right-0 mt-1 mr-1 p-2 opacity-0 group-hover:opacity-100 transition-opacity'>
          <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>
        </div>
       
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(19)}</h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
              {GlobalText(107)}
          </div>
        </div>
      </div>

      </div>)
    }

    if(component.name === 'rate'){
      return(<div className='relative rounded-md hover:shadow group my-10 '>
        <div className='absolute top-0 right-0 mt-1 mr-1 p-2 opacity-0 group-hover:opacity-100 transition-opacity'>
          <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>
        </div>
       
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(88)}</h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
              {GlobalText(110)}
          </div>
        </div>
      </div>

      </div>)
    }

    if(component.name === 'products'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(115)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <span>
            <button 
              onClick={() => {   
                if(cards.length > 1){
                  setcards(cards.slice(0, cards.length - 1)); 
                }
                          
              }}
              type="button" className="text-gray-700 mx-1 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <MinusIcon className='h-4 w-5'/>
          </button>
          <span className="text-sm mx-2 text-center inline-flex items-center">{GlobalText(99)}: {cards.length.toString()}</span>
          <button 
              onClick={() => {  
                if(cards.length < 20){
                  const newCard = {
                    title: 'Card name',
                    subtitle: 'Subtitle',
                    img: '',
                    titleColor: '#ffffff',
                    titleBold: 'normal',
                    titleStyle: 'normal',
                    subtitleColor: '#ffffff',
                    subtitleBold: 'normal',
                    subtitleStyle: 'normal',
                    bgColor: '#000000',
                    buttonTitle: 'Button',
                    buttonBg: '#ffffff',
                    buttonTitleBold: 'normal',
                    buttonTitleStyle: 'normal',
                    buttonTitleColor: '#000000',
                    buttonTitle2: 'Button',
                    buttonTitleBold2: 'normal',
                    buttonTitleStyle2: 'normal',
                    buttonTitleColor2: '#fff',
                    details: [
                      {
                        title: 'Title',
                        subtitle: 'Subtitle',
                        titleColor: '#ffffff',
                        titleBold: 'normal',
                        titleStyle: 'normal',
                        subtitleColor: '#ffffff',
                        subtitleBold: 'normal',
                        subtitleStyle: 'normal',
                      }
                    ]
                  }   
                  setcards([...cards, newCard])   
                }  
              }}
              type="button" className="text-gray-700 mx-1 mr-2 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PlusIcon className='h-4 w-5'/>
          </button>
          </span> }
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)  
                offEdit({
                  title: title,
                  titleColor: titleColor,
                  titleBold: titleBold,
                  titleStyle: titleStyle,
                  showAll: showAll,
                  cards: cards
                }, index)                 
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        {isModalEdit && <div>
          <div className="relative z-0">
          <div className="text-md font-semi-bold sm:text-md items-start flex justify-items-stretch mb-8"> 
            <div className="text-md font-semi-bold w-full mx-2"> 
              <div className="w-full relative z-0 my-5 mr-2">
                <input
                  type="text"   
                  placeholder='' 
                  autoComplete="off"
                  maxLength={90}
                  id={'text_'}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                  defaultValue={component.data.title}
                  className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                />
                <label htmlFor={'text_' } className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
              </div>
              <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      id={'text_color_' + index}
                      defaultValue={titleColor} 
                      onChange={(e) => {
                        setTitleColor(e.target.value)
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                      if(titleBold === 'bolder'){
                        setTitleBold('normal')
                      }else{
                        setTitleBold('bolder')
                      }
                    }}
                      className={classNames(
                        (titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                      if(titleStyle === 'italic'){
                        setTitleStyle('normal')
                      }else{
                        setTitleStyle('italic')
                      }
                      }}
                      className={classNames(
                        (titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
              </div> 
             
            </div>    
          </div>              
          </div>
          {cards && cards.map((element, index) => {   
            return(<div key={'card' + index} className="px-5 py-2 border border-dashed border-gray-300 rounded-xl my-4">
              <div className="relative z-0">
                <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={90}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].title = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.title}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      defaultValue={element.titleColor} 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].titleColor = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                      if(element.titleBold === 'bolder'){
                        const slides2 = [...cards];
                        slides2[index].titleBold = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].titleBold = 'bolder'
                        setcards(slides2) 
                      }
                    }}
                      className={classNames(
                        (element.titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                      if(element.titleStyle === 'italic'){
                        const slides2 = [...cards];
                        slides2[index].titleStyle = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].titleStyle = 'italic'
                        setcards(slides2) 
                      }
                      }}
                      className={classNames(
                        (element.titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                  </div> 
                </div>              
              </div>
              <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                <div className="w-full relative z-0 my-5 mr-2">
                  <input
                    type="text"   
                    placeholder='' 
                    autoComplete="off"
                    maxLength={150}
                    onChange={(e) => {
                      const slides2 = [...cards];
                      slides2[index].subtitle = e.target.value
                      setcards(slides2) 
                    }}
                    defaultValue={element.subtitle}
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                  />
                  <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(91)}</label>
                </div>
                <div className='flex items-center justify-self-end '>       
                  <input 
                    type="color" 
                    className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                    defaultValue={element.subtitleColor} 
                    onChange={(e) => {
                      const slides2 = [...cards];
                      slides2[index].subtitleColor = e.target.value
                      setcards(slides2) 
                    }}
                    title="Color"
                  />
                  <button onClick={() => {
                      if(element.subtitleBold === 'bolder'){
                        const slides2 = [...cards];
                        slides2[index].subtitleBold = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].subtitleBold = 'bolder'
                        setcards(slides2) 
                      }
                    }}
                    className={classNames(
                      (element.subtitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'px-3 py-1 font-bold rounded-lg'
                    )}>
                  B
                  </button>
                  <button onClick={(e) => {
                      if(element.subtitleStyle === 'italic'){
                        const slides2 = [...cards];
                        slides2[index].subtitleStyle = 'normal'
                        setcards(slides2) 
                      }else{
                        const slides2 = [...cards];
                        slides2[index].subtitleStyle = 'italic'
                        setcards(slides2) 
                      }
                      }}
                    className={classNames(
                      (element.subtitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                      'ml-2 px-4 py-1 font-bold italic rounded-lg '
                    )}>
                    I
                  </button>
                </div> 
              </div>              
            </div>
            <div className="relative z-0">
              <div className="text-md font-semi-bold sm:text-md items-start flex justify-items-stretch  grid sm:grid-cols-2 gap-2"> 
              <div>
                <div className="border rounded-xl px-3 py-2 grid grid-cols-2 gap-3 items-start">
                  <div className="w-full relative z-0 my-5 mr-2 pt-1">
                    <input 
                      type="color" 
                      className="block w-full mt-3 h-32 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      defaultValue={element.bgColor} 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].bgColor = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                    <label className="absolute duration-300 top-3 origin-0 text-gray-500 ">{GlobalText(92)}</label>
                  </div>
                  <div className="w-full relative z-0 mr-2 ">
                    <label  className="absolute duration-300 top-3 origin-0 text-gray-500 text-sm">{GlobalText(93)}</label>
                    <label htmlFor={'element' + index}>
                      <div className="block w-full mt-9 h-32 content-center cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 border border-dashed border-gray-300  ">
                        {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              className='h-32 w-auto p-3'
                              src={getImageUrl(element.img_pre)}
                            />
                          </div> : element.img ? <div className="flex justify-center items-center ">
                            <img
                              alt="Icon"
                              className='h-32 w-auto p-3'
                              src={IMGURL + element.img}
                            />
                          </div> : <div className="flex justify-center items-center">
                            <PhotoIcon className='text-gray-300 h-20 items-center '/>
                          </div> }                        
                        </div> 
                    </label> 
                    <FileInput id={'element' + index} className="hidden" 
                      onChange={(event) => {
                        const slides2 = [...cards];
                        slides2[index].img_pre = event.target.files[0]
                        setcards(slides2)
                      }}
                    />                      
                  </div>                    
                </div>
                <div className="border rounded-xl px-3 py-2 my-2">
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={20}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitle = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonTitle}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(94)}</label>
                  </div>
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={150}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonUrl = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonUrl}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>       
                    <input 
                      type="color" 
                      className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      defaultValue={element.buttonBg} 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonBg = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                    <button onClick={() => {
                        if(element.buttonTitleBold === 'bolder'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold = 'bolder'
                          setcards(slides2) 
                        }
                      }}
                      className={classNames(
                        (element.buttonTitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleStyle === 'italic'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle = 'italic'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleTransform === 'uppercase'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform = 'none'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform = 'uppercase'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleTransform === 'uppercase') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold rounded-lg '
                      )}>
                      T
                    </button>
                    <input 
                      type="color" 
                      className="ml-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitleColor = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                  </div> 
                </div>
                <div className="border rounded-xl px-3 py-2 my-2">
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={20}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitle2 = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonTitle2}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(94)}</label>
                  </div>
                  <div className="w-full relative z-0 my-5 mr-2">
                    <input
                      type="text"   
                      placeholder='' 
                      autoComplete="off"
                      maxLength={150}
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonUrl2 = e.target.value
                        setcards(slides2) 
                      }}
                      defaultValue={element.buttonUrl2}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                    <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                  </div>
                  <div className='flex items-center justify-self-end '>   
                    <button onClick={() => {
                        if(element.buttonTitleBold2 === 'bolder'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold2 = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleBold2 = 'bolder'
                          setcards(slides2) 
                        }
                      }}
                      className={classNames(
                        (element.buttonTitleBold2 === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'px-3 py-1 font-bold rounded-lg'
                      )}>
                    B
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleStyle2 === 'italic'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle2 = 'normal'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleStyle2 = 'italic'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleStyle2 === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold italic rounded-lg '
                      )}>
                      I
                    </button>
                    <button onClick={(e) => {
                        if(element.buttonTitleTransform2 === 'uppercase'){
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform2 = 'none'
                          setcards(slides2) 
                        }else{
                          const slides2 = [...cards];
                          slides2[index].buttonTitleTransform2 = 'uppercase'
                          setcards(slides2) 
                        }
                        }}
                      className={classNames(
                        (element.buttonTitleTransform2 === 'uppercase') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                        'ml-2 px-4 py-1 font-bold rounded-lg '
                      )}>
                      T
                    </button>
                    <input 
                      type="color" 
                      className="ml-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                      onChange={(e) => {
                        const slides2 = [...cards];
                        slides2[index].buttonTitleColor2 = e.target.value
                        setcards(slides2) 
                      }}
                      title="Color"
                    />
                  </div> 
                </div>
              </div>
                <div className="border rounded-xl px-3 py-2">
                  <div className="flex items-center">
                    <h6 className="text-sm text-gray-500 mr-4 my-3">{GlobalText(100)}</h6>
                    <span className="flex items-center">
                      <button 
                        onClick={() => {   
                          if(element.details.length > 1){
                            const editeData = [...cards];
                            editeData[index].details = editeData[index].details.slice(0, -1);
                            setcards(editeData) 
                          }
                                    
                        }}
                        type="button" className="text-gray-700 mx-1 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                        <MinusIcon className='h-4 w-5'/>
                      </button>
                      <span className="text-sm mx-2 text-center inline-flex items-center"> {element.details.length.toString()}</span>
                      <button 
                          onClick={() => {  
                            if(element.details.length < 4){
                              const newElement = {
                                title: 'Title',
                                subtitle: 'Subtitle',
                                titleColor: '#ffffff',
                                titleBold: 'normal',
                                titleStyle: 'normal',
                                subtitleColor: '#ffffff',
                                subtitleBold: 'normal',
                                subtitleStyle: 'normal',
                              };   
                              const editeData = [...cards];
                              editeData[index].details = [...editeData[index].details, newElement]; 
                              setcards(editeData); 
                            }  
                          }}
                          type="button" className="text-gray-700 mx-1 mr-2 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                          <PlusIcon className='h-4 w-5'/>
                      </button>
                    </span>
                  </div> 
                  {element.details && element.details.map((detail, dindex) => (
                    <div className="border-t py-3">
                      <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                    <div className="w-full relative z-0 my-5 mr-2">
                      <input
                        type="text"   
                        placeholder='' 
                        autoComplete="off"
                        maxLength={20}
                        onChange={(e) => {
                          const editeData = [...cards];
                          editeData[index].details[dindex].title = e.target.value
                          setcards(editeData) 
                        }}
                        defaultValue={detail.title}
                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                      />
                      <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(65)}</label>
                    </div>
                    <div className='flex items-center justify-self-end '>       
                      <input 
                        type="color" 
                        className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                        defaultValue={detail.titleColor} 
                        onChange={(e) => {
                          const editeData = [...cards];
                          editeData[index].details[dindex].titleColor = e.target.value
                          setcards(editeData) 
                        }}
                        title="Color"
                      />
                      <button onClick={() => {
                          if(detail.titleBold === 'bolder'){
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleBold = 'normal'
                            setcards(editeData) 
                          }else{
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleBold = 'bolder'
                            setcards(editeData) 
                          }
                        }}
                        className={classNames(
                          (detail.titleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                          'px-3 py-1 font-bold rounded-lg'
                        )}>
                      B
                      </button>
                      <button onClick={(e) => {
                          if(detail.titleStyle === 'italic'){
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleStyle = 'normal'
                            setcards(editeData) 
                          }else{
                            const editeData = [...cards];
                            editeData[index].details[dindex].titleStyle = 'italic'
                            setcards(editeData) 
                          }
                          }}
                        className={classNames(
                          (detail.titleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                          'ml-2 px-4 py-1 font-bold italic rounded-lg '
                        )}>
                        I
                      </button>
                    </div> 
                      </div> 
                      <div className="text-md font-semi-bold sm:text-md items-center flex justify-items-stretch "> 
                        <div className="w-full relative z-0 my-5 mr-2">
                          <input
                            type="text"   
                            placeholder='' 
                            autoComplete="off"
                            maxLength={80}
                            onChange={(e) => {
                              const editeData = [...cards];
                              editeData[index].details[dindex].subtitle = e.target.value
                              setcards(editeData) 
                            }}
                            defaultValue={detail.subtitle}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label  className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(91)}</label>
                        </div>
                        <div className='flex items-center justify-self-end '>       
                          <input 
                            type="color" 
                            className="mr-2 h-9 w-14 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700" 
                            defaultValue={detail.subtitleColor} 
                            onChange={(e) => {
                              const editeData = [...cards];
                              editeData[index].details[dindex].subtitleColor = e.target.value
                              setcards(editeData) 
                            }}
                            title="Color"
                          />
                          <button onClick={() => {
                              if(detail.subtitleBold === 'bolder'){
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleBold = 'normal'
                                setcards(editeData) 
                              }else{
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleBold = 'bolder'
                                setcards(editeData) 
                              }
                            }}
                            className={classNames(
                              (detail.subtitleBold === 'bolder') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                              'px-3 py-1 font-bold rounded-lg'
                            )}>
                          B
                          </button>
                          <button onClick={(e) => {
                              if(detail.subtitleStyle === 'italic'){
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleStyle = 'normal'
                                setcards(editeData) 
                              }else{
                                const editeData = [...cards];
                                editeData[index].details[dindex].subtitleStyle = 'italic'
                                setcards(editeData) 
                              }
                              }}
                            className={classNames(
                              (detail.subtitleStyle === 'italic') ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
                              'ml-2 px-4 py-1 font-bold italic rounded-lg '
                            )}>
                            I
                          </button>
                        </div> 
                      </div> 
                    </div>
                  ))}
                  
                </div>
                
              </div>              
            </div>
          </div>)})}
        </div>}
        <h1 class="text-3xl font-bold my-4"
            style={{
              color: titleColor,
              fontWeight: titleBold,
              fontStyle: titleStyle,
            }}
          >{title}</h1>
        {cards && cards.map((element, index) => (
           <div className="py-2">            
            <article className="mb-4 flex flex-wrap items-center rounded-3xl px-5 py-8 md:px-0 md:py-10" style={{
              background: element.bgColor,
            }}>
              <div className="mt-4.2 md:mt-0">
                <div className="md:hidden">
                  <div>
                    <h2 className="font-bold text-2xl"
                        style={{
                          color: element.titleColor,
                          fontWeight: element.titleBold,
                          fontStyle: element.titleStyle
                        }}
                      >
                        {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className='flex justify-center items-center'>
                {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center w-72">
                    <img
                      alt=""
                      className='max-w-full lg:max-w-md'
                      style={{width:'200px'}}
                      src={getImageUrl(element.img_pre)}
                    />
                  </div> : element.img ? <div className="flex justify-center items-center w-72">
                        <img
                          alt=""
                          className='max-w-full lg:max-w-md'
                          style={{width:'200px'}}
                          src={IMGURL + element.img}
                        />
                      </div> : <div className="flex justify-center items-center px-10">
                      <PhotoIcon className='text-gray-300 h-64 items-center '/>
                    </div>}
                </div>
              </div>
              <div className="w-full py-4.2 md:ml-7 md:mt-0 md:w-auto md:max-w-[632px] md:py-4">
                <div className="hidden md:block">
                  <div>
                    <h2 className="font-bold text-2xl"
                      style={{
                        color: element.titleColor,
                        fontWeight: element.titleBold,
                        fontStyle: element.titleStyle
                      }}
                    >
                      {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col flex-nowrap md:mt-6 md:flex-row text-white md:gap-x-8">
                {element.details && element.details.map((detail, dindex) => (
                  <div className="mt-6 flex items-center first:mt-0 md:mr-6 md:mt-0 md:block md:flex-1 md:justify-between md:last:mr-0 ">
                    <span className="min-w-[106px] text-base md:whitespace-nowrap md:text-md" style={{
                        color: detail.titleColor,
                        fontWeight: detail.titleBold,
                        fontStyle: detail.titleStyle
                      }}>
                      {detail.title}
                    </span>
                    <div className="rich-text-editor md:whitespace-nowrap text-lg ml-2 md:mt-1 md:ml-0 text-lg">
                      <p style={{
                        color: detail.subtitleColor,
                        fontWeight: detail.subtitleBold,
                        fontStyle: detail.subtitleStyle
                      }}>{detail.subtitle}</p>
                    </div>
                  </div>
                ))}
                </div>
                <div className="my-10 hidden md:block" ></div> 
                <div className="mt-10 block md:mt-0 md:flex gap-x-6 flex justify-center items-center md:justify-start">
                  <Link to={element.buttonUrl} className="rounded-lg px-3.5 py-3 "
                      style={{ 
                        backgroundColor: element.buttonBg,
                      }}>
                        <span style={{ 
                        color: element.buttonTitleColor,
                        fontWeight: element.buttonTitleBold,
                        fontStyle: element.buttonTitleStyle,
                        textTransform: element.buttonTitleTransform
                      }}>{element.buttonTitle}</span>
                  </Link>
                  <Link to={element.buttonUrl} className="rounded-lg px-3.5 py-3 ">
                        <span style={{ 
                        color: element.buttonTitleColor2,
                        fontWeight: element.buttonTitleBold2,
                        fontStyle: element.buttonTitleStyle2,
                        textTransform: element.buttonTitleTransform2
                      }}>{element.buttonTitle2}</span>
                  </Link>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>)
    }

    if(component.name === '3block'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div className="">
          <h2 className="font-bold text-2xl pb-4">
          { language === "en" && "What are the advantages of acquiring in our bank?" }
          { language === "ru" && "В чем преимущества эквайринга в нашем банке?" }
          { language === "tg" && "Бартариятҳои эквайринг аз бонки мо чист?" }
          </h2>
          <section className="my-5 ">
            <section className="grid gap-4 md:grid-cols-3">
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">
                      { language === "en" && "Very simple" }
                      { language === "ru" && "Очень просто" }
                      { language === "tg" && "Ниҳоят осон" }
                      </h2>
                      <div className="rich-text-editor text-lg">
                        <p>
                        { language === "en" && "Manage your entire business with ease" }
                      { language === "ru" && "Управляйте всем бизнесом очень легко" }
                      { language === "tg" && "Тамоми тиҷоратро хеле осон идора кунед" }
                        </p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="300px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_1}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">
                      { language === "en" && "Instant payment" }
                      { language === "ru" && "Мгновенная оплата" }
                      { language === "tg" && "Пардохти фаврӣ" }
                      </h2>
                      <div className="rich-text-editor text-lg">
                        <p>
                        { language === "en" && "The payment will be credited to your account in no time" }
                      { language === "ru" && "Платеж поступит на ваш счет в мгновение ока" }
                      { language === "tg" && "Пардохт дар як мижжа задан ба суратҳисобатон мерасад" }
                        </p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="200px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_2}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">
                        { language === "en" && "Online" }
                      { language === "ru" && "Онлайн" }
                      { language === "tg" && "Онлайн" }</h2>
                      <div className="rich-text-editor text-lg">
                        <p>
                        { language === "en" && "Control your business online 24/7" }
                      { language === "ru" && "Контролируйте свой бизнес онлайн 24/7" }
                      { language === "tg" && "Тиҷорататонро ба таври онлайн шабонарӯзӣ назорат кунед" }
                        </p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="300px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_3}
                      />
                    </div>
                  </figure>
                </article>
              </a>
            </section>
          </section>
        </div>
      </div>)
    }

    if(component.name === 'sender'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(116)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div
            className="overflow-hidden rounded-2xl py-6 px-4 md:rounded-3xl md:px-10 md:py-14"
            style={{ color: "rgb(255, 255, 255)", background: "#1C2720" }}
          >
            <div className="flex flex-wrap justify-between items-center">
              <div className="text-center md:w-[360px] md:text-left mb-10">
                <h2 className="text-2xl mb-3 text-white font-bold">
                { language === "en" && "To plug" }
                      { language === "ru" && "Подключить" }
                      { language === "tg" && "Пайвастшавӣ" }
                </h2>
                <p className="text-md mb-4.2 text-white">
                { language === "en" && "To get in touch or for more information, please enter your name and phone number. Our staff will contact you as soon as possible." }
                { language === "ru" && "Для связи или получения дополнительной информации введите свое имя и номер телефона. Наши сотрудники свяжутся с вами как можно скорее." }
                { language === "tg" && "Барои пайваст шудан ё гирифтани маълумоти бештар лутфан ному насаб ва рақами телефонатонро нависед. Кормандони мо дар наздиктарин фурсат бо шумо тамос мегиранд." }
             
                </p>
              </div>
              <form
                className="w-full md:w-[unset]"
                aria-labelledby="form-description"
                onSubmit={null}
              >
                <div className="mb-5 md:w-[456px]">
                  <div>
                    <label htmlFor="full-name">
                      <div className="text-small md:bodyNormal text-white pb-2">
                      { language === "en" && "First and last name" }
                      { language === "ru" && "Имя и фамилия" }
                      { language === "tg" && "Ному насаб" }
                      </div>
                    </label>
                    <input
                      type="text"
                      id="full-name"
                      name="fullName"
                      autoComplete="off"
                      placeholder={ language === "en" ? "Your first and last name" : language === "ru" ? "Ваше имя и фамилия" : "Ному насаби шумо" }
                      minLength={2}
                      maxLength={90}
                      className="mt-0.5 w-full rounded-lg border-none p-0 px-3.5 py-3 text-lg leading-7 text-black placeholder-darkGrey outline-none focus:ring-0 md:py-4"
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className="mb-5 md:w-[456px]">
                  <div>
                    <label htmlFor="phone-number">
                      <div className="text-small md:bodyNormal text-white pb-2">
                      { language === "en" ? "Phone number" : language === "ru" ? "Номер телефона" : "Рақами телефон" }
                      </div>
                    </label>
                    <input
                      type="text"
                      id="phone-number"
                      name="phone"
                      autoComplete="off"
                      placeholder={ language === "en" ? "Your phone number" : language === "ru" ? "Ваш номер телефона" : "Рақами телефони шумо" }
                      maxLength={9}
                      className="mt-0.5 w-full rounded-lg border-none p-0 px-3.5 py-3 text-lg leading-7 text-black placeholder-darkGrey outline-none focus:ring-0 md:py-4"
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className="text-center md:text-left">
                  <div className="flex flex-wrap items-center mt-5 mb-3">
                    <button
                      type="button"
                      className="group grid grid-flow-col items-center justify-center outline-none duration-150 py-3 px-5 font-bold text-lg leading-5.8 gap-2 bg-blue-600 text-white hover:bg-blue-700  w-full md:w-auto rounded-lg"
                    >
                      { language === "en" ? "Send" : language === "ru" ? "Отправить" : "Равон кардан" }
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>)
    }

    if(component.name === 'sender2'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(116)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div
            className="overflow-hidden rounded-2xl py-6 px-4 md:rounded-3xl md:px-10 md:py-14"
            style={{ color: "rgb(255, 255, 255)", background: "#1C2720" }}
          >
            <div className="flex flex-wrap justify-between items-center">
              <div className="text-center md:w-[360px] md:text-left mb-10">
                <h2 className="text-2xl mb-3 text-white font-bold">Гирифтани қарз</h2>
                <p className="text-md mb-4.2 text-white">
                  Барои гирифтани қарз ё  маълумоти бештар лутфан ному насаб ва рақами телефонатонро нависед. Кормандони мо дар наздиктарин фурсат бо шумо тамос мегиранд
                </p>
              </div>
              <form
                className="w-full md:w-[unset]"
                aria-labelledby="form-description"
                onSubmit={null}
              >
                <div className="mb-5 md:w-[456px]">
                  <div>
                    <label htmlFor="full-name">
                      <div className="text-small md:bodyNormal text-white pb-2">
                        Ному насаб
                      </div>
                    </label>
                    <input
                      type="text"
                      id="full-name"
                      name="fullName"
                      autoComplete="off"
                      placeholder="Ному насаби шумо"
                      minLength={2}
                      maxLength={90}
                      className="mt-0.5 w-full rounded-lg border-none p-0 px-3.5 py-3 text-lg leading-7 text-black placeholder-darkGrey outline-none focus:ring-0 md:py-4"
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className="mb-5 md:w-[456px]">
                  <div>
                    <label htmlFor="phone-number">
                      <div className="text-small md:bodyNormal text-white pb-2">
                        Рақами телефон
                      </div>
                    </label>
                    <input
                      type="text"
                      id="phone-number"
                      name="phone"
                      autoComplete="off"
                      placeholder="Рақами телефони шумо"
                      maxLength={9}
                      className="mt-0.5 w-full rounded-lg border-none p-0 px-3.5 py-3 text-lg leading-7 text-black placeholder-darkGrey outline-none focus:ring-0 md:py-4"
                      defaultValue=""
                    />
                  </div>
                </div>
                <div className="text-center md:text-left">
                  <div className="flex flex-wrap items-center mt-5 mb-3">
                    <button
                      type="button"
                      className="group grid grid-flow-col items-center justify-center outline-none duration-150 py-3 px-5 font-bold text-lg leading-5.8 gap-2 bg-blue-600 text-white hover:bg-blue-700  w-full md:w-auto rounded-lg"
                      aria-label="Равон кардан"
                    >
                      Равон кардан
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>)
    }

    if(component.name === '3block2'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div className="">
          <h2 className="font-bold text-2xl pb-4">
            Бартариятҳои лоиҳаи музди меҳнат
          </h2>
          <section className="my-5 ">
            <section className="grid gap-4 md:grid-cols-3">
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Бефоиз</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Аз банкоматҳои БРТ кормандонатон бефоиз маошашонро дастрас мекунанд</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="200px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_8}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Паёмак - ройгон</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Ройгон аз интиқоли маблағ смс-паёмак дастрас кунед</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="250px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_9}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Онлайн</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Маошро ба таври онлайн сари вақт гузаронед</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="250px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_10}
                      />
                    </div>
                  </figure>
                </article>
              </a>
            </section>
          </section>
        </div>
      </div>)
    }

    if(component.name === '3block3'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div className="">
          <h2 className="font-bold text-2xl pb-4">
          Бартариятҳои суратҳисоб дар бонки мо
          </h2>
          <section className="my-5 ">
            <section className="grid gap-4 md:grid-cols-3">
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Пур кардан ва гирифтани пул</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Дилхоҳ вақт ҳисобатонро пур кунед ё пулро нақд гиред</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="250px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_11}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Мубодилаи асъор</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Хеле осон пулҳоятонро бо асъори гуногун мубодила кунед</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="270px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_12}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Як суратҳисоб - 5 асъор</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Пулро бо сомонӣ, рубл, доллар, юан ва евро фиристед</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="270px"
                        height="auto"
                        decoding="async"
                        className="mx-auto mb-5"
                        style={{ color: "transparent" }}
                        src={img_13}
                      />
                    </div>
                  </figure>
                </article>
              </a>
            </section>
          </section>
        </div>
      </div>)
    }

    if(component.name === '3block4'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div className="">
          <h2 className="font-bold text-2xl pb-4">
          Бартариятҳои қарзи тиҷоратӣ аз бонки мо
          </h2>
          <section className="my-5 ">
            <section className="grid gap-4 md:grid-cols-3">
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Бо гарави амволи корхона</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Пешниҳоди қарз баъди ҳисоби молҳои коргоҳ барои рушди тиҷорат</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="250px"
                        height="auto"
                        decoding="async"
                        className="mx-auto mb-3"
                        style={{ color: "transparent" }}
                        src={img_14}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Фоизҳои паст</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Барои рушди тиҷорат бо 20% солона қарз гиред</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="270px"
                        height="auto"
                        decoding="async"
                        className="mx-auto"
                        style={{ color: "transparent" }}
                        src={img_15}
                      />
                    </div>
                  </figure>
                </article>
              </a>
              <a className="" href="#">
                <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                  <figure
                    className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                    style={{ backgroundColor: "#F4F6FB", color: "#222222" }}
                  >
                    <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                      <h2 className="font-bold text-xl mb-1 md:mb-3">Интихоби тартиби пардохт</h2>
                      <div className="rich-text-editor text-lg">
                        <p>Пардохти қарзро вобаста ба имконот интихоб кунед</p>
                      </div>
                    </header>
                    <div className="self-end mb-5 mx-5 items-center">
                      <img
                        loading="lazy"
                        width="270px"
                        height="auto"
                        decoding="async"
                        className="mx-auto mb-2"
                        style={{ color: "transparent" }}
                        src={img_16}
                      />
                    </div>
                  </figure>
                </article>
              </a>
            </section>
          </section>
        </div>
      </div>)
    }

    if(component.name === '3block5'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="mx-auto max-w-6xl my-10 px-8 ">
          <h2 class="font-bold text-2xl pb-4">Ҷараёни гирифтани қарз</h2>
          <div class="mb-5 grid gap-4.2 md:mb-6 md:grid-cols-3">
            <div class="flex justify-center md:flex-col md:justify-start md:px-5 my-4 md:pb-7 md:pt-4.2 ">
              <div class="font-bold text-[72px] leading-[92px] text-blue-600 md:text-[96px] md:leading-[123px] mr-12">1</div>
              <div class="max-w-full md:mt-3 md:max-w-[296px]">
                <div class="text-xl font-bold mb-3">Пешниҳоди ҳуҷҷатҳои зарурӣ</div>
                <div class="text-base leading-5">Ҳуҷҷатҳои заруриро барои гирифтани қарз пешниҳод мекунед</div>
              </div>
            </div>
            <div class="flex justify-start md:flex-col md:justify-start md:px-5 my-4 md:pb-7 md:pt-4.2 ">
              <div class="font-bold text-[72px] leading-[92px] text-blue-600 md:text-[96px] md:leading-[123px] mr-12">2</div>
              <div class="max-w-full md:mt-3 md:max-w-[296px]">
                <div class="text-xl font-bold mb-3">Интизори қарори коршиноси қарзӣ</div>
                <div class="text-base leading-5">Коршиноси қарзӣ аз натиҷаи қарор ба шумо хабар мерасонад</div>
              </div>
            </div>
            <div class="flex justify-start md:flex-col md:justify-start md:px-5 my-4 md:pb-7 md:pt-4.2 ">
              <div class="font-bold text-[72px] leading-[92px] text-blue-600 md:text-[96px] md:leading-[123px] mr-12">3</div>
              <div class="max-w-full md:mt-3 md:max-w-[296px]">
                <div class="text-xl font-bold mb-3">Гирифтани қарз</div>
                <div class="text-base leading-5">Шартномаро имзо гузошта қарзро гирифта метавонед</div>
              </div>
            </div>
          </div>
      </div>
      </div>)
    }

    if(component.name === 'slideshow'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(117)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <>
          <Swiper 
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            speed={500}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,          
            }}
            navigation={false}
            pagination={{
              clickable: true
            }}
            
            modules={[Autoplay, EffectFade, Pagination, Navigation]}
            className="swiper max-w-full "
            effect={'fade'}
          >
            <SwiperSlide className="w-full">
              <img 
                className="h-78 w-full object-cover rounded-xl md:rounded-3xl"
                src={img_18} 
              />
            </SwiperSlide>
            <SwiperSlide className="w-full">
              <img 
                className="h-78 w-full object-cover rounded-xl md:rounded-3xl"
                src={img_17} 
              />
            </SwiperSlide>
          </Swiper>
        </>
        </div>
      </div>)
    }

    if(component.name === 'blockimg'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(118)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className="flex flex-wrap md:flex-nowrap items-center justify-between py-8">          
          <div className="order-1 ">
            <div className="text-xl md:text-2xl mb-3 text-gray-800 md:mb-5 font-bold">Дар бораи бонк</div>
            <div className="text-base leading-5">
              <div className="rich-text-editor leading-5 text-lg text-gray-700">
                <p>
                Рушд ва талошҳои ҶСП "Бонки рушди Тоҷикистон" дар бозори хизматрасонии бонкӣ бо мақоми нави бонк муайян карда мешавад - аз моҳи июли соли 2020, бонк бо тарки сохтори ҶСК "Тоҷиксодиротбонк" мустақил шуд. Хатти асосии рушди ҶСП «Бонки Рушди Тоҷикистон» доштани рақобатпазирии баланд дар шароити муносибатҳои бозорӣ, ташкили шабакаи филиалӣ ва шабакаи МХБ дар ноҳияҳои дурдасти кишварамон, ҷорӣ намудани тиҷорати кортҳои пардохтии бонкӣ, афзоиши сармоя ва дороиҳои бонк, сохтани сандуқи баландсифати қарзӣ, ба боварии муштариён сазовор шудан ва заминаи амонатро зиёд кардан аст.
                </p>
              </div>
            </div>
          </div>
          <img
            alt="Frame 181 (1).png"
            loading="lazy"
            width={250}
            height={'auto'}
            decoding="async"
            data-nimg={1}
            className="order-2 mx-auto max-w-[178px] md:mx-[unset] md:max-w-[unset] ml-0 md:ml-10"
            src={img_20}
            style={{ color: "transparent" }}
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap items-center justify-between py-8">          
          <div className="order-1 md:order-2 ml-0 md:ml-10">
            <div className="text-xl md:text-2xl mb-3 text-gray-800 md:mb-5 font-bold">Вазифаҳои афзалиятноки бонк</div>
            <div className="text-base leading-5">
              <div className="rich-text-editor leading-5 text-lg text-gray-700">
                <p>
                Афзоиши дороиҳо ва сармоя бо усули замонавии ташкили маҳсулот ва хидматҳо, инчунин рушди ҳамкориҳои мутақобилан судманд бо ташкилотҳои молиявии байналмилалӣ бо мақсади ҷалби сармояи хориҷӣ. Рушди фарҳанги корпоративӣ, тахассуси баланди кормандон ва мавҷудияти шароит барои афзоиши касбии онҳо, тайёр кардани кадрҳои касбӣ дар тамоми соҳаҳои бонкдорӣ. Сиёсати афзалиятноки қарздиҳи ба бахши воқеии иқтисодиёт ва ба даст овардани ҳиссаи қарзҳои хурд то 70% сандуқи умумии қарзӣ бетағйир боқӣ мемонад. Баланд бардоштани сифати хизматрасонӣ ба мизоҷон ва ба онҳо пешниҳод кардани маҷмӯи пурраи маҳсулот ва хизматрасониҳои инноватсионии бонкӣ. Ҷорӣ намудани хатҳои муосири тиҷорат ва кортҳои пардохтии бонкӣ.
                </p>
              </div>
            </div>
          </div>
          <img
            alt="Frame 181 (1).png"
            loading="lazy"
            width={250}
            height={'auto'}
            decoding="async"
            data-nimg={1}
            className="order-2 mx-auto max-w-[178px] md:order-1 md:mx-[unset] md:max-w-[unset]"
            src={img_19}
            style={{ color: "transparent" }}
          />
        </div>  
        <div className="flex flex-wrap md:flex-nowrap items-center justify-between py-8">          
          <div className="order-1 ">
            <div className="text-xl md:text-2xl mb-3 text-gray-800 md:mb-5 font-bold">Рисолати бонк</div>
            <div className="text-base leading-5">
              <div className="rich-text-editor leading-5 text-lg text-gray-700">
                <p>
                  Вазифаи бонк яке аз беҳтарин бонкҳои ҷумҳурӣ шудан аст. Мизоҷони мо, эҳтиёҷоти онҳо ва некӯаҳволии молиявии онҳо - асоси фаъолияти бонк мебошанд. Этикаи корпоративии менеҷмент тавассути як гурӯҳи ҳамфикрон ба даст оварда мешавад, ки онҳоро фарҳанги умумии арзишҳо муттаҳид мекунад. Вазифаи бонк маҷмӯи принсипҳо ва арзишҳо барои ноил шудан ба ҳадафҳои гузошташуда мебошад. 
                </p>
              </div>
            </div>
          </div>
          <img
            alt="Frame 181 (1).png"
            loading="lazy"
            width={250}
            height={'auto'}
            decoding="async"
            data-nimg={1}
            className="order-2 mx-auto max-w-[178px] md:mx-[unset] md:max-w-[unset] ml-0 md:ml-10"
            src={img_21}
            style={{ color: "transparent" }}
          />
        </div>  
        </div>
      </div>)
    }

    if(component.name === 'pdf'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(118)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
          ---
        </div>
      </div>)
    }

    if(component.name === 'adress'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(118)}
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(146)}</h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(146)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'documents'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
        {isModalEdit && GlobalText(147) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(147)}</h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
            {GlobalText(147)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'documents2'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(147) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(147)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
            {GlobalText(147)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'vacancies'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(148) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(148)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
            {GlobalText(148)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === '3block6'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(86)} (6)</h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(86)} (6)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === '3block7'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(86)} (7)</h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(86)} (7)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === '3block8'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(86) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(86)} (8)</h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(86)} (8)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'tarifs'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(149) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(149)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(149)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'sender3'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(116) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(116)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(116)} (3)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'sender4'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(116) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(116)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(116)} (4)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'sender5'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(116) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(116)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(116)} (5)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'faq'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(150) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(150)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(150)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'faq2'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(150) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(150)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(150)} (2)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'faq3'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(150) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(150)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(150)} (3)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'faq4'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(150) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(150)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(150)} (4)
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'credit_cal'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(129) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(129)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(129)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

    if(component.name === 'deposit_cal'){
      return(<div className={`relative rounded-md hover:shadow group my-10  ${
        isModalEdit ? 'border border-dashed border-gray-300 p-3 shadow ' : 'hover:pt-12'
      }`}>
        <div className='text-xl text-gray-900 sm:text-2xl sm:tracking-tight'>
          {isModalEdit && GlobalText(128) } 
        </div>
        <div className={`absolute top-0 right-0 mt-1 mr-1 p-2  group-hover:opacity-100 transition-opacity ${
            isModalEdit ? 'opacity-100' : 'opacity-0'
          }`}>
          {!isModalEdit && <button 
              onClick={onDelete}
              type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <TrashIcon className='h-4 w-5'/>
          </button>}
          {!isModalEdit && <button 
              onClick={() => {    
                setIsModaEdit(true)
                onEdit()
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PencilIcon className='h-4 w-5'/>
          </button>}
          {isModalEdit && <button 
              onClick={() => { 
                setIsModaEdit(false)                  
              }}
              type="button" className="text-gray-700 mx-1 border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <CheckIcon className='h-4 w-5'/>
          </button>}
        </div>
        <div class="">
        <div className='bg-blue-50 px-5'>
        <div className='mx-auto max-w-6xl pt-2 px-6 xl:px-0 my-10 pb-12 '>
          <div className="container flex w-full justify-between text-titleColor items-center my-5">
            <h2 className="font-bold text-2xl ">{GlobalText(128)} </h2>
          </div>
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events">
          {GlobalText(128)}
          </div>
        </div>
      </div>

        </div>
      </div>)
    }

  }  

  export default ReturnComponent