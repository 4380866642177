import { PhotoIcon } from '@heroicons/react/24/outline'
import GlobalText from './globalText'
import { FileInput, Label } from "flowbite-react";
import { useState, useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom";
import axios from 'axios';
import GlobalData from './globalData';
import Cookies from 'js-cookie';
import { Toaster, toast } from 'sonner'
import { PencilIcon, TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  

  function Settings() {
    const APIURL = GlobalData('apiUrl')
    const IMGURL = GlobalData('imgUrl')
    const succesText = GlobalText(38)
    const errorText = GlobalText(39)
    const Cookie = Cookies.get('token')
    const location = useLocation();
    const isCurrentlyEn = location.pathname.startsWith('/en');
    const isCurrentlyRu = location.pathname.startsWith('/ru');
    const language = isCurrentlyEn ? 'en' : isCurrentlyRu ? 'ru' : 'tg'
    const [langP, setLangP] = useState(language)
    const [siteLogoTJ, setSiteLogoTJ] = useState(null);
    const [selectedLogoTJ, setSelectedLogoTJ] = useState(null);
    const [siteLogoRU, setSiteLogoRU] = useState(null);
    const [selectedLogoRU, setSelectedLogoRU] = useState(null);
    const [siteLogoEN, setSiteLogoEN] = useState(null);
    const [selectedLogoEN, setSelectedLogoEN] = useState(null);
    const [siteIcon, setSiteIcon] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [loading, setLoading] = useState(true);
    const [siteNameTJ, setSiteNameTJ] = useState('');
    const [siteNameRU, setSiteNameRU] = useState('');
    const [siteNameEN, setSiteNameEN] = useState('');
    const [keywordsTJ, setKeywordsTJ] = useState('');
    const [keywordsRU, setKeywordsRU] = useState('');
    const [keywordsEN, setKeywordsEN] = useState('');
    const [sendloading, setsendloading] = useState(false);    
    const [showModal, setShowModal] = useState(false);
    const [showModalEd, setShowModalEd] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [footerData, setFooterData] = useState({});
    const dragItem = useRef(0);
    const dragItemParent = useRef(0);
    const dragItemType = useRef('menu');
    const dragColNum = useRef(0);
    const dragColOverIndex = useRef(0);
    const dragColIndex = useRef(0);
    const dragOverItem = useRef(0);
    const addToItem = useRef(false);
    const [addCol, setAddCol] = useState(false);
    const [col1, setCol1] = useState([]);
    const [col2, setCol2] = useState([]);
    const [col3, setCol3] = useState([]);
    const [edcol, setEdCol] = useState([]);
    const [edcolIndex, setEdColIndex] = useState();
    const addToCol = useRef(0);
    const addItem = useRef(0);
    const [edMenu, setedMenu] = useState({})
    const [editeCol, setEditeCol] = useState(false);
    const logoutText = GlobalText(80);

    const removeMenuItem = (indexMenu, indexChild) => {
      if (window.confirm(logoutText)) {
        const updatedMenuData = [...menuData];
      
        if (indexChild === undefined) {
          updatedMenuData.splice(indexMenu, 1);
        } else {
          updatedMenuData[indexMenu].children.splice(indexChild, 1);
        }
      
        setMenuData(updatedMenuData);
      }
    };
    
    
    function preedite (indexMenu, indexChild) {
      if (indexChild === undefined) {
        setedMenu({
          'indexMenu' : indexMenu,
          'indexChild' : undefined,
        })
      } else {
        setedMenu({
          'indexMenu' : indexMenu,
          'indexChild' : indexChild,
        })
      }
      setShowModalEd(true)
    };
    function loadingData(){
      
      axios.get(APIURL + 'global')
      .then((response) => {
          setSiteIcon(response.data['icon'])
          setSiteLogoTJ(response.data['logo_tg'])
          setSiteLogoRU(response.data['logo_ru'])
          setSiteLogoEN(response.data['logo_en'])
          setSiteNameTJ(response.data['site_name_tg'])
          setSiteNameRU(response.data['site_name_ru'])
          setSiteNameEN(response.data['site_name_en'])
          setKeywordsTJ(response.data['keywords_tg'])
          setKeywordsRU(response.data['keywords_ru'])
          setKeywordsEN(response.data['keywords_en'])
          if(response.data['menu']){
            setMenuData(JSON.parse(response.data['menu']));
          }  
          if(response.data['footer']){
            setFooterData(JSON.parse(response.data['footer']));
            setCol1(JSON.parse(response.data['footer']).col_1)
            setCol2(JSON.parse(response.data['footer']).col_2)
            setCol3(JSON.parse(response.data['footer']).col_3)
          }     
          setLoading(false)  
      })
      .catch(function (error) {            
        console.log(error)           
      })
    }

    useEffect(() => {
      loadingData();
    }, []);

    function SaveSettings(){
      setsendloading(true)
      const data = {
        'icon' : selectedIcon,
        'logo_tg' : selectedLogoTJ,
        'logo_ru' : selectedLogoRU,
        'logo_en' : selectedLogoEN,
        'title_tg' : langP === 'tg' ? document.getElementById('title_tj').value : null,
        'title_ru' : langP === 'ru' ? document.getElementById('title_ru').value : null,
        'title_en' : langP === 'en' ? document.getElementById('title_en').value : null,
        'keywords_tg' : langP === 'tg' ? document.getElementById('keywords_tj').value : null,
        'keywords_ru' : langP === 'ru' ? document.getElementById('keywords_ru').value : null,
        'keywords_en' : langP === 'en' ? document.getElementById('keywords_en').value : null,
        'menu' : menuData,
        'footer': {
          'email': document.getElementById('email').value,
          'email_comment_tg': document.getElementById('email_comment_tg').value,
          'email_comment_ru': document.getElementById('email_comment_ru').value,
          'email_comment_en': document.getElementById('email_comment_en').value,
          'phone': document.getElementById('phone').value,
          'adress_tg': document.getElementById('adress_tg').value,
          'adress_ru': document.getElementById('adress_ru').value,
          'adress_en': document.getElementById('adress_en').value,
          'whatsapp': document.getElementById('whatsapp').value,
          'telegram': document.getElementById('telegram').value,
          'facebook': document.getElementById('facebook').value,
          'instagram': document.getElementById('instagram').value,
          'youtube': document.getElementById('youtube').value,
          'nbt_phone': document.getElementById('nbt_phone').value,
          'copyright_tg': document.getElementById('copyright_tg').value,
          'copyright_ru': document.getElementById('copyright_ru').value,
          'copyright_en': document.getElementById('copyright_en').value,
          'license_tg': document.getElementById('license_tg').value,
          'license_ru': document.getElementById('license_ru').value,
          'license_en': document.getElementById('license_en').value,
          'col_1': col1,
          'col_2': col2,
          'col_3': col3
        }
      }   
      axios.post(APIURL + 'global/edite', data, {
          headers:{
            Authorization: `Bearer ${Cookie}`,
            'Content-Type': 'multipart/form-data'
          }
        }      
      )
      .then((response) => {
        if(response.status === 200){   
          setsendloading(false) 
          toast.success(succesText)           
        }
      })
      .catch(function (error) {   
        setsendloading(false)         
        toast.error(errorText)     
      })
      
    }

    const addMenuItem = (parentIndex, newItem) => {
      const updatedMenuData = [...menuData];
    
      if (parentIndex === undefined) {
        updatedMenuData.push(newItem);
      } else {
        if(updatedMenuData[parentIndex].children){
          updatedMenuData[parentIndex].children.push(newItem);
        }else{
          updatedMenuData[parentIndex].children = [];
          updatedMenuData[parentIndex].children.push(newItem);
        }
        
      }
    
      setMenuData(updatedMenuData);
    };

    function AddMenu(e){
      e.preventDefault()     
      if(addToItem.current){
        addMenuItem(addItem.current, {
          title_tg: document.getElementById('menu_title_tg').value,
          title_ru: document.getElementById('menu_title_ru').value,
          title_en: document.getElementById('menu_title_en').value,
          link: document.getElementById('menu_link').value,
        });
      }else{
        addMenuItem(undefined, {
          title_tg: document.getElementById('menu_title_tg').value,
          title_ru: document.getElementById('menu_title_ru').value,
          title_en: document.getElementById('menu_title_en').value,
          link: document.getElementById('menu_link').value,
          children: []
        });
      }      
      setShowModal(false)
    }    

    function EditeMenu(e){
      e.preventDefault() 
      const title_tg = document.getElementById('menu_title_tg_ed').value
      const title_ru = document.getElementById('menu_title_ru_ed').value
      const title_en = document.getElementById('menu_title_en_ed').value
      const link = document.getElementById('menu_link_ed').value
      const updatedMenuData = [...menuData];
      if(edMenu['indexChild'] === undefined){
        updatedMenuData[edMenu['indexMenu']].title_tg = title_tg
        updatedMenuData[edMenu['indexMenu']].title_ru = title_ru
        updatedMenuData[edMenu['indexMenu']].title_en = title_en
        updatedMenuData[edMenu['indexMenu']].link = link
      }else{
        updatedMenuData[edMenu['indexMenu']].children[edMenu['indexChild']].title_tg = title_tg
        updatedMenuData[edMenu['indexMenu']].children[edMenu['indexChild']].title_ru = title_ru
        updatedMenuData[edMenu['indexMenu']].children[edMenu['indexChild']].title_en = title_en
        updatedMenuData[edMenu['indexMenu']].children[edMenu['indexChild']].link = link
      }
      setShowModalEd(false)
    }

    function handleSort(){
      if(dragItemType.current === 'menu'){
        if(dragOverItem.current > 0){
          const menuClone = [...menuData]
          const temp = menuClone[dragItem.current]
          menuClone[dragItem.current] = menuClone[dragOverItem.current]
          menuClone[dragOverItem.current] = temp
          setMenuData(menuClone)
        }        
      }else{
        const menuClone = [...menuData]
        const temp = menuClone[dragItemParent.current].children[dragItem.current]
        menuClone[dragItemParent.current].children[dragItem.current] = menuClone[dragItemParent.current].children[dragOverItem.current]
        menuClone[dragItemParent.current].children[dragOverItem.current] = temp
        setMenuData(menuClone)
      }     
    }

    function AddItem(e){
      e.preventDefault() 
      const title_tg = document.getElementById('item_title_tg').value
      const title_ru = document.getElementById('item_title_ru').value
      const title_en = document.getElementById('item_title_en').value
      const link = document.getElementById('item_link').value
      if(addToCol.current === 1){
        col1.push({
          'title_tg': title_tg,
          'title_ru': title_ru,
          'title_en': title_en,
          'link': link,
        });
      }
      if(addToCol.current === 2){
        col2.push({
          'title_tg': title_tg,
          'title_ru': title_ru,
          'title_en': title_en,
          'link': link,
        });
      }
      if(addToCol.current === 3){
        col3.push({
          'title_tg': title_tg,
          'title_ru': title_ru,
          'title_en': title_en,
          'link': link,
        });
      }      
      setAddCol(false)
    }

    const deleteItemCol = (indexMenu, col) => {      
      if (window.confirm(logoutText)) {
        let updatedCol;
        switch (col) {
          case 3:
            updatedCol = [...col3];
            break;
          case 2:
            updatedCol = [...col2];
            break;
          default:
            updatedCol = [...col1]; 
        }
      
        updatedCol.splice(indexMenu, 1);
        switch (col) {
          case 3:
            setCol3(updatedCol);
            break;
          case 2:
            setCol2(updatedCol);
            break;
          default:
            setCol1(updatedCol); 
        }
      }
      
    };

    function EditeCol(e, col){
      e.preventDefault() 
      let updatedCol;
      switch (col) {
        case 3:
          updatedCol = [...col3];
          break;
        case 2:
          updatedCol = [...col2];
          break;
        default:
          updatedCol = [...col1]; 
      }      
      const title_tg = document.getElementById('ed_item_title_tg').value
      const title_ru = document.getElementById('ed_item_title_ru').value
      const title_en = document.getElementById('ed_item_title_en').value
      const link = document.getElementById('ed_item_link').value
      updatedCol[edcolIndex].title_tg = title_tg
      updatedCol[edcolIndex].title_ru = title_ru
      updatedCol[edcolIndex].title_en = title_en
      updatedCol[edcolIndex].link = link
      switch (col) {
        case 3:
          setCol3(updatedCol);
          break;
        case 2:
          setCol2(updatedCol);
          break;
        default:
          setCol1(updatedCol); 
      }
      setEditeCol(false)
    }

    const editeItemCol = (indexMenu, col) => {
      let updatedCol;
      switch (col) {
        case 3:
          updatedCol = [...col3];
          break;
        case 2:
          updatedCol = [...col2];
          break;
        default:
          updatedCol = [...col1]; 
      }
      setEdCol(updatedCol[indexMenu])
      setEdColIndex(indexMenu)
      addToCol.current = col
      setEditeCol(true)
    };

    function handleSortCol(){
      if(dragColNum.current === 1){
        const dataClone = [...col1]
        const temp = dataClone[dragColIndex.current]
        dataClone[dragColIndex.current] = dataClone[dragColOverIndex.current]
        dataClone[dragColOverIndex.current] = temp
        setCol1(dataClone)       
      }   
      if(dragColNum.current === 2){
        const dataClone = [...col2]
        const temp = dataClone[dragColIndex.current]
        dataClone[dragColIndex.current] = dataClone[dragColOverIndex.current]
        dataClone[dragColOverIndex.current] = temp
        setCol2(dataClone)       
      }
      if(dragColNum.current === 3){
        const dataClone = [...col3]
        const temp = dataClone[dragColIndex.current]
        dataClone[dragColIndex.current] = dataClone[dragColOverIndex.current]
        dataClone[dragColOverIndex.current] = temp
        setCol3(dataClone)       
      }  
    } 

    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      {loading ? GlobalData('loading') :
      <div>
      <Toaster richColors position="top-center"/>
      <div className='flex mx-auto justify-between py-3'>
        <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(32)} </div>
        <div className='justify-self-end'>        
          <button onClick={() => setLangP('tg')}
            className={classNames(
              langP === 'tg' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'px-3 py-2 font-bold rounded-lg'
            )}>
            TJ
          </button>
          <button onClick={() => setLangP('ru')}
            className={classNames(
              langP === 'ru' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'ml-2 px-3 py-2 font-bold rounded-lg'
            )}>
            RU
          </button>
          <button onClick={() => setLangP('en')}
            className={classNames(
              langP === 'en' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'ml-2 px-3 py-2 font-bold rounded-lg'
            )}>
            EN
          </button>
        </div>         
      </div>
      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-7">
        <div className="sm:col-span-1 ">
            <label htmlFor="site-icon" className="block text-lg font-medium leading-6 text-gray-900">
                {GlobalText(36)}
              
            <div className="items-center justify-center mt-2">
              <Label
                htmlFor="site-icon"
                className="flex w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                
                {selectedIcon ? <div className="items-center justify-center">
                  <img
                    alt="Icon"
                    className='p-3 '
                    width={"80px"}
                    src={URL.createObjectURL(selectedIcon)}
                  />
                </div> : siteIcon ? <div className="items-center justify-center">
                  <img
                    alt="Icon"
                    className='p-3 '
                    width="80px"
                    src={IMGURL + siteIcon}
                  />
                </div>  : <div className="items-center justify-center">
                  <PhotoIcon className='text-gray-300 h-20 p-3'/>
                </div> }
                <FileInput id="site-icon" className="hidden" 
                  onChange={(event) => {
                    if(event.target.files[0]){
                      setSelectedIcon(event.target.files[0]); 
                    }                    
                  }}
                />
              </Label>
            </div>
            </label>
          </div>
          <div className="sm:col-span-2">
            {langP === 'tg' && <label htmlFor="site-logo-tj" className="block text-lg font-medium leading-6 text-gray-900">
                {GlobalText(34)}
              
            <div className="flex w-full items-center justify-center mt-2 flex w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
               {selectedLogoTJ ? <div className="items-center justify-center ">
                  <img
                    alt="Icon"
                    className='p-3'
                    width={"300px"}
                    src={URL.createObjectURL(selectedLogoTJ)}
                  />
                </div> : siteLogoTJ ? <div className="items-center justify-center ">
                  <img
                    alt="Icon"
                    className='p-3'
                    width={"300px"}
                    src={IMGURL + siteLogoTJ}
                  />
                </div> : <div className="items-center justify-center">
                  <PhotoIcon className='text-gray-300 h-20 p-3'/>
                </div> }
                <FileInput id="site-logo-tj" className="hidden" 
                  onChange={(event) => {
                    if(event.target.files[0]){
                      setSelectedLogoTJ(event.target.files[0]); 
                    }
                  }}
                />
            </div>
            </label>}
            {langP === 'ru' && <label htmlFor="site-logo-ru" className="block text-lg font-medium leading-6 text-gray-900">
                {GlobalText(34)}
              
            <div className="flex w-full items-center justify-center mt-2 flex w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
               {selectedLogoRU ? <div className="items-center justify-center ">
                  <img
                    alt="Icon"
                    className='p-3'
                    width={"300px"}
                    src={URL.createObjectURL(selectedLogoRU)}
                  />
                </div> : siteLogoRU ? <div className="items-center justify-center ">
                  <img
                    alt="Icon"
                    className='p-3'
                    width={"300px"}
                    src={IMGURL + siteLogoRU}
                  />
                </div> : <div className="items-center justify-center">
                  <PhotoIcon className='text-gray-300 h-20 p-3'/>
                </div> }
                <FileInput id="site-logo-ru" className="hidden" 
                  onChange={(event) => {
                    if(event.target.files[0]){
                      setSelectedLogoRU(event.target.files[0]); 
                    }
                  }}
                />
            </div>
            </label>}
            {langP === 'en' && <label htmlFor="site-logo-en" className="block text-lg font-medium leading-6 text-gray-900">
                {GlobalText(34)}
              
            <div className="flex w-full items-center justify-center mt-2 flex w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
               {selectedLogoEN ? <div className="items-center justify-center ">
                  <img
                    alt="Icon"
                    className='p-3'
                    width={"300px"}
                    src={URL.createObjectURL(selectedLogoEN)}
                  />
                </div> : siteLogoEN ? <div className="items-center justify-center ">
                  <img
                    alt="Icon"
                    className='p-3'
                    width={"300px"}
                    src={IMGURL + siteLogoEN}
                  />
                </div> : <div className="items-center justify-center">
                  <PhotoIcon className='text-gray-300 h-20 p-3'/>
                </div> }
                <FileInput id="site-logo-en" className="hidden" 
                  onChange={(event) => {
                    if(event.target.files[0]){
                      setSelectedLogoEN(event.target.files[0]); 
                    }
                  }}
                />
            </div>
            </label>}
          </div>
          {langP === 'tg' &&  <div className="sm:col-span-4">
            <label htmlFor="title_tj" className="block text-lg font-medium leading-6 text-gray-900">
              {GlobalText(35)}
            </label>
            <div className="mt-2">
              <input
                maxLength={100}
                required
                type="text"
                id="title_tj"
                autoComplete="given-name"
                placeholder={GlobalText(35)}
                defaultValue={siteNameTJ}
                className="block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
              />
            </div>
          </div> } 
          {langP === 'ru' &&  <div className="sm:col-span-4">
            <label htmlFor="title_ru" className="block text-lg font-medium leading-6 text-gray-900">
              {GlobalText(35)}
            </label>
            <div className="mt-2">
              <input
                maxLength={100}
                required
                type="text"
                id="title_ru"
                autoComplete="given-name"
                placeholder={GlobalText(35)}
                defaultValue={siteNameRU}
                className="block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
              />
            </div>
          </div> }   
          {langP === 'en' &&  <div className="sm:col-span-4">
            <label htmlFor="title_en" className="block text-lg font-medium leading-6 text-gray-900">
              {GlobalText(35)}
            </label>
            <div className="mt-2">
              <input
                maxLength={100}
                required
                type="text"
                id="title_en"
                autoComplete="given-name"
                placeholder={GlobalText(35)}
                defaultValue={siteNameEN}
                className="block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
              />
            </div>
          </div> }          
       </div>
       {langP === 'tg' && <div className="col-span-full mt-4">
            <label htmlFor="keywords_tj" className="block text-lg font-medium leading-6 text-gray-900">
                {GlobalText(37)}
              
          <div className="mt-2">
            <textarea
              id="keywords_tj"
              rows={3}
              placeholder={GlobalText(37)}
              defaultValue={keywordsTJ}
              className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              
            />
          </div>
            </label>
        </div>}
        {langP === 'ru' && <div className="col-span-full mt-4">
            <label htmlFor="keywords_ru" className="block text-lg font-medium leading-6 text-gray-900">
                {GlobalText(37)}
              
          <div className="mt-2">
            <textarea
              id="keywords_ru"
              rows={3}
              placeholder={GlobalText(37)}
              defaultValue={keywordsRU}
              className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              
            />
          </div>
            </label>
        </div>}
        {langP === 'en' && <div className="col-span-full mt-4">
            <label htmlFor="keywords_en" className="block text-lg font-medium leading-6 text-gray-900">
                {GlobalText(37)}
              
          <div className="mt-2">
            <textarea
              id="keywords_en"
              rows={3}
              placeholder={GlobalText(37)}
              defaultValue={keywordsEN}
              className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              
            />
          </div>
            </label>
        </div>}
        <div className="col-span-full mt-6">
          <div className='flex mx-auto justify-between py-3'>
            <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(40)} </div>      
          </div>
          <div className="mt-2 w-full rounded-lg border-2 border-dashed border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div className="flex flex-col px-2">
              {menuData.map((menuItem, menuIndex) => (
                <div key={menuItem.link} className="border-b-2 border-gray-200 bg-white">
                  <div className="flex items-center justify-between py-4 px-4"
                    draggable={!menuItem.nodelete && true}
                    onDragStart={() => {
                      dragItemType.current = 'menu'
                      dragItem.current = menuIndex                 
                    }}
                    onDragEnter={() => dragItemType.current === 'menu' && !menuItem.nodelete && (dragOverItem.current = menuIndex)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <p className="font-medium leading-6 text-gray-900 text-lg">{langP === 'tg' ? menuItem.title_tg : langP === 'ru' ? menuItem.title_ru : menuItem.title_en} </p>
                          <p className="mt-1 truncate text-sm leading-5 text-gray-500">{menuItem.link} 
                          {menuItem.nodelete && <span className="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(52)}</span>}
                          </p>
                        </div>
                      </div>
                    <div>
                      {!menuItem.nodelete && <button 
                          onClick={() => removeMenuItem(menuIndex)}
                          type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                          <TrashIcon className='h-4 w-5'/>
                      </button>} 
                      <button 
                          onClick={() => preedite(menuIndex)}
                          type="button" className="text-gray-500 mx-1 border border-gray-600 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                          <PencilIcon className='h-4 w-5'/>
                      </button>
                      <button 
                          onClick={() => {                
                            addToItem.current = true
                            addItem.current = menuIndex
                            setShowModal(true)
                          }}
                          type="button" className="text-gray-500 mx-1 border border-gray-600 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                          <PlusIcon className='h-4 w-5'/>
                      </button>
                    </div>
                  </div>
                  {menuItem.children && (
                    <div className="pl-8">
                      <div className="list-disc">
                        {menuItem.children.map((childItem, childIndex) => (<div>
                          <div className="flex items-center justify-between border-t border-gray-200 py-2 px-4"
                            draggable
                            onDragStart={() => {
                              dragItemType.current = 'sub'
                              dragItem.current = childIndex
                              dragItemParent.current = menuIndex                             
                            }}
                            onDragEnter={() => {
                              if(dragItemParent.current === menuIndex){
                                dragOverItem.current = childIndex
                              }
                            }}
                            onDragEnd={handleSort}
                            onDragOver={(e) => e.preventDefault()}
                          >
                              <div className="min-w-0 flex-auto">
                                <p className="font-medium leading-6 text-gray-900 text-lg"> {langP === 'tg' ? childItem.title_tg : langP === 'ru' ? childItem.title_ru : childItem.title_en}</p>
                                <p className="mt-1 truncate text-sm leading-5 text-gray-500">{childItem.link}</p>
                              </div>
                              <button 
                                onClick={() => removeMenuItem(menuIndex, childIndex)}
                                type="button" className="text-red-700 mx-1 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                <TrashIcon className='h-4 w-5'/>
                            </button>  
                            <button 
                                onClick={() => preedite(menuIndex, childIndex)}
                                type="button" className="text-gray-500 mx-1 border border-gray-600 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                <PencilIcon className='h-4 w-5'/>
                            </button>
                          </div>
                        </div>))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <button 
              onClick={() => {                
                addToItem.current = false
                setShowModal(true)
              }}
              className="mx-5 my-5 justify-self-end text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
                <PlusIcon className='h-5 w-5 mr-2'/>
                {GlobalText(43)}
            </button> 
          </div>
            
        </div>
        {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className='sm:mx-60'></div>
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                  <h3 className="text-xl font-semibold text-gray-600">
                    {addToItem.current === true ? GlobalText(56) : GlobalText(43)} 
                    {addToItem.current === true && langP === 'tg' && ' (' + menuData[addItem.current]['title_tg'] + ')'}
                    {addToItem.current === true && langP === 'ru' && ' (' + menuData[addItem.current]['title_ru'] + ')'}
                    {addToItem.current === true && langP === 'en' && ' (' + menuData[addItem.current]['title_en'] + ')'}
                  </h3>
                  <button
                    className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                      <XMarkIcon />
                    </span>
                  </button>
                </div>
                <form onSubmit={(e) => AddMenu(e)}>
                <div className="relative p-6 flex-auto">  
                  <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_tg"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(53) + ')'}</label>
                    </div> 
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_ru"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(54) + ')'}</label>
                    </div>  
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_en"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(55) + ')'}</label>
                    </div> 
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_link"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_link" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                    </div> 
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                    <button
                      className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                    {GlobalText(31)}
                  </button>            
                </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
        {showModalEd ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className='sm:mx-60'></div>
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                  <h3 className="text-xl font-semibold text-gray-600">
                    {GlobalText(57)}
                  </h3>
                  <button
                    className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                    onClick={() => setShowModalEd(false)}
                  >
                    <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                      <XMarkIcon />
                    </span>
                  </button>
                </div>
                <form onSubmit={(e) => EditeMenu(e)}>
                <div className="relative p-6 flex-auto">   
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_tg_ed"
                          minLength='2'
                          required
                          placeholder=''
                          defaultValue={edMenu['indexChild'] === undefined ? menuData[edMenu['indexMenu']].title_tg : menuData[edMenu['indexMenu']].children[edMenu['indexChild']].title_tg}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_tg_ed" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(53) + ')'}</label>
                    </div>   
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_ru_ed"
                          minLength='2'
                          required
                          placeholder=''
                          defaultValue={edMenu['indexChild'] === undefined ? menuData[edMenu['indexMenu']].title_ru : menuData[edMenu['indexMenu']].children[edMenu['indexChild']].title_ru}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_ru_ed" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(54) + ')'}</label>
                    </div>  
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_en_ed"
                          minLength='2'
                          required
                          placeholder=''
                          defaultValue={edMenu['indexChild'] === undefined ? menuData[edMenu['indexMenu']].title_en : menuData[edMenu['indexMenu']].children[edMenu['indexChild']].title_en}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_en_ed" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(55) + ')'}</label>
                    </div>  
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_link_ed"
                          minLength='2'
                          required
                          placeholder=''
                          defaultValue={edMenu['indexChild'] === undefined ? menuData[edMenu['indexMenu']].link : menuData[edMenu['indexMenu']].children[edMenu['indexChild']].link}
                          disabled={edMenu['indexChild'] === undefined &&  menuData[edMenu['indexMenu']].nodelete && true}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_link_ed" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                    </div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                    <button
                      className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                    {GlobalText(27)}
                  </button>            
                </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <div className="col-span-full mt-6">
          <div className='flex mx-auto justify-between py-3'>
            <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(67)} </div> 
            <div className='justify-self-end'>        
          <button onClick={() => setLangP('tg')}
            className={classNames(
              langP === 'tg' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'px-3 py-2 font-bold rounded-lg'
            )}>
            TJ
          </button>
          <button onClick={() => setLangP('ru')}
            className={classNames(
              langP === 'ru' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'ml-2 px-3 py-2 font-bold rounded-lg'
            )}>
            RU
          </button>
          <button onClick={() => setLangP('en')}
            className={classNames(
              langP === 'en' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'ml-2 px-3 py-2 font-bold rounded-lg'
            )}>
            EN
          </button>
        </div>      
          </div>
          <div className="mt-2 w-full rounded-lg border-2 border-dashed border-gray-300  dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div className="flex flex-col px-2">      
            <footer id="brt_footer" className="footer bg-white rounded-t-2xl mt-10">
              
              <div className="container">
                <div className="footer-inner pb-12 pt-5">
                  <div className="flex flex-wrap flex-row sm:flex-row sm:flex-nowrap">
                    <div className="w-full inline-block <sm:mb-4 px-2">
                      <div className="relative z-0 w-full mb-5">
                        <input
                          type="email"
                          id="email"
                          required
                          placeholder=''
                          defaultValue={footerData['email']}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                        <label htmlFor="email" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(8)}</label>
                      </div>
                      <div className="relative z-0 w-full mb-5"
                        hidden={langP === 'tg' ? false : true}
                      >
                        <input
                          type="text"
                          id="email_comment_tg"     
                          placeholder=''                     
                          defaultValue={footerData['email_comment_tg']}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                        <label htmlFor="email_comment_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(68)}</label>
                      </div>
                      <div className="relative z-0 w-full mb-5"
                        hidden={langP === 'ru' ? false : true}
                      >
                        <input
                          type="text"
                          id="email_comment_ru"   
                          placeholder=''                       
                          defaultValue={footerData['email_comment_ru']}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                        <label htmlFor="email_comment_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(68)}</label>
                      </div>
                      <div className="relative z-0 w-full mb-5"
                        hidden={langP === 'en' ? false : true}
                      >
                        <input
                          type="text"
                          id="email_comment_en"       
                          placeholder=''                   
                          defaultValue={footerData['email_comment_en']}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                        <label htmlFor="email_comment_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(68)}</label>
                      </div>
                    </div>
                    <div className="w-full inline-block <sm:mb-4 px-2">
                      <div className="relative z-0 w-full mb-5">
                        <input
                          type="text"
                          id="phone"
                          required
                          placeholder=''
                          defaultValue={footerData['phone']}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                        <label htmlFor="phone" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(69)}</label>
                      </div>
                      <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'tg' ? false : true}
                        >
                          <input
                            type="text"
                            id="adress_tg"    
                            placeholder=''                      
                            defaultValue={footerData['adress_tg']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="adress_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(70)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'ru' ? false : true}
                        >
                          <input
                            type="text"
                            id="adress_ru"    
                            placeholder=''                      
                            defaultValue={footerData['adress_ru']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="adress_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(70)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'en' ? false : true}
                        >
                          <input
                            type="text"
                            id="adress_en"      
                            placeholder=''                    
                            defaultValue={footerData['adress_en']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="adress_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(70)}</label>
                        </div>
                    </div>    
                    <div className="w-full inline-block <sm:mb-4 px-2">
                      <div className="relative z-0 w-full mb-5">
                        <input
                          type="text"
                          id="whatsapp"
                          placeholder=''
                          defaultValue={footerData['whatsapp']}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                        <label htmlFor="whatsapp" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(71)}</label>
                      </div>
                      <div className="relative z-0 w-full mb-5">
                        <input
                          type="text"
                          id="telegram"
                          placeholder=''
                          defaultValue={footerData['telegram']}
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                        <label htmlFor="telegram" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(72)}</label>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-2 <lg:flex-wrap <sm:pt-1 <sm:pb-4 pt-12 pb-10 border-y border-stroke">
                    <div className="w-full <lg:mb-12 <sm:mb-0 rounded-lg border-2 border-dashed p-2" >
                        <div className="flex flex-col px-2">
                          
                        {col1 && col1.map((menuItem, menuIndex) => (
                          <div key={menuItem.link} className="border-b-2 border-gray-200 bg-white">
                            <div className="flex items-center justify-between py-4"
                              draggable
                              onDragStart={() => {
                                dragColNum.current = 1
                                dragColIndex.current = menuIndex                 
                              }}
                              onDragEnter={() => {
                                dragColOverIndex.current = menuIndex                
                              }}
                              onDragEnd={handleSortCol}
                              onDragOver={(e) => e.preventDefault()}
                            >
                              <div className="flex min-w-0 gap-x-4">
                                  <div className="min-w-0 flex-auto">
                                    <p className={classNames(
                                        menuIndex === 0 ? 'font-bold' : 'font-medium',
                                        'leading-6 text-gray-900 text-lg'
                                      )}>{langP === 'tg' ? menuItem.title_tg : langP === 'ru' ? menuItem.title_ru : menuItem.title_en} </p>
                                    <p className="mt-1 truncate text-sm leading-5 text-gray-500">{menuItem.link} 
                                    {menuItem.nodelete && <span className="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(52)}</span>}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                <div className='flex flex-col'>                                
                                <button 
                                    onClick={() => editeItemCol(menuIndex, 1)}
                                    type="button" className="text-gray-500 my-0.5 border border-gray-600 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                    <PencilIcon className='h-4 w-5'/>
                                </button>
                                <button 
                                    onClick={() => deleteItemCol(menuIndex, 1)}
                                    type="button" className="text-red-700 my-0.5 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                    <TrashIcon className='h-4 w-5'/>
                                </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}                        
                      </div>
                      <button 
                          onClick={() => {     
                          addToCol.current = 1
                          setAddCol(true)
                        }}
                        className="mx-5 my-5 text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
                          <PlusIcon className='h-5 w-5 mr-2'/>
                          {GlobalText(43)}
                      </button> 
                      
                    </div>
                    <div className="w-full <lg:mb-12 <sm:mb-0 rounded-lg border-2 border-dashed p-2" >
                        <div className="flex flex-col px-2">                          
                        {col2.map((menuItem, menuIndex) => (
                          <div key={menuItem.link} className="border-b-2 border-gray-200 bg-white">
                            <div className="flex items-center justify-between py-4"
                              draggable
                              onDragStart={() => {
                                dragColNum.current = 2
                                dragColIndex.current = menuIndex                 
                              }}
                              onDragEnter={() => {
                                dragColOverIndex.current = menuIndex                
                              }}
                              onDragEnd={handleSortCol}
                              onDragOver={(e) => e.preventDefault()}
                            >
                              <div className="flex min-w-0 gap-x-4">
                                  <div className="min-w-0 flex-auto">
                                    <p className={classNames(
                                        menuIndex === 0 ? 'font-bold' : 'font-medium',
                                        'leading-6 text-gray-900 text-lg'
                                      )}>{langP === 'tg' ? menuItem.title_tg : langP === 'ru' ? menuItem.title_ru : menuItem.title_en} </p>
                                    <p className="mt-1 truncate text-sm leading-5 text-gray-500">{menuItem.link} 
                                    {menuItem.nodelete && <span className="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(52)}</span>}
                                    </p>
                                  </div>
                                </div>
                              <div>
                              <div className='flex flex-col'>                                
                                <button 
                                    onClick={() => editeItemCol(menuIndex, 2)}
                                    type="button" className="text-gray-500 my-0.5 border border-gray-600 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                    <PencilIcon className='h-4 w-5'/>
                                </button>
                                <button 
                                    onClick={() => deleteItemCol(menuIndex, 2)}
                                    type="button" className="text-red-700 my-0.5 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                    <TrashIcon className='h-4 w-5'/>
                                </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}                        
                      </div>
                      <button 
                          onClick={() => {     
                          addToCol.current = 2
                          setAddCol(true)
                        }}
                        className="mx-5 my-5 text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
                          <PlusIcon className='h-5 w-5 mr-2'/>
                          {GlobalText(43)}
                      </button> 
                    </div>
                    <div className="w-full <lg:mb-12 <sm:mb-0 rounded-lg border-2 border-dashed p-2" >
                        <div className="flex flex-col px-2">                          
                        {col3.map((menuItem, menuIndex) => (
                          <div key={menuItem.link} className="border-b-2 border-gray-200 bg-white">
                            <div className="flex items-center justify-between py-4"
                              draggable
                              onDragStart={() => {
                                dragColNum.current = 3
                                dragColIndex.current = menuIndex                 
                              }}
                              onDragEnter={() => {
                                dragColOverIndex.current = menuIndex                
                              }}
                              onDragEnd={handleSortCol}
                              onDragOver={(e) => e.preventDefault()}
                            >
                              <div className="flex min-w-0 gap-x-4">
                                  <div className="min-w-0 flex-auto">
                                    <p className={classNames(
                                        menuIndex === 0 ? 'font-bold' : 'font-medium',
                                        'leading-6 text-gray-900 text-lg'
                                      )}>
                          {langP === 'tg' ? menuItem.title_tg : langP === 'ru' ? menuItem.title_ru : menuItem.title_en} </p>
                                    <p className="mt-1 truncate text-sm leading-5 text-gray-500">{menuItem.link} 
                                    {menuItem.nodelete && <span className="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(52)}</span>}
                                    </p>
                                  </div>
                                </div>
                              <div>
                              <div className='flex flex-col'>                                
                                <button 
                                    onClick={() => editeItemCol(menuIndex, 3)}
                                    type="button" className="text-gray-500 my-0.5 border border-gray-600 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                    <PencilIcon className='h-4 w-5'/>
                                </button>
                                <button 
                                    onClick={() => deleteItemCol(menuIndex, 3)}
                                    type="button" className="text-red-700 my-0.5 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                                    <TrashIcon className='h-4 w-5'/>
                                </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}                        
                      </div>
                      <button 
                          onClick={() => {     
                          addToCol.current = 3
                          setAddCol(true)
                        }}
                        className="mx-5 my-5 text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
                          <PlusIcon className='h-5 w-5 mr-2'/>
                          {GlobalText(43)}
                      </button> 
                    </div>
                    <div className="w-full <sm:w-full rounded-lg border-2 border-dashed p-2">
                      <div className="mb-12 <sm:pt-6 <sm:mb-6 dark:border-dark">
                        <div className="font-bold mb-6">{GlobalText(75)}</div>
                        <div className="relative z-0 w-full mb-5">
                          <input
                            type="text"
                            id="facebook"
                            placeholder=''
                            defaultValue={footerData['facebook']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="facebook" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">Facebook</label>
                        </div>
                        <div className="relative z-0 w-full mb-5">
                          <input
                            type="text"
                            id="instagram"
                            placeholder=''
                            defaultValue={footerData['instagram']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="instagram" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">Instagram</label>
                        </div>
                        <div className="relative z-0 w-full mb-5">
                          <input
                            type="text"
                            id="youtube"
                            placeholder=''
                            defaultValue={footerData['youtube']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="youtube" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">YouTube</label>
                        </div>
                      </div>
                      <div className="mb-2 <sm:pt-6 <sm:mb-2 dark:border-dark">
                        <div className="font-bold mb-2">{GlobalText(76)}</div>
                        <div className="relative z-0 w-full mb-5">
                          <textarea
                          rows={3}
                            id="nbt_phone"
                            placeholder={GlobalText(76)}
                            defaultValue={footerData['nbt_phone']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                        </div>
                      </div>
                    </div>
                    {addCol ? (
                        <>
                          <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                          >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className='sm:mx-60'></div>
                                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                                  <h3 className="text-xl font-semibold text-gray-600">
                                    {GlobalText(43)} 
                                  </h3>
                                  <button
                                    className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                                    onClick={() => setAddCol(false)}
                                  >
                                    <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                                      <XMarkIcon />
                                    </span>
                                  </button>
                                </div>
                                <form onSubmit={(e) => AddItem(e)}>
                                <div className="relative p-6 flex-auto">  
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="item_title_tg"
                                        minLength='2'
                                        required
                                        placeholder=''
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="item_title_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(53) + ')'}</label>
                                  </div>  
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="item_title_ru"
                                        minLength='2'
                                        required
                                        placeholder=''
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="item_title_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(54) + ')'}</label>
                                  </div> 
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="item_title_en"
                                        minLength='2'
                                        required
                                        placeholder=''
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="item_title_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(55) + ')'}</label>
                                  </div>   
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="item_link"
                                        minLength='1'
                                        required
                                        placeholder=''
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="item_link" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                                  </div> 
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                                    <button
                                      className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="submit"
                                    >
                                    {GlobalText(31)}
                                  </button>            
                                </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}
                  </div>
                  {editeCol ? (
                        <>
                          <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                          >
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className='sm:mx-60'></div>
                                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                                  <h3 className="text-xl font-semibold text-gray-600">
                                    {GlobalText(57)} 
                                  </h3>
                                  <button
                                    className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                                    onClick={() => setEditeCol(false)}
                                  >
                                    <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                                      <XMarkIcon />
                                    </span>
                                  </button>
                                </div>
                                <form onSubmit={(e) => EditeCol(e, addToCol.current)}>
                                <div className="relative p-6 flex-auto">  
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="ed_item_title_tg"
                                        minLength='2'
                                        required
                                        placeholder=''
                                        defaultValue={edcol['title_tg']}
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="ed_item_title_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(53) + ')'}</label>
                                  </div>  
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="ed_item_title_ru"
                                        minLength='2'
                                        required
                                        placeholder=''
                                        defaultValue={edcol['title_ru']}
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="ed_item_title_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(54) + ')'}</label>
                                  </div> 
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="ed_item_title_en"
                                        minLength='2'
                                        required
                                        placeholder=''
                                        defaultValue={edcol['title_en']}
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="ed_item_title_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(55) + ')'}</label>
                                  </div>   
                                  <div className="relative z-0 w-full mb-5">
                                    <input
                                        maxLength={100}
                                        autoComplete='off'
                                        type="text"
                                        id="ed_item_link"
                                        minLength='1'
                                        required
                                        placeholder=''
                                        defaultValue={edcol['link']}
                                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                                      />
                                    <label htmlFor="ed_item_link" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                                  </div> 
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                                    <button
                                      className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="submit"
                                    >
                                    {GlobalText(27)}
                                  </button>            
                                </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}
                  <div className="flex flex-wrap flex-row sm:flex-row sm:flex-nowrap pt-6">
                      <div className="w-full inline-block <sm:mb-4 px-2">                        
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'tg' ? false : true}
                        >
                          <input
                            type="text"
                            id="copyright_tg"     
                            placeholder=''                     
                            defaultValue={footerData['copyright_tg']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="copyright_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(74)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'ru' ? false : true}
                        >
                          <input
                            type="text"
                            id="copyright_ru"     
                            placeholder=''                     
                            defaultValue={footerData['copyright_ru']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="copyright_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(74)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'en' ? false : true}
                        >
                          <input
                            type="text"
                            id="copyright_en"           
                            placeholder=''               
                            defaultValue={footerData['copyright_en']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="copyright_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(74)}</label>
                        </div>
                      </div>
                      <div className="w-full inline-block <sm:mb-4 px-2">                        
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'tg' ? false : true}
                        >
                          <input
                            type="text"
                            id="license_tg"            
                            placeholder=''              
                            defaultValue={footerData['license_tg']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="license_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(73)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'ru' ? false : true}
                        >
                          <input
                            type="text"
                            id="license_ru"          
                            placeholder=''                
                            defaultValue={footerData['license_ru']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="license_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(73)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-5"
                          hidden={langP === 'en' ? false : true}
                        >
                          <input
                            type="text"
                            id="license_en"              
                            placeholder=''            
                            defaultValue={footerData['license_en']}
                            className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                          />
                          <label htmlFor="license_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(73)}</label>
                        </div>
                      </div>    
                    </div>
                </div>
              </div>
            </footer>        
            </div>
          </div>
            
        </div>
       <div className='flex mx-auto justify-between mt-10'>
          {sendloading ? <button disabled className="uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
          <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(27)}
          </button> : <button onClick={(event) => {
            SaveSettings()
          }}
          className="uppercase text-white bg-gray-500 hover:bg-gray-600 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
            {GlobalText(27)}
          </button> }
        </div> 
        </div> }
    </div>)
  }  

  export default Settings