import React, { Fragment, useState } from 'react'
import {Menu, Transition} from '@headlessui/react'
import GlobalText from './globalText'
import { IoMdCheckmark } from "react-icons/io";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
  Link,
  useLocation
} from "react-router-dom";
import logoTj from '../imgs/logo_blue_tj_h1.png'
import logoRu from '../imgs/logo_blue_ru_h1.png'
import logoEn from '../imgs/logo_blue_en_h1.png'
import axios from 'axios';
import Cookies from 'js-cookie';
import Helmet from 'react-helmet';
import GlobalData from './globalData';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




function Login() {
  const IMGURL = GlobalData('imgUrl')
  const APIURL = GlobalData('apiUrl')
  const location = useLocation();
  const isCurrentlyEn = location.pathname.startsWith('/en');
  const isCurrentlyRu = location.pathname.startsWith('/ru');
  const language = isCurrentlyEn ? 'en' : isCurrentlyRu ? 'ru' : 'tg'  
  const [loading, setLoading] = useState(false);

  function checkLogin(e){
    e.preventDefault()  
    setLoading(true)
    const email = document.getElementById('email').value
    const password = document.getElementById('password').value    
    document.getElementById('alert').hidden = true  
      axios.post(APIURL + 'login', {
        'email': email,
        'password': password
      })
      .then((response) => {
        if(response.status === 200){
          if(response.data.token === 'Unauthenticated'){
            document.getElementById('password').value = ''
            document.getElementById('alert').hidden = false
            setLoading(false)
          }else{          
            Cookies.set('token', response.data.token, { path: '/' });
            window.location.reload(); 
          }
        }
      })
      .catch(function (error) {     
        setLoading(false)          
      })
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <Helmet>
        <link rel="icon" href={IMGURL + 'icon.png'} />
      </Helmet>
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-dvh lg:py-0">
      <Link to={language === 'tg' ? '/' : language} className="-m-1.5 p-1.5 mb-5">
            <span className="sr-only">{GlobalText(5, language)}</span>
            <img className="h-10 w-auto" src={language === 'en' ? logoEn : language === 'ru' ? logoRu : logoTj} alt="BRT logo" />
      </Link>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form className="space-y-4 md:space-y-6" onSubmit={(e) => checkLogin(e)}>
                  <div>
                      <label htmlFor="email" className="block mb-2 font-medium text-gray-900 dark:text-white">{GlobalText(8, language)}</label>
                      <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={GlobalText(10, language)} required autoComplete="on"/>
                  </div>
                  <div>
                      <label htmlFor="password" className="block mb-2 font-medium text-gray-900 dark:text-white">{GlobalText(9, language)}</label>
                      <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required autoComplete="on"/>
                  </div>
                  <div id='alert' hidden className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 brt-text-medium" role="alert">
                    {GlobalText(12)}                    
                  </div>      
                  {loading ? <button disabled className="w-full uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center justify-center ">
                  <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(11)}
                  </button> : <button
                    className="w-full text-white rounded-lg px-5 py-2.5 text-center brt-bg-blue hover:brt-bg-blue brt-text-bold uppercase"
                    type="submit"
                  >
                    {GlobalText(11)}
                  </button> }  

                  <div className="flex flex-1 justify-end ">
                      <div className="">
                      <Menu as="div" className="relative inline-block text-left">
                <Menu.Button
                  className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-gray-600 brt-text-regular hover:bg-gray-50"        
                >
                  {GlobalText(0, language)}
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-500 " aria-hidden="true" />
                </Menu.Button>
                <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                  >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                          <Menu.Item >
                          {({ active }) => (
                              <Link to='admin'                                                   
                              className={language === "tg"
                              ? classNames(
                                  'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                  )
                                  : classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                    'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                )
                                }
                              >
                              Тоҷикӣ
                              {language === "tg" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                              </Link>
                          )}
                          </Menu.Item>
                          <Menu.Item  >
                          {({ active }) => (
                              <Link to='ru/admin'                                                     
                              className={language === "ru"
                              ? classNames(
                                  'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                  )
                                  : classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                    'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                )
                                }
                              >
                              Русский
                              {language === "ru" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                              </Link>
                          )}
                          </Menu.Item>
                          <Menu.Item  >
                          {({ active }) => (
                              <Link to='en/admin'   
                              className={language === "en"
                              ? classNames(
                                  'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                  )
                                  : classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                                    'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                                )
                                }
                              >
                              English 
                              {language === "en" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                              </Link>
                          )}
                          </Menu.Item>
                      </div>
                      </Menu.Items>
                  </Transition>  
                  </Menu>
                      </div>            
                  </div>
              </form>
          </div>
      </div>
  </div>
</section>
  )
}

export default Login