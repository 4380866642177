import { PencilIcon, TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import GlobalText from './globalText'
import { Link } from "react-router-dom";
import LinkTo from './LinkTo';
import React, { useState, useEffect } from "react";
import { Toaster, toast } from 'sonner'
import GlobalData from './globalData';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toDate } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';
  
  function Vacancies() {
    const APIURL = GlobalData('apiUrl')
    const succesTextDel = GlobalText(154)
    const errorText = GlobalText(39)
    const ConfirmText = GlobalText(153)
    const Cookie = Cookies.get('token')
    const [loading, setLoading] = useState(true);
    const [vacancies, setvacancies] = useState([]);

    function loadingData(){      
      axios.get(APIURL + 'vacancies/all', {
        headers:{
          Authorization: `Bearer ${Cookie}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
          setvacancies(response.data)   
          setLoading(false)   
      })
      .catch(function (error) {         
        setLoading(false)    
      })
    }

    const formatDate = (isoString) => {
      const date = new Date(isoString);
      
      // Получение компонентов даты и времени
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      
      return `${day}.${month}.${year} `;
    };

    function DeleteVacancies(id){ 
      axios.post(APIURL + 'vacancies/delete', {
        'id' : id
      }, {
        headers:{
          Authorization: `Bearer ${Cookie}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
          loadingData()
          toast.success(succesTextDel) 
      })
      .catch(function (error) {
        toast.error(errorText)
      })
    }

    useEffect(() => {
      loadingData();
    }, []);
    
    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      {loading ? GlobalData('loading') : <div>
      <Toaster richColors position="top-center"/>
      <div className='flex mx-auto justify-between py-3'>
        <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(148)} </div>
        <Link to={'/admin/vacancies/add'} className="justify-self-end text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
            <PlusIcon className='h-5 w-5 mr-2'/>
            {GlobalText(31)}
        </Link>  
      </div>
      <ul role="list" className="divide-y divide-gray-100 mx-auto ">
      {vacancies.map((item, index) => (
        <li key={item.id} className="flex justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="font-bold leading-6 text-gray-900 ">{item.title_tg} </p>
                <p className="text-sm text-gray-500 leading-6 pb-2">{item.url}</p>
                <p className="mt-1 truncate text-xs text-gray-500">                  
                  {formatDate(item.created_at)}                   
                </p>
              </div>
            </div>
            <div className="shrink-0 flex flex-row items-center">
                <button 
                  onClick={(event) => {
                    if(window.confirm(ConfirmText)){
                      DeleteVacancies(item.id)            
                    }
                  }}
                  type="button" className="text-red-700 mr-2 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                      <TrashIcon className='h-5 w-6'/>
                </button>   
                <Link to={LinkTo('/admin/vacancies/edite/' + item.id )}
                    className="border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                      <PencilIcon className='h-5 w-6'/>
                  </Link>    
            </div>
        </li>
      ))}
      </ul>

      </div>}
    </div>)
  }
  
  export default Vacancies