import { PencilIcon, TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import GlobalText from './globalText'
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Toaster, toast } from 'sonner'
import GlobalData from './globalData';
import axios from 'axios';
import Cookies from 'js-cookie';
  
  function Users() {
    const [showModal, setShowModal] = useState(false);
    const [showModalEd, setShowModalEd] = useState(false);
    const [addloading, setaddloading] = useState(false);    
    const APIURL = GlobalData('apiUrl')
    const succesText = GlobalText(47)
    const succesTextDel = GlobalText(49)
    const succesTextEd = GlobalText(38)
    const errorText = GlobalText(39)
    const ConfirmText = GlobalText(48)
    const Cookie = Cookies.get('token')
    const [loading, setLoading] = useState(true);
    const [users, setusers] = useState([]);
    const [edusers, setedusers] = useState({});

    function AddUser(e){
      e.preventDefault()  
      setaddloading(true)
      const name = document.getElementById('name').value
      const email = document.getElementById('email').value
      const status = document.getElementById('status').value
      const password = document.getElementById('password').value

      axios.post(APIURL + 'user/add', 
        {
          'name' : name,
          'email' : email,
          'status' : status,
          'password' : password,
        }, 
        {
          headers:{
            Authorization: `Bearer ${Cookie}`,
            'Content-Type': 'multipart/form-data'
          }
      }      
      )
      .then((response) => {
        if(response.data === 'ok'){ 
          toast.success(succesText)  
          setShowModal(false)
          loadingData()
        }
        setaddloading(false) 
      })
      .catch(function (error) {   
        setaddloading(false)         
        toast.error(errorText)     
      })
      
    }

    function loadingData(){      
      axios.get(APIURL + 'users', {
        headers:{
          Authorization: `Bearer ${Cookie}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
          setusers(response.data)   
          localStorage.setItem('users', JSON.stringify(response.data));
          setLoading(false)   
      })
      .catch(function (error) {         
        setLoading(false)    
      })
    }

    useEffect(() => {
      const storedPages = localStorage.getItem('users');
      if (storedPages) {
        setusers(JSON.parse(storedPages))
        setLoading(false)
        loadingData()
      } else {
        loadingData()
      }
    }, []);
    
    function DeleteUser(id){ 
      axios.post(APIURL + 'user/delete', {
        'user_id' : id
      }, {
        headers:{
          Authorization: `Bearer ${Cookie}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
          loadingData()
          toast.success(succesTextDel) 
      })
      .catch(function (error) {
        toast.error(errorText)
      })
    }

    function PreEdite(id, name, email, status){
      setedusers({
        "id" : id,
        "name" : name,
        "email" : email,
        "status" : status,
      })
      setShowModalEd(true)
      setaddloading(false) 
    }

    function EditeUser(e){
      e.preventDefault()  
      setaddloading(true)    
      const name = document.getElementById('name_ed').value
      const email = document.getElementById('email_ed').value
      const status = document.getElementById('status_ed').value
      const password = document.getElementById('password_ed').value      
      axios.post(APIURL + 'user/edite', 
        {
          'id' : edusers['id'],
          'name' : name,
          'email' : email,
          'status' : status,
          'password' : password,
        }, 
        {
          headers:{
            Authorization: `Bearer ${Cookie}`,
            'Content-Type': 'multipart/form-data'
          }
      }      
      )
      .then((response) => {
        if(response.data === 'ok'){
          toast.success(succesTextEd)  
          setShowModalEd(false)
          loadingData()
        }
        setaddloading(false) 
      })
      .catch(function (error) {   
        setaddloading(false)         
        toast.error(errorText)     
      })

    }

    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      {loading ? GlobalData('loading') : <div>
      <Toaster richColors position="top-center"/>
      <div className='flex mx-auto justify-between py-3'>
        <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(21)} </div>
        <button onClick={() => setShowModal(true)} className="justify-self-end text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
            <PlusIcon className='h-5 w-5 mr-2'/>
            {GlobalText(44)}
        </button>  
      </div>
      <ul role="list" className="divide-y divide-gray-100 mx-auto ">
      {users.map((user) => (
        <li key={user.id} className="flex justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="font-semibold leading-6 text-gray-900">{user.name} ({user.status === 'superadmin' ? GlobalText(15) : user.status === 'admin' ? GlobalText(15) : user.status === 'moderator' ? GlobalText(16) : GlobalText(17)})</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{user.email}</p>
              </div>
            </div>
            <div className="shrink-0 flex flex-row items-center">
                <button 
                  onClick={(event) => {
                    if(window.confirm(ConfirmText + '  (' + user.name + ')')){
                      DeleteUser(user.id)                      
                    }
                  }}
                  type="button" className="text-red-700 mr-2 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                      <TrashIcon className='h-5 w-6'/>
                </button>   
                <button onClick={() => PreEdite(user.id, user.name, user.email, user.status)}
                  className="border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                      <PencilIcon className='h-5 w-6'/>
                </button>      
            </div>
        </li>
      ))}
      </ul>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                  <h3 className="text-xl font-semibold text-gray-600">
                    {GlobalText(44)}
                  </h3>
                  <button
                    className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                      <XMarkIcon />
                    </span>
                  </button>
                </div>
                <form onSubmit={(e) => AddUser(e)}>
                <div className="relative p-6 flex-auto">                  
                    <input
                        maxLength={100}
                        required
                        type="text"
                        id="name"
                        autoComplete='off'
                        minLength='2'
                        placeholder={GlobalText(45)}
                        className="block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
                    />
                    <input
                        maxLength={100}
                        required
                        type="email"
                        id="email"
                        autoComplete='off'
                        placeholder={GlobalText(8)}
                        className="mt-3 block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
                    />
                    <select id="status" className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="editor" >{GlobalText(17)}</option>
                      <option value="moderator">{GlobalText(16)}</option>
                      <option value="admin">{GlobalText(15)}</option>
                    </select>
                    <input
                        maxLength={100}
                        required
                        type="text"
                        id="password"
                        autoComplete='off'
                        minLength='8'
                        placeholder={GlobalText(9)}
                        className="mt-3 block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
                    />
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                  {addloading ? <button disabled className="uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
                  <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(31)}
                  </button> : <button
                    className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {GlobalText(31)}
                  </button> }             
                </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

    {showModalEd ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                  <h3 className="text-xl font-semibold text-gray-600">
                    {GlobalText(50)}
                  </h3>
                  <button
                    className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                    onClick={() => setShowModalEd(false)}
                  >
                    <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                      <XMarkIcon />
                    </span>
                  </button>
                </div>
                <form onSubmit={(e) => EditeUser(e)}>
                <div className="relative p-6 flex-auto">                  
                    <input
                        maxLength={100}
                        required
                        type="text"
                        id="name_ed"
                        autoComplete='off'
                        minLength='2'
                        placeholder={GlobalText(45)}
                        defaultValue={edusers['name']}
                        className="block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
                    />
                    <input
                        maxLength={100}
                        required
                        type="email"
                        id="email_ed"
                        autoComplete='off'
                        placeholder={GlobalText(8)}
                        defaultValue={edusers['email']}
                        className="mt-3 block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
                    />
                    <select id="status_ed" className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="editor" selected={edusers['status'] === 'editor'}>{GlobalText(17)}</option>
                      <option value="moderator" selected={edusers['status'] === 'moderator'}>{GlobalText(16)}</option>
                      <option value="admin" selected={edusers['status'] === 'admin'}>{GlobalText(15)}</option>
                    </select>
                    <input
                        maxLength={100}
                        type="text"
                        id="password_ed"
                        autoComplete='off'
                        minLength='8'
                        placeholder={GlobalText(51)}
                        className="mt-3 block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
                    />
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                  {addloading ? <button disabled className="uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
                  <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(27)}
                  </button> : <button
                    className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {GlobalText(27)}
                  </button> }             
                </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      </div>}
    </div>)
  }
  
  export default Users