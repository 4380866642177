import { PencilIcon, TrashIcon, PlusIcon, XMarkIcon,  MinusIcon } from '@heroicons/react/24/outline'
import GlobalText from './globalText'
import { Link } from "react-router-dom";
import LinkTo from './LinkTo';
import React, { useState, useEffect } from "react";
import { Toaster, toast } from 'sonner'
import GlobalData from './globalData';
import axios from 'axios';
import Cookies from 'js-cookie';
  
  function Cals() {
    const APIURL = GlobalData('apiUrl')
    const succesTextDel = GlobalText(109)
    const errorText = GlobalText(39)
    const ConfirmText = GlobalText(108)
    const Cookie = Cookies.get('token')
    const [loading, setLoading] = useState(true);
    const [senloading, setsendLoading] = useState(false);
    const succesText = GlobalText(38)
    const [rates, setRates] = useState([]);
    const [debts, setdebts] = useState([]);
    const [showModal, setShowModal] = useState(false);

    function Save(){
      setsendLoading(true) 
      axios.post(APIURL + 'cals/edite', {
        'data': rates
      }, {
          headers:{
            Authorization: `Bearer ${Cookie}`,
            'Content-Type': 'multipart/form-data'
          }
        }      
      )
      .then((response) => {
        if(response.status === 200){   
          console.log(response.data)
          setsendLoading(false) 
          toast.success(succesText)           
        }
      })
      .catch(function (error) {   
        setsendLoading(false)         
        toast.error(errorText)     
      })
      
    }

    function add(name){      
      if(name === 'rates'){
        const newData = {
          title_tg: 'Name TJ',
          title_ru: 'Name RU',
          title_en: 'Name EN',
          data: [
            {
              title: 'USD',
              value_buy: '10.6000',
              value_sale: '10.7000'
            },
            {
              title: 'RUB',
              value_buy: '0.1210',
              value_sale: '0.1230'
            },
            {
              title: 'EUR',
              value_buy: '11.5000',
              value_sale: '11.7000'
            },
          ]
        }
        setRates([...rates, newData])
      }      
    }

    function loadingData(){      
      axios.get(APIURL + 'calculators', {
        headers:{
          Authorization: `Bearer ${Cookie}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        console.log(response.data)
          setRates(response.data)   
          setLoading(false)   
      })
      .catch(function (error) {         
        setLoading(false)    
      })
    }

    useEffect(() => {
      loadingData();
    }, []);
    
    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      {loading ? GlobalData('loading') : <div>
      <Toaster richColors position="top-center"/>
      
      <div className='py-3 '>
        <div className='flex mx-auto justify-between '>
          <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(88)} </div>
          {<span>
            <button 
              onClick={() => {   
                if(rates.length > 1){
                  setRates(rates.slice(0, rates.length - 1)); 
                }
                          
              }}
              type="button" className="text-gray-700 mx-1 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <MinusIcon className='h-4 w-5'/>
          </button>
          <span className="text-sm mx-2 text-center inline-flex items-center">{GlobalText(102)}: {rates.length.toString()}</span>
          <button 
              onClick={() => {  
                if(rates.length < 5){
                  add('rates')  
                }  
              }}
              type="button" className="text-gray-700 mx-1 mr-2 bg-gray-200 hover:bg-gray-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
              <PlusIcon className='h-4 w-5'/>
          </button>
          </span> } 
        </div>
        <div className="mt-6 grid grid-cols-1 gap-2 sm:grid-cols-2 ">
              {rates && rates.map((element, index) => { 
                return(<div key={index} className=" border border-dashed border-gray-300 rounded-xl px-3 pt-5 items-center">
                  <div className="relative z-0 w-full mb-5">
                    <input
                        maxLength={100}
                        autoComplete='off'
                        type="text"
                        id={'title_tg' + index}
                        minLength='2'
                        required
                        placeholder=''
                        defaultValue={element.title_tg}
                        onChange={(e) => {
                          element.title_tg = e.target.value
                        }}
                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                      />
                    <label htmlFor={'title_tg' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(53) + ')'}</label>
                  </div>
                  <div className="relative z-0 w-full mb-5">
                    <input
                        maxLength={100}
                        autoComplete='off'
                        type="text"
                        id={'title_ru' + index}
                        minLength='2'
                        required
                        placeholder=''
                        defaultValue={element.title_ru}
                        onChange={(e) => {
                          element.title_ru = e.target.value
                        }}
                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                      />
                    <label htmlFor={'title_ru' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(54) + ')'}</label>
                  </div>
                  <div className="relative z-0 w-full mb-5">
                    <input
                        maxLength={100}
                        autoComplete='off'
                        type="text"
                        id={'title_en' + index}
                        minLength='2'
                        required
                        placeholder=''
                        defaultValue={element.title_en}
                        onChange={(e) => {
                          element.title_en = e.target.value
                        }}
                        className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                      />
                    <label htmlFor={'title_en' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(55) + ')'}</label>
                  </div>
                  {
                    element.data && element.data.map((el, ind) => {
                      return(<div className='flex justify-items-stretch'>
                        <div className="relative z-0 w-full mb-5 px-2">
                          <input
                              maxLength={100}
                              autoComplete='off'
                              type="number"
                              id={'rubl' + index}
                              step="0.01"
                              required
                              placeholder=''
                              defaultValue={el.value_buy}
                              onChange={(e) => {
                                el.value_buy = e.target.value
                              }}
                              className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                            />
                          <label htmlFor={'rubl' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">1 {el.title + ' (' +GlobalText(113) + ')'}</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 px-2">
                          <input
                              maxLength={100}
                              autoComplete='off'
                              type="number"
                              step="0.01"
                              id={'rubl' + index}
                              required
                              placeholder=''
                              defaultValue={el.value_sale}
                              onChange={(e) => {
                                el.value_sale = e.target.value
                              }}
                              className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                            />
                          <label htmlFor={'rubl' + index} className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">1 {el.title + ' (' +GlobalText(114) + ')'}</label>
                        </div>
                      </div>)
                    })
                  }
                  
                </div>)
              })}              
            </div>     
      </div> 


      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className='sm:mx-60'></div>
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                  <h3 className="text-xl font-semibold text-gray-600">
                    {GlobalText(112)} 
                  </h3>
                  <button
                    className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                      <XMarkIcon />
                    </span>
                  </button>
                </div>
                <form onSubmit={(e) => console.log(e)}>
                <div className="relative p-6 flex-auto">  
                  <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_tg"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(53) + ')'}</label>
                    </div> 
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_ru"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(54) + ')'}</label>
                    </div>  
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_title_en"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_title_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(55) + ')'}</label>
                    </div> 
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="menu_link"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="menu_link" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                    </div> 
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                    <button
                      className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                    {GlobalText(31)}
                  </button>            
                </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      </div>}
      <div className='flex mx-auto justify-between mt-10'>
        {senloading ? <button disabled className="uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
        <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(27)}
        </button> : <button onClick={(event) => {
          Save()
        }}
        className="uppercase text-white bg-gray-500 hover:bg-gray-600 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
          {GlobalText(27)}
        </button> }
      </div>
    </div>)
  }
  
  export default Cals