import { PencilIcon, TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import GlobalText from './globalText'
import React, { useState, useEffect, useRef } from "react";
import { Toaster, toast } from 'sonner'
import GlobalData from './globalData';
import axios from 'axios';
import Cookies from 'js-cookie';
  
  function Profile(props) {  
    const { userEmail, userName, userStatus } = props;
    const APIURL = GlobalData('apiUrl')
    const Cookie = Cookies.get('token')
    const [sendloading, setsendloading] = useState(false);  
    const [edpass, setedpass] = useState(false);  
    const succesTextEd = GlobalText(38)
    const errorText = GlobalText(39)
    const errorText_2 = GlobalText(63)

    function EditeUser(e){
      e.preventDefault()  
      setsendloading(true)    
      const name = document.getElementById('name').value
      const email = document.getElementById('email').value
      const new_password = document.getElementById('new_password').value 
      const new_password_2 = document.getElementById('new_password_2').value    
      if(new_password === new_password_2){
        axios.post(APIURL + 'profile/edite', 
          {
            'name' : name,
            'email' : email,
            'new_password' : new_password,
          }, 
          {
            headers:{
              Authorization: `Bearer ${Cookie}`,
              'Content-Type': 'multipart/form-data'
            }
        }      
        )
        .then((response) => {
          console.log(response.data)
          if(response.data === 'ok'){
            toast.success(succesTextEd)  
          }
          setsendloading(false) 
        })
        .catch(function (error) {   
          setsendloading(false)         
          toast.error(errorText)     
        })
      }else{
        setsendloading(false)  
        toast.error(errorText_2)
      }

    }

    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      <div className='pb-10'>
      <Toaster richColors position="top-center"/>  
      <div className='flex mx-auto justify-between py-3'>
        <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(13)} </div>      
      </div>
      <form onSubmit={(e) => EditeUser(e)}>
      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
        <div className="relative z-0 w-full mb-5">
          <input
              maxLength={100}
              autoComplete='off'
              type="text"
              id="name"
              minLength='2'
              required
              placeholder=''
              defaultValue={userName}
              className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            />
          <label htmlFor="name" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(45)}</label>
        </div> 
        <div className="relative z-0 w-full mb-5">
          <input
              maxLength={100}
              autoComplete='off'
              type="email"
              id="email"
              minLength='2'
              required
              placeholder=''
              defaultValue={userEmail}
              className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            />
          <label htmlFor="email" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(8)}</label>
        </div> 
        </div>

        <div className="sm:col-span-3">
          <label for="ed_pass" className="block text-md font-medium leading-6 text-gray-900 inline-flex items-center">
            <input id="ed_pass" type="checkbox" onChange={(e) => setedpass(e.target.checked)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> 
            <span className='ml-2'>{GlobalText(59)}</span>
          </label>
          <input
              maxLength={100}
              required
              type="text"
              id="new_password"
              autoComplete='off'
              minLength='8'
              placeholder={GlobalText(61)}
              disabled={!edpass}
              className="mt-2 block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
          />
          <input
              maxLength={100}
              required
              type="text"
              id="new_password_2"
              autoComplete='off'
              minLength='8'
              placeholder={GlobalText(62)}
              disabled={!edpass}
              className="mt-2 block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-100 sm:text-md sm:leading-6"
          />
        </div></div>
        
        {sendloading ? <button disabled className="mt-6 bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
          <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(27)}
          </button> : <button type='submit'
          className="mt-6 text-white bg-gray-500 hover:bg-gray-600 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
            {GlobalText(27)}
          </button> }
        </form>
      </div>
    </div>)
  }
  
  export default Profile