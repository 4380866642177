import { useState, useEffect, useRef } from 'react';
import { PencilIcon, TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import GlobalText from './globalText'
import axios from 'axios';
import GlobalData from './globalData';
import Cookies from 'js-cookie';
import { Toaster, toast } from 'sonner'
import { Link } from 'react-router-dom';
import LinkTo from './LinkTo';
  
  function Pages(props) {
    const { language } = props
    const APIURL = GlobalData('apiUrl')
    const cookie = Cookies.get('token')
    const [addPageShow, setAddPageShow] = useState(false);
    const [pages, setPages] = useState([]);
    const [addloading, setaddloading] = useState(false);  
    const succesTextAdd = GlobalText(82)
    const succesTextDel = GlobalText(83)
    const confirmTex = GlobalText(81)
    const errorText = GlobalText(39)
    const errorTextLink = GlobalText(84)
    const link_b = useRef(true)
    const [loading, setLoading] = useState(true);     

    function loadingData(){  
      axios.get(APIURL + 'pages/all', {
        headers:{
          Authorization: `Bearer ${cookie}`
        }
      })
      .then((response) => {
        setPages(response.data)
        localStorage.setItem('pages', JSON.stringify(response.data));
        setLoading(false)
      })
      .catch(function (error) {            
        console.log(error) 
        setLoading(false)          
      })
    }

    useEffect(() => {
      const storedPages = localStorage.getItem('pages');
      if (storedPages) {
        setPages(JSON.parse(storedPages))
        setLoading(false)
        loadingData()
      } else {
        loadingData()
      }
    }, []);

    function AddPage(e){
      e.preventDefault() 
      link_b.current = true;
      const title_tg = document.getElementById('add_title_tg').value
      const title_ru = document.getElementById('add_title_ru').value
      const title_en = document.getElementById('add_title_en').value
      const link = document.getElementById('add_link').value      
      pages.forEach((page) => {
        if(page.page_name === link){
          link_b.current = false;
        }
      });
      if(link_b.current){
        setaddloading(true)
        axios.post(APIURL + 'pages/add', 
        {
          'page_title_tg' : title_tg,
          'page_title_ru' : title_ru,
          'page_title_en' : title_en,
          'page_name' : link,
        }, 
        {
          headers:{
            Authorization: `Bearer ${cookie}`
          }
        })
        .then((response) => {
          if(response.data){
            setaddloading(false)
            setAddPageShow(false)
            loadingData();
            toast.success(succesTextAdd)
          }
        })
        .catch(function (error) {            
          console.log(error)    
          setaddloading(false) 
          setAddPageShow(false)  
          toast.error(errorText)    
        })
      }else{
        toast.error(errorTextLink) 
      }
      
    }

    function DeletePage(id){      
      if(window.confirm(confirmTex)){
        axios.post(APIURL + 'pages/delete', 
        {
          'id' : id
        }, 
        {
          headers:{
            Authorization: `Bearer ${cookie}`
          }
        })
        .then((response) => {
          if(response.data === 'ok'){
            loadingData();
            toast.success(succesTextDel)
          }
        })
        .catch(function (error) {            
          console.log(error)    
          toast.error(errorText)    
        })
      }
    }

    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      <Toaster richColors position="top-center"/>
      {loading ? GlobalData('loading') : <div>
        <div className='flex mx-auto justify-between py-3'>
        <div className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(20)} </div>
        <button onClick={() => setAddPageShow(true)} className="justify-self-end text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white font-medium rounded-lg text-sm p-2 text-center inline-flex items-center">
            <PlusIcon className='h-5 w-5 mr-2'/>
            {GlobalText(23)}
        </button>  
        </div>
        <ul role="list" className="divide-y divide-gray-100 mx-auto ">
        {pages.map((Item) => (
          <li key={Item.id} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="font-medium leading-6 text-gray-900 text-lg">{language === 'tg' ? Item.page_title_tg : language === 'ru' ? Item.page_title_ru : Item.page_title_en} </p>
                    <p className="mt-1 truncate text-sm leading-5 text-gray-500">{Item.page_name} 
                    {Item.page_nod == 'main' && <span className="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(52)}</span>}
                    {Item.page_nod == 'news' && <span className="ml-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(19)}</span>}
                    {Item.page_nod == 'vacancies' && <span className="ml-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(148)}</span>}
                    </p>
                  </div>
                </div>
              <div className="shrink-0 flex flex-row items-center">
                  {!Item.page_nod && <button 
                    onClick={(event) => {
                      DeletePage(Item.id)
                    }}
                    type="button" className="text-red-700 mr-2 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                      <TrashIcon className='h-5 w-6'/>
                  </button>}   
                  <Link to={LinkTo('/admin/pages/edite/' + Item.id )}
                    className="border border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-1 text-center inline-flex items-center">
                      <PencilIcon className='h-5 w-6'/>
                  </Link>      
              </div>
          </li>
        ))}
        </ul>
        {addPageShow ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className='sm:mx-60'></div>
                  <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
                    <h3 className="text-xl font-semibold text-gray-600">
                      {GlobalText(23)} 
                    </h3>
                    <button
                      className="p-1 ml-24 bg-transparent border-0 text-blacktext-3xl leading-none font-semibold outline-none focus:outline-none items-center justify-center"
                      onClick={() => setAddPageShow(false)}
                    >
                      <span className="bg-transparent text-gray-400 h-6 w-6 text-xl block outline-none focus:outline-none justify-center items-center m">
                        <XMarkIcon />
                      </span>
                    </button>
                  </div>
                  <form onSubmit={(e) => AddPage(e)}>
                  <div className="relative p-6 flex-auto">  
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="add_title_tg"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="add_title_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(53) + ')'}</label>
                    </div>  
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="add_title_ru"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="add_title_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(54) + ')'}</label>
                    </div> 
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="add_title_en"
                          minLength='2'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="add_title_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(41) + ' (' + GlobalText(55) + ')'}</label>
                    </div>   
                    <div className="relative z-0 w-full mb-5">
                      <input
                          maxLength={100}
                          autoComplete='off'
                          type="text"
                          id="add_link"
                          minLength='1'
                          required
                          placeholder=''
                          className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                        />
                      <label htmlFor="add_link" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(42)}</label>
                    </div> 
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">       
                      {addloading ? <button disabled className="uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
                      <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(31)}
                    </button> : <button
                        className="bg-gray-500 text-white hover:bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                      {GlobalText(31)}
                    </button>}            
                  </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>}
    </div>)
  }

  export default Pages